export const FOLDER_CREATE_REQUESTED = 'folder/FOLDER_CREATE_REQUESTED';
export const FOLDER_CREATE_SUCCESS = 'folder/FOLDER_CREATE_SUCCESS';
export const FOLDER_CREATE_FAILURE = 'folder/FOLDER_CREATE_FAILURE';

export const PROGRAM_DETAILS_REQUESTED = 'folder/PROGRAM_DETAILS_REQUESTED';
export const PROGRAM_DETAILS_SUCCESS = 'folder/PROGRAM_DETAILS_SUCCESS';
export const PROGRAM_DETAILS_FAILURE = 'folder/PROGRAM_DETAILS_FAILURE';

export const DELETE_REQUESTED = 'folder/DELETE_REQUESTED';
export const DELETE_SUCCESS = 'folder/DELETE_SUCCESS';
export const DELETE_FAILURE = 'folder/DELETE_FAILURE';

export const FOLDER_STRUCTURE_REQUESTED = 'folder/FOLDER_STRUCTURE_REQUESTED';
export const FOLDER_STRUCTURE_SUCCESS = 'folder/FOLDER_STRUCTURE_SUCCESS';
export const FOLDER_STRUCTURE_FAILURE = 'folder/FOLDER_STRUCTURE_FAILURE';

export const FOLDER_MOVE_REQUESTED = 'folder/FOLDER_MOVE_REQUESTED';
export const FOLDER_MOVE_SUCCESS = 'folder/FOLDER_MOVE_SUCCESS';
export const FOLDER_MOVE_FAILURE = 'folder/FOLDER_MOVE_FAILURE';

export const FOLDER_EXPORT_REQUESTED = 'folder/FOLDER_EXPORT_REQUESTED';
export const FOLDER_EXPORT_SUCCESS = 'folder/FOLDER_EXPORT_SUCCESS';
export const FOLDER_EXPORT_FAILURE = 'folder/FOLDER_EXPORT_FAILURE';

export const FOLDER_RENAME_REQUESTED = 'folder/FOLDER_RENAME_REQUESTED';
export const FOLDER_RENAME_SUCCESS = 'folder/FOLDER_RENAME_SUCCESS';
export const FOLDER_RENAME_FAILURE = 'folder/FOLDER_RENAME_FAILURE';

export const FOLDER_DUPLICATE_REQUESTED = 'folder/FOLDER_DUPLICATE_REQUESTED';
export const FOLDER_DUPLICATE_SUCCESS = 'folder/FOLDER_DUPLICATE_SUCCESS';
export const FOLDER_DUPLICATE_FAILURE = 'folder/FOLDER_DUPLICATE_FAILURE';

export const DOWNLOAD_SRT_FILE_REQUESTED = 'processDetails/DOWNLOAD_SRT_FILE_REQUESTED';
export const DOWNLOAD_SRT_FILE_SUCCESS = 'processDetails/DOWNLOAD_SRT_FILE_SUCCESS';
export const DOWNLOAD_SRT_FILE_FAILURE = 'processDetails/DOWNLOAD_SRT_FILE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  data: [],
  allowedServices: [],
  programList: [],
  totalCounts: 0,
  errorMessage: '',
  programLoading: false,
  folderList: [],
  moveLoading: false,
  exportLoading: false,
  createFolderLoading: false,
  isDefault: true,
  isAdmin: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FOLDER_CREATE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        createFolderLoading: true,
      };
    }
    case FOLDER_CREATE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        data: action.result,
        createFolderLoading: false,
      };
    }
    case FOLDER_CREATE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        createFolderLoading: false,
        // errorMessage: action.result.errorMessage
      };
    }
    case PROGRAM_DETAILS_REQUESTED: {
      return {
        ...state,
        programLoading: true,
        loaded: false,
        error: false
      };
    }
    case PROGRAM_DETAILS_SUCCESS: {
      return {
        ...state,
        programLoading: false,
        loaded: true,
        error: false,
        programList: action.result.programDetails.programProcessListDTO,
        totalCounts: action.result.programDetails.count,
        isDefault: action.result.programDetails.default,
        isAdmin: action.result.programDetails.admin,
      };
    }
    case PROGRAM_DETAILS_FAILURE: {
      return {
        ...state,
        programLoading: false,
        loaded: true,
        error: true
      };
    }
    case DELETE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELETE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case FOLDER_STRUCTURE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case FOLDER_STRUCTURE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        folderList: action.result.programDetails,
      };
    }
    case FOLDER_STRUCTURE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case FOLDER_MOVE_REQUESTED: {
      return {
        ...state,
        moveLoading: true,
        loaded: false,
        error: false
      };
    }
    case FOLDER_MOVE_SUCCESS: {
      return {
        ...state,
        moveLoading: false,
        loaded: true,
        error: false,
        // folderList: action.result.programDetails,
      };
    }
    case FOLDER_MOVE_FAILURE: {
      return {
        ...state,
        moveLoading: false,
        loaded: true,
        error: true
      };
    }
    case FOLDER_EXPORT_REQUESTED: {
      return {
        ...state,
        exportLoading: true,
        loaded: false,
        error: false
      };
    }
    case FOLDER_EXPORT_SUCCESS: {
      console.log('aa', action, state);
      return {
        ...state,
        exportLoading: false,
        loaded: true,
        error: false,
        // folderList: action.result.programDetails,
      };
    }
    case FOLDER_EXPORT_FAILURE: {
      return {
        ...state,
        exportLoading: false,
        loaded: true,
        error: true
      };
    }
    case FOLDER_RENAME_REQUESTED: {
      return {
        ...state,
        renameLoading: true,
        loaded: false,
        error: false
      };
    }
    case FOLDER_RENAME_SUCCESS: {
      console.log('aa', action, state);
      return {
        ...state,
        renameLoading: false,
        loaded: true,
        error: false,
        // folderList: action.result.programDetails,
      };
    }
    case FOLDER_RENAME_FAILURE: {
      console.log('action ', action);
      return {
        ...state,
        renameLoading: false,
        loaded: true,
        error: true
      };
    }
    case FOLDER_DUPLICATE_REQUESTED: {
      return {
        ...state,
        duplicateLoading: true,
        loaded: false,
        error: false
      };
    }
    case FOLDER_DUPLICATE_SUCCESS: {
      return {
        ...state,
        duplicateLoading: false,
        loaded: true,
        error: false,
        // folderList: action.result.programDetails,
      };
    }
    case FOLDER_DUPLICATE_FAILURE: {
      return {
        ...state,
        duplicateLoading: false,
        loaded: true,
        error: true
      };
    }
    case DOWNLOAD_SRT_FILE_REQUESTED:
    {
      return {
        ...state,
        exportLoading: true,
        loaded: false,
      };
    }
    case DOWNLOAD_SRT_FILE_SUCCESS:
    {
      return {
        ...state,
        exportLoading: false,
        error: false,
        loaded: true,
      };
    }
    case DOWNLOAD_SRT_FILE_FAILURE:
    {
      return {
        ...state,
        exportLoading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const createFolder = (data) => {
  return {
    types: [FOLDER_CREATE_REQUESTED, FOLDER_CREATE_SUCCESS, FOLDER_CREATE_FAILURE],
    promise: client => client.post('createFolder', { data })
  };
};

export const getProgramList = (itemsPerPage, pageNo, sortKey, sortDirection, directoryId, searchValue, workspaceId) => {
  return {
    types: [PROGRAM_DETAILS_REQUESTED, PROGRAM_DETAILS_SUCCESS, PROGRAM_DETAILS_FAILURE],
    promise: client => client.get(`getProgramListWithFolder/${itemsPerPage}/${pageNo}?sortKey=${sortKey}&sortDirection=${sortDirection}&directoryId=${directoryId}&searchValue=${searchValue}&workspaceId=${workspaceId}`)
  };
};

export const deleteFilesOrDirectory = (data) => {
  return {
    types: [DELETE_REQUESTED, DELETE_SUCCESS, DELETE_FAILURE],
    promise: client => client.post('delete', { data })
  };
};

export const getFolderStructure = (dirId, workspaceId, data) => {
  return {
    types: [FOLDER_STRUCTURE_REQUESTED, FOLDER_STRUCTURE_SUCCESS, FOLDER_STRUCTURE_FAILURE],
    promise: client => client.post(`getFolderList?dirId=${dirId}&workspaceId=${workspaceId}`, { data })
  };
};

export const moveFilesOrDirectory = (data) => {
  return {
    types: [FOLDER_MOVE_REQUESTED, FOLDER_MOVE_SUCCESS, FOLDER_MOVE_FAILURE],
    promise: client => client.post('moveFolder', { data })
  };
};

export const exportFiles = (data) => {
  return {
    types: [FOLDER_EXPORT_REQUESTED, FOLDER_EXPORT_SUCCESS, FOLDER_EXPORT_FAILURE],
    promise: client => client.post('exportFile', { data })
  };
};

export const renameFiles = (data) => {
  return {
    types: [FOLDER_RENAME_REQUESTED, FOLDER_RENAME_SUCCESS, FOLDER_RENAME_FAILURE],
    promise: client => client.post('renameFile', { data })
  };
};

export const duplicateFiles = (contentId) => {
  return {
    types: [FOLDER_DUPLICATE_REQUESTED, FOLDER_DUPLICATE_SUCCESS, FOLDER_DUPLICATE_FAILURE],
    promise: client => client.get(`duplicateFile?contentId=${contentId}`)
  };
};

export const exportSrt = (programID) => {
  return {
    types: [DOWNLOAD_SRT_FILE_REQUESTED, DOWNLOAD_SRT_FILE_SUCCESS, DOWNLOAD_SRT_FILE_FAILURE],
    promise: client => client.post(`downloadSrt/${programID}`)
  };
};

export const exportFilesWithoutLogin = (data) => {
  return {
    types: [FOLDER_EXPORT_REQUESTED, FOLDER_EXPORT_SUCCESS, FOLDER_EXPORT_FAILURE],
    promise: client => client.post('exportFileWithoutLogin', { data })
  };
};

export const getProgramMixtureList = (itemsPerPage, pageNo, sortKey, sortDirection, searchValue) => {
  return {
    types: [PROGRAM_DETAILS_REQUESTED, PROGRAM_DETAILS_SUCCESS, PROGRAM_DETAILS_FAILURE],
    promise: client => client.get(`getProgramMixtureList/${itemsPerPage}/${pageNo}?sortKey=${sortKey}&sortDirection=${sortDirection}&searchValue=${searchValue}`)
  };
};

export const renameFilesMixture = (data) => {
  return {
    types: [FOLDER_RENAME_REQUESTED, FOLDER_RENAME_SUCCESS, FOLDER_RENAME_FAILURE],
    promise: client => client.post('renameFileMixture', { data })
  };
};
