export const GET_USERDETAILS_REQUESTED = 'editUserDetails/GET_USERDETAILS_REQUESTED';
export const GET_USERDETAILS_SUCCESS = 'editUserDetails/GET_USERDETAILS_SUCCESS';
export const GET_USERDETAILS_FAILURE = 'editUserDetails/GET_USERDETAILS_FAILURE';

export const EDIT_USERDETAILS_REQUESTED = 'editUserDetails/EDIT_USERDETAILS_REQUESTED';
export const EDIT_USERDETAILS_SUCCESS = 'editUserDetails/EDIT_USERDETAILS_SUCCESS';
export const EDIT_USERDETAILS_FAILURE = 'editUserDetails/EDIT_USERDETAILS_FAILURE';

export const CHECK_PASSEORD_REQUESTED = 'editUserDetails/CHECK_PASSEORD_REQUESTED';
export const CHECK_PASSEORD_SUCCESS = 'editUserDetails/CHECK_PASSEORD_SUCCESS';
export const CHECK_PASSEORD_FAILURE = 'editUserDetails/CHECK_PASSEORD_FAILURE';

export const SEND_SUPPORT_REQUESTED = 'editUserDetails/SEND_SUPPORT_REQUESTED';
export const SEND_SUPPORT_SUCCESS = 'editUserDetails/SEND_SUPPORT_SUCCESS';
export const SEND_SUPPORT_FAILURE = 'editUserDetails/SEND_SUPPORT_FAILURE';


const initialState = {
  error: false,
  data: null,
  userDetails: [],
  editDetails: [],
  passwordFlag: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERDETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_USERDETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        userDetails: action.result,
      };
    }
    case GET_USERDETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    }
    case EDIT_USERDETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case EDIT_USERDETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        editDetails: action.result,
      };
    }
    case EDIT_USERDETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.result,
      };
    }
    case CHECK_PASSEORD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CHECK_PASSEORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        passwordFlag: action.result,
      };
    }
    case CHECK_PASSEORD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.result,
      };
    }
    case SEND_SUPPORT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SEND_SUPPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        passwordFlag: action.result,
      };
    }
    case SEND_SUPPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.result,
      };
    }

    default:
      return state;
  }
}

export const getUserDetails = () => {
  return {
    types: [GET_USERDETAILS_REQUESTED, GET_USERDETAILS_SUCCESS, GET_USERDETAILS_FAILURE],
    promise: client => client.get('getUserDetails')
  };
};
export const updateUser = (data) => {
  return {
    types: [EDIT_USERDETAILS_REQUESTED, EDIT_USERDETAILS_SUCCESS, EDIT_USERDETAILS_FAILURE],
    promise: client => client.post('editUserDetails', {
      data
    })
  };
};

export const checkPassword = (data) => {
  return {
    types: [CHECK_PASSEORD_REQUESTED, CHECK_PASSEORD_SUCCESS, CHECK_PASSEORD_FAILURE],
    promise: client => client.post('checkPassword', {
      data
    })
  };
};

export const sendSupportEmail = (data) => {
  return {
    types: [SEND_SUPPORT_REQUESTED, SEND_SUPPORT_SUCCESS, SEND_SUPPORT_FAILURE],
    promise: client => client.post('sendSupportEmail', {
      data
    })
  };
};
