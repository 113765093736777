export const TRANSLATE_ADD_COUNT_REQUESTED = 'translate/TRANSLATE_ADD_COUNT_REQUESTED';
export const TRANSLATE_ADD_COUNT_SUCCESS = 'translate/TRANSLATE_ADD_COUNT_SUCCESS';
export const TRANSLATE_ADD_COUNT_FAILURE = 'translate/TRANSLATE_ADD_COUNT_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  res: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TRANSLATE_ADD_COUNT_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case TRANSLATE_ADD_COUNT_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        res: action.result.total_program,
      };
    }
    case TRANSLATE_ADD_COUNT_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const addTranslate = () => {
  return {
    types: [TRANSLATE_ADD_COUNT_REQUESTED, TRANSLATE_ADD_COUNT_SUCCESS, TRANSLATE_ADD_COUNT_FAILURE],
    promise: client => client.post('addTranslateCount')
  };
};
