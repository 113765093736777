import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Loadable from 'react-loadable';
import OnRouteChange from '@containers/common/OnRouteChange';
import App from '@containers/App';
import cn from 'classnames';
import PageLoader from '@components/common/PageLoader';
import HappyScribeSideMenu from '@containers/common/HappyScribeSideMenu';
import Header from '@containers/common/Header';
import { APP_CONFIG, ALLOWED_SERVICES } from '@constants';
import { ToastContainer } from 'react-toastify';
import { getSubdomain } from '@helpers/Utility';
import checkPermission from '@helpers/CheckPermission';
import EN from '@localization/EN';


const PrivateRoute = ({ component: Component, authed, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (typeof window.localStorage.getItem('userData') !== 'undefined'
        && window.localStorage.getItem('userData') !== null
        && window.localStorage.getItem('userData') !== '' ? (
          <>
            <Component {...props} />
          </>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        ))
      }
    />
  );
};

const subDomain = getSubdomain();

const LoadingComponent = ({ isLoading, isError }) => {
  if (isLoading) {
    return <PageLoader />;
  }
  if (isError) {
    return (
      <div>
Sorry, unable to load the page
      </div>
    );
  }
  return null;
};

const HappyScribeDashboard = Loadable({
  loader: () => import('@containers/HappyScribeDashboard'),
  loading: LoadingComponent,
});

const HappyScribeDashboardWorkspace = Loadable({
  loader: () => import('@containers/HappyScribeDashboard'),
  loading: LoadingComponent,
});

const Anayltics = Loadable({
  loader: () => import('@containers/AnalyticsContainer'),
  loading: LoadingComponent,
});

const LiveRecording = Loadable({
  loader: () => import('@containers/LiveRecordingContainer'),
  loading: LoadingComponent,
});

const ProcessLogs = Loadable({
  loader: () => import('@containers/ProcessLogs'),
  loading: LoadingComponent,
});

const Forgot = Loadable({
  loader: () => import('@containers/ForgotPassword'),
  loading: LoadingComponent,
});

const ConfirmPassword = Loadable({
  loader: () => import('@containers/ConfirmPassword'),
  loading: LoadingComponent,
});

const ResetPassword = Loadable({
  loader: () => {
    const resetPassword = (subDomain === EN.AMP_VALUE || subDomain === EN.STAGEUN_VALUE) ? import('@components/ResetPasswordUN') : import('@components/ResetPassword');
    return resetPassword;
  },
  loading: LoadingComponent,
});

const Login = Loadable({
  loader: () => import('@containers/UNLogin'),
  loading: LoadingComponent,
});
const AddNewProcess = Loadable({
  loader: () => import('@containers/AddNewProcessContainer'),
  loading: LoadingComponent,
});

const MyProfileSettings = Loadable({
  loader: () => import('@containers/MyProfileSettings'),
  loading: LoadingComponent,
});
const Register = Loadable({
  loader: () => import('@containers/RegisterUN'),
  loading: LoadingComponent,
});

const RegisterSuccess = Loadable({
  loader: () => import('@containers/Register/RegisterSuccess'),
  loading: LoadingComponent,
});

const RegisterVerify = Loadable({
  loader: () => import('@containers/Register/RegisterVerify'),
  loading: LoadingComponent,
});
const ProcessesDetail = Loadable({
  loader: () => import('@containers/ProcessesDetailVersion2Slate'),
  loading: LoadingComponent,
});

const Default = Loadable({
  loader: () => import('@containers/Default'),
  loading: LoadingComponent,
});

const notFound = Loadable({
  loader: () => import('@components/404NotFound'),
  loading: LoadingComponent,
});
const Logout = Loadable({
  loader: () => import('@containers/Logout'),
  loading: LoadingComponent,
});

const IntermediateScreen = Loadable({
  loader: () => import('@containers/IntermediateScreen'),
  loading: LoadingComponent,
});

const ViewPrograms = Loadable({
  loader: () => import('@containers/ViewPrograms'),
  loading: LoadingComponent,
});

const MachineTranslation = Loadable({
  loader: () => import('@containers/MachineTranslation'),
  loading: LoadingComponent,
});

const AzureUserTokenPage = Loadable({
  loader: () => import('@containers/AzureUserTokenPage'),
  loading: LoadingComponent,
});

const Subtitle = Loadable({
  loader: () => import('@containers/SubtitleContainer'),
  loading: LoadingComponent,
});
const NewWorkspace = Loadable({
  loader: () => import('@containers/NewWorkspace'),
  loading: LoadingComponent,
});

const manageWorkspace = Loadable({
  loader: () => import('@containers/NewWorkspace'),
  loading: LoadingComponent,
});

const AzureLandingPage = Loadable({
  loader: () => import('@containers/AzureLandingPage'),
  loading: LoadingComponent,
});

const TranslateV2 = Loadable({
  loader: () => import('@containers/TranslateV2'),
  loading: LoadingComponent,
});

const AddToDictonary = Loadable({
  loader: () => import('@containers/AddToDictonary'),
  loading: LoadingComponent,
});

const PaymentPlans = Loadable({
  loader: () => import('@containers/PaymentPlansContainer'),
  loading: LoadingComponent,
});

const SupportContainer = Loadable({
  loader: () => import('@containers/Support'),
  loading: LoadingComponent,
});

const LiveTranslate = Loadable({
  loader: () => import('@containers/LiveTranslate'),
  loading: LoadingComponent,
});

const EndedSession = Loadable({
  loader: () => import('@containers/EndSession'),
  loading: LoadingComponent,
});

const ReplaySession = Loadable({
  loader: () => import('@containers/ReplaySession'),
  loading: LoadingComponent,
});

const BroadcastSession = Loadable({
  loader: () => import('@containers/Broadcast'),
  loading: LoadingComponent,
});

const ResendEmail = Loadable({
  loader: () => import('@containers/ResendEmail'),
  loading: LoadingComponent,
});

const Mixture = Loadable({
  loader: () => import('@containers/Mixturecontainer'),
  loading: LoadingComponent,
});

const ApiAccess = Loadable({
  loader: () => import('@containers/ApiAccessContainer'),
  loading: LoadingComponent,
});

const MixtureReplay = Loadable({
  loader: () => import('@containers/MixtureReplayContainer'),
  loading: LoadingComponent,
});

const StripeContainer = Loadable({
  loader: () => import('@containers/StripePayment'),
  loading: LoadingComponent,
});

const StripeSuccessContainer = Loadable({
  loader: () => import('@containers/StripeSuccessContainer'),
  loading: LoadingComponent,
});

const StripeOneTimeFailedContainer = Loadable({
  loader: () => import('@containers/StripeOneTimeFailedContainer'),
  loading: LoadingComponent,
});

const StripeOneTimeSuccessContainer = Loadable({
  loader: () => import('@containers/StripeOneTimeSuccessContainer'),
  loading: LoadingComponent,
});

const StripeFailedContainer = Loadable({
  loader: () => import('@containers/StripeFailedContainer'),
  loading: LoadingComponent,
});

const path = window.location.pathname;
const sideMenuReq = window.localStorage.getItem('userData') && JSON.parse(window.localStorage.getItem('userData')).endUserDetails.id && !path.includes('/core') && !path.includes('/translation') && !path.includes('/logout') && !path.includes('/confirmresetpassword');

export default (
  <Router>
    <App>
      {/* {window.localStorage.getItem('userData') !== null && <Header />} */}
      <div className="d-flex" style={{ fontFamily: 'Open Sans', overflowX: 'clip', overflowY: 'clip' }}>
        {sideMenuReq && (
          <>
            { (window.location.href.includes('/livetranslate') || window.location.href.includes('/session/ended') || window.location.href.includes('/session/watch') || window.location.href.includes('/session/broadcast')) ? (
              <div className={cn('navbar navbar-vertical d-none d-md-block min-vh-100 col-md-3 col-lg-1 col-xl-1 pl-0 colWidthh', checkPermission(ALLOWED_SERVICES.ISBUTTONAPP) ? 'side-menu-background-App' : 'side-menu-background-un')} id="sideMenuId">
                <HappyScribeSideMenu />
              </div>
            ) : (<>
              <div className={cn('navbar navbar-vertical d-none d-md-block min-vh-100 col-md-3 col-lg-2 col-xl-2 pl-0 colWidth', checkPermission(ALLOWED_SERVICES.ISBUTTONAPP) ? 'side-menu-background-App' : 'side-menu-background-un')} id="sideMenuId">
                <HappyScribeSideMenu />
              </div>
              </>)
            }
          </>
        )}
        <div id="main_div" className={cn(sideMenuReq ? [path.includes('/livetranslate') || path.includes('/session/ended') || path.includes('/session/watch') || path.includes('/session/broadcast') ? 'col-md-11 col-lg-11 col-xl-11 p-0 m-0' : 'col-md-9 col-lg-10 col-xl-10 p-0 m-0'] : 'col-md-12 p-0')}>
          {window.localStorage.getItem('userData') !== null && <Header />}
          <div className={cn('col-12', window.localStorage.getItem('userData') !== null ? '' : 'p-0 m-0')}>
            <OnRouteChange>
              <Switch>
                <Route exact path={`${APP_CONFIG.BASE_URL}/`} component={Login} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/Default`} component={Default} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/users/signup`} component={Register} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/azurelogin`} component={AzureUserTokenPage} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/landingpage`} component={AzureLandingPage} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/users/signup/successful`} component={RegisterSuccess} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/forgotpassword/`} component={Forgot} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/confirmresetpassword/:id`} component={ConfirmPassword} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/resetpassword`} component={ResetPassword} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/addnewprocess/uploads/:contentId/:workspaceId`} component={AddNewProcess} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/login`} component={Login} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/program`} component={ViewPrograms} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/Dashboard`} component={HappyScribeDashboard} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/analytics`} component={Anayltics} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/liveRecord`} component={LiveRecording} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/Processes`} component={ProcessLogs} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/users/settings`} component={MyProfileSettings} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/users/activate/:userId`} component={RegisterVerify} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/logout`} component={Logout} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/core/`} component={IntermediateScreen} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/process/:programID/:status/:language/:contentId`} component={ProcessesDetail} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/process/subtitle/:programID/:status/:language/:contentId/:isSharable`} component={Subtitle} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/workspace/new`} component={NewWorkspace} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/workspace/edit/:workspaceId`} component={manageWorkspace} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/workspace/:workspaceId`} component={HappyScribeDashboardWorkspace} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/translation`} component={MachineTranslation} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/translate/:programID/:status/:language/:contentId/:translatedLanguageCode`} component={TranslateV2} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/addtodictonary`} component={AddToDictonary} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/paymentplans`} component={PaymentPlans} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/support`} component={SupportContainer} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/livetranslate`} component={LiveTranslate} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/session/ended/:sessionId/:sessionName`} component={EndedSession} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/session/watch/:sessionId`} component={ReplaySession} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/session/broadcast/:sessionId/:workspaceId/:sessionName/:translationType/:justifyData`} component={BroadcastSession} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/resend/email`} component={ResendEmail} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/mixture/tool`} component={Mixture} />
                <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/apiAccess`} component={ApiAccess} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/mixture/replay/:mixtureId`} component={MixtureReplay} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/stripe/:amount/:quantity/:planType`} component={StripeContainer} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/stripe/payment/sucess`} component={StripeSuccessContainer} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/stripe/payment/failed`} component={StripeFailedContainer} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/stripe/payments/sucess`} component={StripeOneTimeSuccessContainer} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/stripe/payments/failed`} component={StripeOneTimeFailedContainer} />
                <Route path="*" component={notFound} />
              </Switch>
            </OnRouteChange>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </App>
  </Router>
);
