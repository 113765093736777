export const RESET_PASSWORD_REQUESTED = 'password/RESET_PASSWORD_REQUESTED';
export const RESET_PASSWORD_SUCCESS = 'password/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'password/RESET_PASSWORD_FAILURE';

export const IS_PASSWORD_EXIST_REQUESTED = 'password/IS_EMAIL_EXIST_REQUESTED';
export const IS_PASSWORD_EXIST_SUCCESS = 'password/IS_EMAIL_EXIST_SUCCESS';
export const IS_PASSWORD_EXIST_FAILURE = 'password/IS_EMAIL_EXIST_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true
      };
    }
    case IS_PASSWORD_EXIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        isPassword: false
      };
    }
    case IS_PASSWORD_EXIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        isPassword: false
      };
    }
    case IS_PASSWORD_EXIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        isPassword: true
      };
    }

    default:
      return state;
  }
};


export const resetPassword = (data) => {
  return {
    types: [RESET_PASSWORD_REQUESTED, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
    promise: client => client.post('resetpassword', {
      data
    })
  };
};

export const isPasswordSame = (pass, userId) => {
  return {
    types: [IS_PASSWORD_EXIST_REQUESTED, IS_PASSWORD_EXIST_SUCCESS, IS_PASSWORD_EXIST_FAILURE],
    promise: client => client.get(`isPasswordSame/${userId}?password=${pass}?`)
  };
};

export const verifyLink = (linkTime, userId) => {
  return {
    types: [RESET_PASSWORD_REQUESTED, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
    promise: client => client.post(`forgotPasswordVerifyLink?verifyTime=${linkTime}&userId=${userId}`)
  };
};
