export const DICTONARY_SAVE_REQUESTED = 'dictonary/DICTONARY_SAVE_REQUESTED';
export const DICTONARY_SAVE_SUCCESS = 'dictonary/DICTONARY_SAVE_SUCCESS';
export const DICTONARY_SAVE_FAILURE = 'dictonary/DICTONARY_SAVE_FAILURE';

export const DICTONARY_GET_REQUESTED = 'dictonary/DICTONARY_GET_REQUESTED';
export const DICTONARY_GET_SUCCESS = 'dictonary/DICTONARY_GET_SUCCESS';
export const DICTONARY_GET_FAILURE = 'dictonary/DICTONARY_GET_FAILURE';

export const DICTONARY_DELETE_REQUESTED = 'dictonary/DICTONARY_DELETE_REQUESTED';
export const DICTONARY_DELETE_SUCCESS = 'dictonary/DICTONARY_DELETE_SUCCESS';
export const DICTONARY_DELETE_FAILURE = 'dictonary/DICTONARY_DELETE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DICTONARY_SAVE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DICTONARY_SAVE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        data: action.result.message
      };
    }
    case DICTONARY_SAVE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DICTONARY_GET_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DICTONARY_GET_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        wordList: action.result.dictonary,
        wordCount: action.result.totalCount
      };
    }
    case DICTONARY_GET_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DICTONARY_DELETE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DICTONARY_DELETE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        delMessage: action.result.message
      };
    }
    case DICTONARY_DELETE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const dictonarySave = (data) => {
  return {
    types: [DICTONARY_SAVE_REQUESTED, DICTONARY_SAVE_SUCCESS, DICTONARY_SAVE_FAILURE],
    promise: client => client.post('dictonarySave', { data }),
  };
};

export const dictonaryGet = (data) => {
  return {
    types: [DICTONARY_GET_REQUESTED, DICTONARY_GET_SUCCESS, DICTONARY_GET_FAILURE],
    promise: client => client.post('dictonaryGet', { data }),
  };
};

export const dictonaryDelete = (data) => {
  return {
    types: [DICTONARY_DELETE_REQUESTED, DICTONARY_DELETE_SUCCESS, DICTONARY_DELETE_FAILURE],
    promise: client => client.post('dictonaryDelete', { data }),
  };
};
