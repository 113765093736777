export const ADD_SAAS_OFFER_REQUESTED = 'saasFulfilment/ADD_SAAS_OFFER_REQUESTED';
export const ADD_SAAS_OFFER_SUCCESS = 'saasFulfilment/ADD_SAAS_OFFER_SUCCESS';
export const ADD_SAAS_OFFER_FAILURE = 'saasFulfilment/ADD_SAAS_OFFER_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_SAAS_OFFER_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_SAAS_OFFER_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case ADD_SAAS_OFFER_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const addSaasOffer = (data) => {
  return {
    types: [ADD_SAAS_OFFER_REQUESTED, ADD_SAAS_OFFER_SUCCESS, ADD_SAAS_OFFER_FAILURE],
    promise: client => client.post('addSaasOffer', { data })
  };
};
