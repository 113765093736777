export default {
  REMAINING_CREDIT: '/remainingcredit',
  SHARE_TRANSCRIPT: '/sharetranscription',
  CONVERT_SUBTITLES: '/convertsubtitles',
  INTERMIDIATE_SCREEN: '/intermidiatescreen',
  TRANSLTE_SIDEMENU: '/translatesidemenu',
  TRANSCRIBE_SIDEMENU: '/transcribesidemenu',
  WORKSPACE: 'workspace',
  HEADER_COMPONENT: 'headerComponent',
  DASHBOARD: 'dashboard',
  SIDEMENU_COMPONENT: 'SideMenuComponent',
  UPLOAD_TRANSCRIPTION: 'uploadTranscription',
  MyProfileSettings: 'myProfileSettings',
  TRANSCRIPTION_SCREEN: 'transcriptionScreen',
  TRANSLATE_APP: 'TranslateHeader',
  ADD_DICITONARY: 'addToDicitonaryPopup',
  ADD_DICITONARY_TABLE: 'addToDicitonaryTable',
  TRANSLATE_POPUP: 'translatePopup',
  TRANSLATE_V2: 'translateV2',
  SIDE_MENU_LOGO: 'logoamp',
  TIMECODE_POPUP: 'timecodePopup',
  TIMESTAMP_CHUNK: 'timestampChunkDownload',
  ANALYTICS: 'analyticsModule',
  AMP_WHAT_WOULD: 'ampRedirects',
  ISBUTTONAPP: 'isButtonApp',
  HIDE_RECORD: 'hideRecord',
  WIDGET_DISPLAY: 'widgetDisplay',
  ANALYTICS_PAGE: 'Analytics',
  API_ACCESS: 'apiAccess',
  MARKETING_MESSAGE: 'marketingMessage',
};
