import React from 'react';
import cn from 'classnames';
import * as availableLanguages from '@localization';
import Card from '@components/common/Card';
import {
  UncontrolledCollapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from 'reactstrap';
import { SIDE_MENU_CONFIG, ALLOWED_SERVICES } from '@constants';
import ModalComponent from '@components/common/Modal';
import checkPermission from '@helpers/CheckPermission';
import styles from './index.module.scss';

const CONSTANTS = availableLanguages.EN;

const HappyScribeSideMenuCom = (props) => {
  let { remainingTime } = props;
  const {
    workspaceList,
    onWorkspaceClick,
    onToggleModalSeeAllTeams,
    toggleModalSeeAllTeams,
    onWorkspaceListSearch,
    sidebarList,
    redirectionOnClick,
    handleManageWorkspace,
    isSideMenuActive,
  } = props;
  console.log('is side menu active ==========>', isSideMenuActive);
  remainingTime = remainingTime ? remainingTime.slice(0, remainingTime.lastIndexOf(':')) : '00:00';
  const redirectToNewWorkspace = () => {
    props.navigate('/workspace/new');
  };
  const redirectToDashboard = () => {
    window.localStorage.removeItem('folderArray');
    window.localStorage.setItem('workspaceId', 1);
    props.handleDashboardRedirect();
  };
  const getCardBody = () => {
    return (
      <div>
        <div className="d-flex align-items-center">
          <div className={styles.cardTitle}>
            {CONSTANTS.REMAINING_CREDIT}
          </div>
          {remainingTime && (
            <div className={cn('mb-0', styles.creditTime)}>
              {remainingTime}
            </div>
          )}
        </div>
        <button type="button" className={styles.creditButton} disabled={remainingTime !== '00:00'}>
          {CONSTANTS.BUY_MORE_HOURS}
        </button>
      </div>
    );
  };

  const generateSideMenuItems = (sideMenuArray, isFirstLevel = false) => {
    let menuItemList = sideMenuArray;
    if (isFirstLevel) {
      const url = new URL(window.location);
      const pathName = url.pathname.split('/')[1];
      menuItemList = sideMenuArray[pathName];
    }
    return menuItemList && menuItemList.map((menuItem) => {
      let translateResult = true;
      let transcribeResult = true;
      if (menuItem.name === 'Translate') {
        translateResult = checkPermission(ALLOWED_SERVICES.TRANSLTE_SIDEMENU);
      }
      if (menuItem.name === 'Transcribe') {
        transcribeResult = checkPermission(ALLOWED_SERVICES.TRANSCRIBE_SIDEMENU);
      }
      return (
        <>
          { translateResult && transcribeResult && (
          <li className={menuItem.className.li}>
            {menuItem.icon && (
            <i className={menuItem.icon} />
            )}
            <div
              id={`toggler_${menuItem.id}`}
              className={cn('cursor-pointer', styles[menuItem.className.a])}
              role="presentation"
              onClick={() => redirectionOnClick(menuItem.url)}
            >
              {menuItem.name}
            </div>
            {menuItem.submenu && (
            <UncontrolledCollapse toggler={`#toggler_${menuItem.id}`}>
              <ul className="nav nav-sm flex-column">
                {generateSideMenuItems(menuItem.submenu)}
              </ul>
            </UncontrolledCollapse>
            )}
          </li>
          )}
        </>
      );
    });
  };

  const generateWorkspace = (workspace, endIndex) => {
    const workspaceId = Number(window.localStorage.getItem('workspaceId'));
    const workspaceData = workspace && workspace.slice(0, endIndex).map((item) => {
      return (
        <>
          { item.id !== 1 && (
          <li className="nav-item pt-4 pl-3">
            <div className={cn('row nav-item', styles.workspaceTag, workspaceId === item.id ? styles.highlightWorkspace : 'pl-4')} role="presentation">
              <div className={styles.workspaceIcon} role="presentation" onClick={() => onWorkspaceClick(item.id, item.name)}>
                {item.name.charAt(0)}
              </div>
              <div className={styles.workspaceName} role="presentation" onClick={() => onWorkspaceClick(item.id, item.name)}>
                {item.name.length > 10 ? `${item.name.substr(0, 9)}...` : item.name}
              </div>
              { item.admin && (
              <div className={cn(styles.sideMenuOptions1)}>
                {/* <button type="button" className={cn('pl-2 border-none', styles.manageWorkspaceButton)} onClick={() => handleManageWorkspace(item.id, item.name)}>
                  <b>
                        ...
                  </b>
                </button> */}
                <div className="dropdown">
                  <UncontrolledDropdown className={cn(styles.sideMenuOptions)}>
                    <DropdownToggle tag="span">
                      {/* <div className="avatar avatar-sm"> */}
                      <i className={cn('fe fe-more-vertical', styles.moreVerticalColor)} />
                      {/* </div> */}
                    </DropdownToggle>
                    <DropdownMenu
                      right
                      className={styles.dropDownItemColor}
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: (datas) => {
                            return {
                              ...datas,
                              styles: {
                                ...datas.styles,
                                overflow: 'auto',
                                minHeight: '10px',
                              },
                            };
                          },
                        },
                      }}
                    >
                      <DropdownItem className={cn('pl-3 pr-3', styles.dropDownItemColor)}>
                        <a href={() => handleManageWorkspace(item.id, item.name)} onClick={() => handleManageWorkspace(item.id, item.name)}>
                          Manage Workspace
                        </a>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              )}
            </div>
          </li>
          )}
        </>
      );
    });
    return workspaceData;
  };

  const seeAllItem = () => {
    const seeAllItems = (
      <li className="nav-item pt-4 pl-3">
        <div className={cn('row nav-item pl-4', styles.workspaceTag)} role="presentation" onClick={() => onToggleModalSeeAllTeams()}>
          <div className={styles.workspaceIcon}>
            ...
          </div>
          <div className={styles.workspaceName}>
            See All Workspaces
          </div>
        </div>
      </li>
    );
    return seeAllItems;
  };

  const createWorkspaceComp = () => {
    const newWorkspace = (
      <li className="nav-item pt-4 pl-3">
        <div className={cn('row nav-item pl-4', styles.workspaceTag)} role="presentation" onClick={() => redirectToNewWorkspace()}>
          <div className={styles.workspaceIcon}>
            +
          </div>
          <div className={styles.workspaceName}>
            New Workspace
          </div>
        </div>
      </li>
    );
    return newWorkspace;
  };

  const myDashboard = () => {
    const dashboard = (
      <li className="nav-item pt-4 pl-3">
        <div className={cn('row nav-item pl-4', styles.workspaceTag)} role="presentation" onClick={() => redirectToDashboard()}>
          <div className={styles.workspaceIcon}>
            D
          </div>
          <div className={styles.workspaceName}>
            My Dashboard
          </div>
        </div>
      </li>
    );
    return dashboard;
  };

  const backToDashboard = () => {
    const workspaceId = Number(window.localStorage.getItem('workspaceId'));
    const workspaceName = CONSTANTS.BACK_TO + window.localStorage.getItem('workspaceName');
    let url = '/Dashboard';
    if (workspaceId !== 1) {
      url = `/workspace/${workspaceId}`;
    }
    const backTo = (
      <li className="nav-item pt-4 pl-4">
        <i className="fa fa-caret-left p-2" />
        <a
          href={url}
          id={`toggler_${workspaceId}`}
          className={styles.sideMenuItem}
        >
          {Number(window.localStorage.getItem('workspaceId')) > 1 ? workspaceName : CONSTANTS.BACK_TO_DASHBOARD}
        </a>
      </li>
    );
    return backTo;
  };

  const generateWorkspaceListForSeeAllItem = (workspace) => {
    const workspaceData = workspace && workspace.map((item) => {
      return (
        <>
          {item.id !== 1 && (
          <li className="nav-item pt-0 pl-1 pb-1">
            <div className={cn('row nav-item pl-4', styles.workspaceTag)} role="presentation" onClick={() => onWorkspaceClick(item.id, item.name)}>
              <div className={styles.workspaceIcon}>
                {item.name.charAt(0)}
              </div>
              <div className={styles.workspaceNamePopup}>
                {item.name}
              </div>
            </div>
          </li>
          )}
        </>
      );
    });
    return workspaceData;
  };

  return (
    <nav className={isSideMenuActive ? cn('border-bottom-0', styles.sideMenuLayout) : cn('border-bottom-0', styles.sideMenuLayoutInActive)}>
      <>
        <ul className="mt-5 pt-4 navbar-nav">
          {generateSideMenuItems(SIDE_MENU_CONFIG, true)}
          { window.location.href && (window.location.href.includes('/addnewprocess') || window.location.href.includes('/process') || window.location.href.includes('/translate')) && (
            <>
              {backToDashboard()}
            </>
          )}
          { window.location.href && !window.location.href.includes('/Dashboard') && (
            <>
              {myDashboard()}
            </>
          )}
          <>
            <li
              className={cn('nav-item pt-4', styles.workspacesHead)}
            >
              {CONSTANTS.WORKSPACES}
            </li>
            {generateWorkspace(sidebarList, 3)}
            {seeAllItem()}
            {createWorkspaceComp()}
          </>
        </ul>
        { checkPermission(ALLOWED_SERVICES.REMAINING_CREDIT) && (
        <div className={cn('mt-4', styles.cardAlign)}>
          <Card
            cardBody={getCardBody()}
          />
        </div>
        )}
        <ModalComponent
          type="popup"
          dialogClassName="align-items-center"
          isOpen={toggleModalSeeAllTeams}
          toggle={onToggleModalSeeAllTeams}
          size="md"
          customHeaderClass="fs-24 clr-black font-bold p-0"
          modalCloseClass="fs-20"
          modalTitleClass="w-10 text-center"
          contentClassName="border-radius-12 vh-50"
        >
          <div>
            <div className="row pb-2">
              <div className="col-12">
                <input type="text" placeholder={CONSTANTS.SEARCH_TEXT} className={styles.searchStyle} onChange={e => onWorkspaceListSearch(e.target.value)} />
              </div>
            </div>
            <div className={cn('container', styles.overFlowControl)}>
              <div className="row mt-1 d-flex">
                <div className="col-lg-12 col-12 text-left text-dark">
                  <ul className="navbar-nav">
                    {generateWorkspaceListForSeeAllItem(workspaceList)}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ModalComponent>
      </>
    </nav>
  );
};

export default HappyScribeSideMenuCom;
