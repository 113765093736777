export const API_ACCESS_REQUESTED = 'API_ACCESS_REQUESTED';
export const API_ACCESS_SUCCESS = 'API_ACCESS_SUCCESS';
export const API_ACCESS_FAILURE = 'API_ACCESS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,

};

export default (state = initialState, action) => {
  switch (action.type) {
    case API_ACCESS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case API_ACCESS_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case API_ACCESS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const apiAccessData = (data) => {
  // console.log('apiAccess reducer', data);
  return {
    types: [API_ACCESS_REQUESTED, API_ACCESS_SUCCESS, API_ACCESS_FAILURE],
    promise: client => client.post('apiAccess', { data }),
  };
};
