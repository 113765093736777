import React from 'react';
import * as availableLanguages from '@localization';
import { Button, ModalFooter } from 'reactstrap';

const FooterComponent = (props) => {
  const {
    onSubmit,
    onCancel,
    submitButtonName,
  } = props;

  const CONSTANTS = availableLanguages[props.selectedLanguage];

  return (
    <ModalFooter className="border-top-0">
      <Button color="primary" onClick={onSubmit}>
        {submitButtonName}
      </Button>
      {' '}
      {onCancel && (
        <Button color="secondary" onClick={onCancel}>
          {CONSTANTS.MODAL_CANCEL}
        </Button>
      )}
    </ModalFooter>
  );
};
export default FooterComponent;
