export const GET_LANGUAGE_LIST_REQUESTED = 'translateV2/GET_LANGUAGE_LIST_REQUESTED';
export const GET_LANGUAGE_LIST_SUCCESS = 'translateV2/GET_LANGUAGE_LIST_SUCCESS';
export const GET_LANGUAGE_LIST_FAILURE = 'translateV2/GET_LANGUAGE_LIST_FAILURE';

export const GET_TRANSLATED_LANGUAGE_LIST_REQUESTED = 'translateV2/GET_TRANSLATED_LANGUAGE_LIST_REQUESTED';
export const GET_TRANSLATED_LANGUAGE_LIST_SUCCESS = 'translateV2/GET_TRANSLATED_LANGUAGE_LIST_SUCCESS';
export const GET_TRANSLATED_LANGUAGE_LIST_FAILURE = 'translateV2/GET_TRANSLATED_LANGUAGE_LIST_FAILURE';

export const TRANSLATE_PROGRAM_REQUESTED = 'translateV2/TRANSLATE_PROGRAM_REQUESTED';
export const TRANSLATE_PROGRAM_SUCCESS = 'translateV2/TRANSLATE_PROGRAM_SUCCESS';
export const TRANSLATE_PROGRAM_FAILURE = 'translateV2/TRANSLATE_PROGRAM_FAILURE';

export const GET_TRANSLATED_PROGRAM_REQUESTED = 'translateV2/GET_TRANSLATED_PROGRAM_REQUESTED';
export const GET_TRANSLATED_PROGRAM_SUCCESS = 'translateV2/GET_TRANSLATED_PROGRAM_SUCCESS';
export const GET_TRANSLATED_PROGRAM_FAILURE = 'translateV2/GET_TRANSLATED_PROGRAM_FAILURE';

export const EDIT_TRANSLATED_PROGRAM_REQUESTED = 'translateV2/EDIT_TRANSLATED_PROGRAM_REQUESTED';
export const EDIT_TRANSLATED_PROGRAM_SUCCESS = 'translateV2/EDIT_TRANSLATED_PROGRAM_SUCCESS';
export const EDIT_TRANSLATED_PROGRAM_FAILURE = 'translateV2/EDIT_TRANSLATED_PROGRAM_FAILURE';

export const DOWNLOAD_TRANSLATED_PROGRAM_REQUESTED = 'translateV2/DOWNLOAD_TRANSLATED_PROGRAM_REQUESTED';
export const DOWNLOAD_TRANSLATED_PROGRAM_SUCCESS = 'translateV2/DOWNLOAD_TRANSLATED_PROGRAM_SUCCESS';
export const DOWNLOAD_TRANSLATED_PROGRAM_FAILURE = 'translateV2/DOWNLOAD_TRANSLATED_PROGRAM_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  languageList: [],
  translatedLanguageList: [],
  saveLoading: false,
  exportLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LANGUAGE_LIST_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_LANGUAGE_LIST_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        languageList: action.result
      };
    }
    case GET_LANGUAGE_LIST_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_TRANSLATED_LANGUAGE_LIST_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TRANSLATED_LANGUAGE_LIST_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        translatedLanguageList: action.result
      };
    }
    case GET_TRANSLATED_LANGUAGE_LIST_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case TRANSLATE_PROGRAM_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case TRANSLATE_PROGRAM_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        translatedLanguageList: action.result
      };
    }
    case TRANSLATE_PROGRAM_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_TRANSLATED_PROGRAM_REQUESTED:
    {
      return {
        ...state,
        loadingTranslateText: true,
        loaded: false,
      };
    }
    case GET_TRANSLATED_PROGRAM_SUCCESS:
    {
      console.log('stateee', action.result);
      return {
        ...state,
        loadingTranslateText: false,
        error: false,
        loaded: true,
        trans: action.result,
        translatedText: action.result.ctmdtoList
      };
    }
    case GET_TRANSLATED_PROGRAM_FAILURE:
    {
      return {
        ...state,
        loadingTranslateText: false,
        loaded: false,
        error: true,
      };
    }
    case EDIT_TRANSLATED_PROGRAM_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        saveLoading: true,
      };
    }
    case EDIT_TRANSLATED_PROGRAM_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        saveLoading: false,
      };
    }
    case EDIT_TRANSLATED_PROGRAM_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        saveLoading: false,
      };
    }
    case DOWNLOAD_TRANSLATED_PROGRAM_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        exportLoading: true,
      };
    }
    case DOWNLOAD_TRANSLATED_PROGRAM_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        exportLoading: false,
        file: action.result,
      };
    }
    case DOWNLOAD_TRANSLATED_PROGRAM_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        exportLoading: false,
      };
    }
    default:
      return state;
  }
};

export const getTranslationSupportedLanguages = () => {
  return {
    types: [GET_LANGUAGE_LIST_REQUESTED, GET_LANGUAGE_LIST_SUCCESS, GET_LANGUAGE_LIST_FAILURE],
    promise: client => client.get('fetchLanguageList')
  };
};

export const getTranslatedLanguages = (programId) => {
  return {
    types: [GET_TRANSLATED_LANGUAGE_LIST_REQUESTED, GET_TRANSLATED_LANGUAGE_LIST_SUCCESS, GET_TRANSLATED_LANGUAGE_LIST_FAILURE],
    promise: client => client.get(`translatedLanguageList/${programId}`)
  };
};

export const translateProgram = (programId, languageCode, languageName) => {
  return {
    types: [TRANSLATE_PROGRAM_REQUESTED, TRANSLATE_PROGRAM_SUCCESS, TRANSLATE_PROGRAM_FAILURE],
    promise: client => client.get(`translateProgram/${programId}/${languageCode}/${languageName}`)
  };
};

export const getTranslatedProgram = (programId, languageCode) => {
  return {
    types: [GET_TRANSLATED_PROGRAM_REQUESTED, GET_TRANSLATED_PROGRAM_SUCCESS, GET_TRANSLATED_PROGRAM_FAILURE],
    promise: client => client.get(`getTranslatedProgram/${programId}/${languageCode}`)
  };
};

export const editTranslatedProgram = (data) => {
  return {
    types: [EDIT_TRANSLATED_PROGRAM_REQUESTED, EDIT_TRANSLATED_PROGRAM_SUCCESS, EDIT_TRANSLATED_PROGRAM_FAILURE],
    promise: client => client.post('editTranslateProgram', { data })
  };
};

export const downloadTranslateProgram = (data) => {
  return {
    types: [DOWNLOAD_TRANSLATED_PROGRAM_REQUESTED, DOWNLOAD_TRANSLATED_PROGRAM_SUCCESS, DOWNLOAD_TRANSLATED_PROGRAM_FAILURE],
    promise: client => client.post('exportTranslateFile', { data })
  };
};
