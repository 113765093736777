export default {
  Dashboard: [
    {
      id: 2,
      name: 'What\'s New',
      url: '',
      //   icon: 'fa fa-newspaper-o',
      className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItem', wi: 'SGBF-open-6303c14e323c53001348f9cb' },
      allowedService: 'widgetDisplay'
    },
    {
      id: 3,
      name: 'Feature Request',
      url: 'https://feedback.fenek.ai/ideas',
      //   icon: 'fa fa-bolt',
      className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItem' },
      allowedService: 'widgetDisplay',
      newTab: true,
    },
  ]
};
