export default {

  endpoints: {
    dummy: '/kanari/dummy/api',
    loginapi: '/accounts/login',
    getUserDetailsForAzureUsers: '/accounts/getuserdetails',
    getDashboardCount: 'dashboard/get/count',
    getWeeklyUsage: '/dashboard/get/weekly/usage',
    temporarypassword: '/account/get/forgot/password/link',
    resetpassword: '/account/update/password',
    getUserDetails: '/user/profile/get',
    editUserDetails: '/user/profile/update',
    register: '/account/register',
    updateTranslatorKey: '/account/update/translatorkey',
    isEmailExist: '/account/is/email/exist',
    isUserNameExist: '/account/is/userName/exist',
    verifyEmail: '/account/verify/email',
    getLanguages: 'process/get/languages',
    addProcessfile: '/process/upload/file',
    addProcessurl: 'process/url',
    getProgramList: '/program/get/details',
    getProcessedFiles: '/process/get/program',
    downloadProcessedFile: '/process/download/processed/file',
    saveCTMFile: '/process/save/ctmfile',
    addTranslateCount: '/translations/add/count',
    forgotPasswordVerifyLink: '/account/verify/forgot/password/link',
    checkSubScriptionLimit: '/subscription/checksubscriptionlimit',
    getUserSubscriptionDetail: '/subscription/getusersubscriptiondetail',
    isPasswordSame: '/account/is/password/same',
    addSaasOffer: '/saas/addsaasoffer',
    checkPassword: '/user/profile/check',
    createFolder: '/file/create',
    getProgramListWithFolder: '/file/getlist',
    delete: '/file/delete',
    getFolderList: '/file/child',
    moveFolder: '/file/move',
    exportFile: '/file/export',
    renameFile: '/file/rename',
    duplicateFile: '/file/duplicate',
    fetchProcessedFiles: '/process/fetch/program',
    saveFile: '/process/ctmfile/save',
    fetchSubtitle: 'process/fetch/subtitles',
    addWorkspace: '/workspace/add',
    inviteUsers: '/workspace/addusers',
    workspaceLists: '/workspace/getWorkspacelist',
    workspaceDetails: '/workspace/getuserList',
    deleteWorkspace: '/workspace/removeWorkspace',
    removeUser: '/workspace/removeuser',
    fetchLanguageList: '/translate/get/languages',
    translatedLanguageList: '/translate/get/languages',
    translateProgram: '/translate/program',
    getTranslatedProgram: '/translate/program/get',
    editTranslateProgram: '/translate/program/update/',
    share: '/process/toggleshare',
    editSubtitle: '/process/edit/subtitles',
    downloadSrt: '/process/download/srt',
    dictonarySave: '/dictonary/savewords',
    dictonaryGet: '/dictonary/getwords',
    dictonaryDelete: '/dictonary/deletewords',
    dictonaryGetCount: '/dictonary/getwordcount',
    resendRegistrationLink: '/account/get/registeration/link',
    exportTranslateFile: '/translate/export',
    exportGroupedByTime: '/process/download/grouped',
    resendVerifyMail: '/account/get/registeration/email/link',
    sessionWatch: '/process/session/fetch',
    broadcastSession: '/liveSession/session-continuous-chunk',
    analytics: '/analytics/get',
    fetchProcessedFilesWithoutLogin: '/process/fetch/program/without/login',
    exportFileWithoutLogin: '/file/export/without/login',
    audioListFetch: '/process/mixer/list',
    saveMixture: '/process/mixer/save',
    sendSupportEmail: '/user/profile/support',
    apiAccess: '/apiAccess',
    fetchMixture: '/process/mixer/fetch',
    getProgramMixtureList: '/file/get/mixture/list',
    renameFileMixture: '/file/transcripts/rename/delete',
    downloadMixtureTranscript: '/process/mixer/download',
    analyticsUsage: '/analytics/getTime',
    analyticsUsageOfUser: '/subscription/get/time',
    analyticsPending: '/analytics/pending/count',
    stripeCreateCharge: '/stripe/create/charge',
    stripeCreateSession: '/stripe/create/session',
    usertabledata: '/account/get/details',
    checkSubscription: '/stripe/check/subscribed',
    cancelSubscription: '/stripe/cancel/subscription',
    analyticsgraphdata: '/analytics/graph/data',
    analyticsRegistrationGraphData: '/account/get/regdata',
    downloadUserAccountTabledata: '/account/export/report',
    downloadUserUsageTabledata: '/subscription/download/report',
    getPopupUserDetails: '/analytics/user/details',
    continuosLiveChunk: '/liveSession/save',
    saveLiveData: '/process/session',
    downloadProcessedFileWithoutLogin: '/process/download/processed/file/without/login'
  },
};
