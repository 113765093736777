export const PROGRAM_DETAILS_REQUESTED = 'programs/VIEW_ADMIN_ACCOUNT_REQUESTED';
export const PROGRAM_DETAILS_SUCCESS = 'programs/VIEW_ADMIN_ACCOUNT_SUCCESS';
export const PROGRAM_DETAILS_FAILURE = 'programs/VIEW_ADMIN_ACCOUNT_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  programList: [],
  totalCounts: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROGRAM_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case PROGRAM_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        programList: action.result.programDetails.programProcessListDTO,
        totalCounts: action.result.programDetails.count
      };
    }
    case PROGRAM_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
}

export const getProgramList = (itemsPerPage, pageNo, sortKey, sortDirection) => {
  return {
    types: [PROGRAM_DETAILS_REQUESTED, PROGRAM_DETAILS_SUCCESS, PROGRAM_DETAILS_FAILURE],
    promise: client => client.get(`getProgramList/${itemsPerPage}/${pageNo}?sortKey=${sortKey}&sortDirection=${sortDirection}`)
  };
};
