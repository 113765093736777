export const LANGUAGE_REQUESTED = 'LANGUAGE/LANGUAGE_REQUESTED';
export const LANGUAGE_SUCCESS = 'LANGUAGE/LANGUAGE_SUCCESS';
export const LANGUAGE_FAILURE = 'LANGUAGE/LANGUAGE_FAILURE';

export const FILE_UPLOAD_REQUESTED = 'FILE_UPLOAD_REQUESTED';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';

export const URL_REQUESTED = 'URL_REQUESTED';
export const URL_SUCCESS = 'URL_SUCCESS';
export const URL_FAILURE = 'URL_FAILURE';

export const CHECK_SUB_SUBSCRIPTION_LIMIT_REQUESTED = 'CHECK_SUB_SUBSCRIPTION_LIMIT_REQUESTED';
export const CHECK_SUB_SUBSCRIPTION_LIMIT_SUCCESS = 'CHECK_SUB_SUBSCRIPTION_LIMIT_SUCCESS';
export const CHECK_SUB_SUBSCRIPTION_LIMIT_FAILURE = 'CHECK_SUB_SUBSCRIPTION_LIMIT_FAILURE';

export const GET_USER_SUBSCRIPTION_DETAILS_REQUESTED = 'GET_USER_SUBSCRIPTION_DETAILS_REQUESTED';
export const GET_USER_SUBSCRIPTION_DETAILS_SUCCESS = 'GET_USER_SUBSCRIPTION_DETAILS_SUCCESS';
export const GET_USER_SUBSCRIPTION_DETAILS_FAILURE = 'GET_USER_SUBSCRIPTION_DETAILS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  data: [],
  isSideMenuActive: true,
  mediaLength: '',
  subscriptionErrorMsg: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LANGUAGE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        langdata: action.result
      };
    }
    case LANGUAGE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case FILE_UPLOAD_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        isSideMenuActive: false,
      };
    }
    case FILE_UPLOAD_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        filepath: action.result.path,
        isSideMenuActive: false,
        mediaLength: action.result.mediaLength,
      };
    }
    case FILE_UPLOAD_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        isSideMenuActive: false,
      };
    }
    case URL_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        isSideMenuActive: false,
      };
    }
    case URL_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        programId: action.result.programId,
        status: action.result.status,
        isSideMenuActive: true,
      };
    }
    case URL_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        isSideMenuActive: true,
      };
    }
    case CHECK_SUB_SUBSCRIPTION_LIMIT_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CHECK_SUB_SUBSCRIPTION_LIMIT_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        remainingTime: action.result.remainingTime,
        videoLength: action.result.videoLength
      };
    }
    case CHECK_SUB_SUBSCRIPTION_LIMIT_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        subscriptionErrorMsg: action.error.errorMessage,
      };
    }

    case GET_USER_SUBSCRIPTION_DETAILS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_USER_SUBSCRIPTION_DETAILS_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        packName: action.result.name,
        packDescription: action.result.description,
        requestedTime: action.result.timeRequested,
        availableTime: action.result.timeAvailable
      };
    }
    case GET_USER_SUBSCRIPTION_DETAILS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    default:
      return state;
  }
};

export const languageCall = () => {
  return {
    types: [LANGUAGE_REQUESTED, LANGUAGE_SUCCESS, LANGUAGE_FAILURE],
    promise: client => client.get('getLanguages')
  };
};

export const fileUpload = (file, dirId, langCode) => {
  const data = new FormData();
  data.append('file', file);
  return {
    types: [FILE_UPLOAD_REQUESTED, FILE_UPLOAD_SUCCESS, FILE_UPLOAD_FAILURE],
    promise: client => client.post(`addProcessfile?dirId=${dirId}&langCode=${langCode}`, {
      data
    })
  };
};

export const urlUpload = (data) => {
  return {
    types: [URL_REQUESTED, URL_SUCCESS, URL_FAILURE],
    promise: client => client.post('addProcessurl', {
      data,
    })
  };
};

export const checkSubScriptionLimit = (data) => {
  return {
    types: [CHECK_SUB_SUBSCRIPTION_LIMIT_REQUESTED, CHECK_SUB_SUBSCRIPTION_LIMIT_SUCCESS, CHECK_SUB_SUBSCRIPTION_LIMIT_FAILURE],
    promise: client => client.post('checkSubScriptionLimit', {
      data,
    })
  };
};

export const getSubscriptionDetail = () => {
  return {
    types: [GET_USER_SUBSCRIPTION_DETAILS_REQUESTED, GET_USER_SUBSCRIPTION_DETAILS_SUCCESS, GET_USER_SUBSCRIPTION_DETAILS_FAILURE],
    promise: client => client.get('getUserSubscriptionDetail')
  };
};
