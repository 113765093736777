import React, { useEffect } from 'react';
// import avatarOne from '@assets/img/avatars/profiles/avatar-1.jpg';
// import logo from '@assets/img/illustrations/kanari_logo_v2.png';
import profileLogo from '@assets/img/illustrations/profileimg.png';
import {
  UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Collapse
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import cn from 'classnames';
import checkPermission from '@helpers/CheckPermission';
import HappyScribeSideMenu from '@containers/common/HappyScribeSideMenu';
import * as availableLanguages from '@localization';
import { getCurrentFolder } from '@helpers/Utility';
import ComponentCheckPermission from '@helpers/ComponentCheckPermission';
import GetKeyValue from '@helpers/GetKeyValue';
import { Link } from 'react-router-dom';
import styles from './index.module.css';
import { APP_CONFIG, ALLOWED_SERVICES } from '@constants';

const CONSTANTS = availableLanguages.EN;

const HeaderCom = (props) => {
  let { remainingTime } = props;
  console.log('rreeee', remainingTime);
  const splittedTime = remainingTime && remainingTime.split(':');
  let hours = 0;
  let minute = 0;
  if (splittedTime && splittedTime.length >= 2) {
    hours = splittedTime[0];
    minute = splittedTime[1];
  }
  const hoursTitle = Number(hours) > 1 ? 'Hours' : 'Hour';
  console.log('splitted time', splittedTime);
  remainingTime = remainingTime ? remainingTime.slice(0, remainingTime.lastIndexOf(':')) : '00:00';
  const audioName = props.audioName;
  const redirectBack = (url) => {
    if (window.location.pathname.includes('/process/') || window.location.pathname.includes('/translate/')) {
      props.navigate(url);
    } else {
      props.navigate(`${APP_CONFIG.BASE_URL}${url}`);
    }
  };

  useEffect(() => {
    const SGVFLoader = window.SGBFWidgetLoader;
    console.log('sgvf', SGVFLoader, window);
  }, []);

  const uploadFile = () => {
    const directoryList = getCurrentFolder();
    const lastVisitedFolder = directoryList[directoryList.length - 1];
    const folderId = btoa(lastVisitedFolder.value);
    const workspaceId = btoa(Number(window.localStorage.getItem('workspaceId')));
    if (window.location.pathname.includes('/process/') || window.location.pathname.includes('/translate/')) {
      props.navigate(`/addnewprocess/uploads/${folderId}/${workspaceId}`);
    } else {
      props.navigate(`${APP_CONFIG.BASE_URL}/addnewprocess/uploads/${folderId}/${workspaceId}`);
    }
  };

  const createNewSession = () => {
    if (window.location.pathname.includes('/process/') || window.location.pathname.includes('/translate/')) {
      props.navigate('/liveRecord');
    } else {
      props.navigate(`${APP_CONFIG.BASE_URL}/liveRecord`);
    }
  };

  const generateProfileDropdown = () => {
    const userData = window.localStorage.getItem('userData');
    console.log('analytics', ComponentCheckPermission(ALLOWED_SERVICES.ANALYTICS));
    const { name, email } = JSON.parse(userData).endUserDetails;
    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="a">
          {checkPermission(ALLOWED_SERVICES.ISBUTTONAPP) ? (
            <img
              alt="..."
              className={cn(styles.avatar, 'rounded-circle')}
              src={profileLogo}
            />
          ) : (
            <svg
              className={styles.profile}
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 21C24.1421 21 27.5 17.6421 27.5 13.5C27.5 9.35791 24.1421 6 20 6C15.8579 6 12.5 9.35791 12.5 13.5C12.5 17.6421 15.8579 21 20 21Z"
                fill="#2F2BBC"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 20C0 24.5034 1.48828 28.657 4 32C7.64893 36.8564 13.4575 40 20 40C26.2134 40 31.7646 37.1667 35.4331 32.7218C35.6284 32.4852 35.8184 32.2456 36.0029 32C38.5132 28.6576 40 24.5017 40 20C40 8.95435 31.0459 0 20 0C8.9541 0 0 8.95435 0 20ZM20.0015 24C10.5103 24 6.229 26.234 4.59082 30.0593C2.69971 27.1682 1.6001 23.7125 1.6001 20C1.6001 9.83801 9.83789 1.59998 20 1.59998C30.1621 1.59998 38.3999 9.83801 38.3999 20C38.3999 23.7117 37.3013 27.1669 35.4106 30.0575C33.7705 26.2333 29.4907 24 20.0015 24Z"
                fill="#2F2BBC"
              />
            </svg>
          )}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>
            {name}
          </DropdownItem>
          <DropdownItem header>
            {email}
          </DropdownItem>
          <DropdownItem divider />
          {/* <DropdownItem disabled>
            My Transcript
          </DropdownItem>
          <DropdownItem disabled>
            Top-up Plan
          </DropdownItem>
          <DropdownItem disabled>
            Vocabulary
          </DropdownItem> */}
          <DropdownItem className={cn('pb-0')}>
            <Link to="/addtodictonary" className={cn(styles.dropDownItemColor, 'nav-link pl-0')}>
              Add to Dictionary
            </Link>
          </DropdownItem>
          {checkPermission(ALLOWED_SERVICES.API_ACCESS)
          && (
          <DropdownItem>
            <Link to="/apiAccess" className={cn(styles.dropDownItemColor, 'nav-link pl-0')}>
              API Access
            </Link>
          </DropdownItem>
          )
            }
          {checkPermission(ALLOWED_SERVICES.ISBUTTONAPP)
          && (
          <DropdownItem className={cn('pb-0')}>
            <Link to="/paymentplans" className={cn(styles.dropDownItemColor, 'nav-link pl-0')}>
              Plans
            </Link>
          </DropdownItem>
          )}
          <DropdownItem className="pb-0">
            <Link to="/support" className={cn(styles.dropDownItemColor, 'nav-link pl-0')}>
              Support
            </Link>
          </DropdownItem>
          {/* <DropdownItem className="pb-0" container="body">
            <div className={cn('SGBF-open-62415144ea425400121c2fc7')}>
            What is New
            </div>
          </DropdownItem> */}
          {/* <DropdownItem className={cn('pb-0')}>
            <a href="https://help.kanari.ai/" className={cn(styles.dropDownItemColor, 'nav-link pl-0')} target="_blank" rel="noopener noreferrer">
              Help
            </a>
          </DropdownItem> */}
          <DropdownItem>
            <Link
              to="/users/settings"
              className={
                props.isSideMenuActive
                  ? cn(styles.dropDownItemColor, 'nav-link pl-0')
                  : cn(styles.dropDownItemColor, 'nav-link disabled pl-0')
              }
            >
              Settings
            </Link>
          </DropdownItem>
          {/* {ComponentCheckPermission(ALLOWED_SERVICES.ANALYTICS) === 'Analytics' && (
            <DropdownItem>
              <Link to="/analytics" className={cn(styles.dropDownItemColor, 'nav-link pl-0')}>
                Daily Analytics
              </Link>
            </DropdownItem>
          )} */}
          <DropdownItem className="pt-1">
            <Link to="/logout" className={cn(styles.dropDownItemColor, 'nav-link pl-0 pt-0')}>
              {CONSTANTS.LOGOUT}
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const topBarname = () => {
    console.log('payhhhhhhh');
    const path = window.location.pathname;
    if (path.includes('/mixture/replay')) {
      const name = props.mixtureName.length > 30
        ? `${props.mixtureName.substring(0, 20)}.....${props.mixtureName.substring(props.mixtureName.length - 5, props.mixtureName.length)}`
        : props.mixtureName;
      return name;
    }
    let name = audioName.length > 30
      ? `${audioName.substring(0, 20)}.....${audioName.substring(audioName.length - 5, audioName.length)}`
      : audioName;
    if (path.includes('/process')) {
      console.log('pathname', path);
      const pathArray = path.split('/');
      console.log('pathArray', pathArray);
      const status = atob(pathArray[3]);
      console.log('status', status);
      if (status === '1' || status === '2' || status === '4') {
        const workspaceName = window.localStorage.getItem('workspaceName');
        name = Number(window.localStorage.getItem('workspaceId')) > 1 ? workspaceName : CONSTANTS.Dashboard;
      }
    }
    return props.fetchLoading ? '' : name;
  };

  const backToDashboard = () => {
    const theme = createMuiTheme({
      overrides: {
        MuiTooltip: {
          tooltip: {
            color: 'white !important',
            backgroundColor: 'black !important',
          },
        },
      },
    });
    const workspaceId = Number(window.localStorage.getItem('workspaceId'));
    const workspaceName = window.localStorage.getItem('workspaceName');
    // if (window.location.href.includes('/workspace/new')) {
    //   workspaceName = 'Dashboard';
    // } else if (window.location.href.includes('/addnewprocess')) {
    //   workspaceName = 'Transcribe';
    // }
    let url = '/Dashboard';
    if (workspaceId !== 1) {
      url = `/workspace/${workspaceId}`;
    }
    const path = window.location.pathname;
    let nameForSupportSetting = 'Contact Us';
    if (path.includes('/addtodictonary')) {
      nameForSupportSetting = 'Dictionary';
    } else if (path.includes('/users/settings')) {
      nameForSupportSetting = 'Settings';
    } else if (path.includes('/paymentplans')) {
      nameForSupportSetting = 'Plans';
    }
    const tooltipTitle = path.includes('/mixture/replay') ? props.mixtureName : audioName;
    const record = window.location.href.includes('/addnewprocess') ? 'Upload' : 'Live Recording';
    const backTo = (
      <div className={cn('pl-3', styles.audio)}>
        <span
          onClick={props.isSideMenuActive ? () => redirectBack(url) : ''}
          role="presentation"
          className={cn(styles.backTo, props.isSideMenuActive ? '' : styles.backToDisabled)}
        >
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.svgWidth}>
            <path
              d="M20.0002 36.6668C29.2049 36.6668 36.6668 29.2049 36.6668 20.0002C36.6668 10.7954 29.2049 3.3335 20.0002 3.3335C10.7954 3.3335 3.3335 10.7954 3.3335 20.0002C3.3335 29.2049 10.7954 36.6668 20.0002 36.6668Z"
              fill="#2F2BBC"
              stroke="#2F2BBC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.6667 13.3335L16 20.0002L22.6667 26.6668"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        {(path.includes('/process')
          || path.includes('/translate')
          || path.includes('/subtitle')
          || path.includes('/session/watch')
          || path.includes('/mixture/replay'))
        && (props.audioName || props.mixtureName) ? (
          <MuiThemeProvider theme={theme}>
            <Tooltip title={tooltipTitle} placement="bottom">
              <span className={cn(styles.dashboardNamee)}>
                {topBarname()}
              </span>
            </Tooltip>
          </MuiThemeProvider>
          ) : (
          <>
            {window.location.href.includes('/addnewprocess')
            || window.location.href.includes('/liveRecord')
            || window.location.href.includes('/analytics') ? (
              <>
                {window.location.href.includes('/analytics') ? (
                  <span className={cn(styles.dashboardNameee)}>
Analytics Report
                  </span>
                ) : (
                  <span className={cn(styles.dashboardNamee)}>
                    {record}
                  </span>
                )}
              </>
              ) : (
              <>
                {window.location.href.includes('/support') || window.location.href.includes('/users/settings') || window.location.href.includes('/addtodictonary') || window.location.href.includes('/paymentplans') ? (
                  <span className={cn(styles.dashboardNameee)}>
                    {nameForSupportSetting}
                  </span>
                ) : (
                  <span className={cn(styles.dashboardNameee)}>
                    {Number(window.localStorage.getItem('workspaceId')) > 1 ? workspaceName : CONSTANTS.Dashboard}
                  </span>
                )}
              </>
              )}
          </>
          )}
      </div>
    );
    return backTo;
  };

  console.log('window.location', window.location.pathname);
  return (
    <div className="col-md-12 p-0 m-0 mb-4">
      { checkPermission(ALLOWED_SERVICES.MARKETING_MESSAGE) && (
        <nav
          className={cn(
            'navbar navbar-expand',
            styles.backGroundColorForMarketing
          )}
          id="sidebar1"
        >
          <div className="col-12">
            <p className={styles.marketMessageStyle}>
              {GetKeyValue(ALLOWED_SERVICES.MARKETING_MESSAGE)}
            </p>
          </div>
        </nav>
      )}
      <nav
        className={cn(
          'navbar navbar-expand',
          window.location.href.includes('/mixture/tool') ? styles.backGroundColorMixture : styles.backGroundColor,
          // checkPermission(ALLOWED_SERVICES.MARKETING_MESSAGE) ? styles.doubleHeaderHeight : styles.singleHeaderHeight
        )}
        id="sidebar"
      >
        <div className="container-fluid">
          <button
            type="button"
            onClick={props.toggleSideNav}
            className={cn('d-inline d-md-none', styles.sideMenuButton)}
          >
            <span className={props.isSideNavOpen ? 'fa fa-close' : 'fa fa-bars'} />
          </button>
          {/* <img src={logo} className="navbar-brand-img" alt="..." /> */}
          {window.location.href
            && (window.location.href.includes('/addnewprocess')
              || window.location.href.includes('/analytics')
              || window.location.href.includes('/process')
              || window.location.href.includes('/translate')
              || window.location.href.includes('/workspace/new')
              || window.location.href.includes('/workspace/edit')
              || window.location.href.includes('/users/settings')
              || window.location.href.includes('/addtodictonary')
              || window.location.href.includes('/subtitle')
              || window.location.href.includes('/support')
              || window.location.href.includes('/liveRecord')
              || window.location.href.includes('/session/watch')
              || window.location.href.includes('/paymentplans')
              || window.location.href.includes('/stripe')
              || window.location.href.includes('/mixture') || window.location.href.includes('/apiAccess')) && <>{backToDashboard()}</>}
          {window.location.href
            && (window.location.href.includes('/Dashboard')
              || (window.location.href.includes('/workspace')
                && !(
                  window.location.href.includes('/workspace/new') || window.location.href.includes('/workspace/edit')
                ))) && (
                <div className={cn('form-group has-search input-group input-group-merge pl-1 pl-md-0', styles.searchBox)}>
                  {/* <span className={cn('fa fa-search form-control-search', styles.searchIcon)} />
                  <input
                    type="text"
                    placeholder="Search file or folder name"
                    onChange={e => props.handleSerach(e.target.value)}
                    value={props.searchValue}
                    className={cn('', styles.textBox)}
                  />
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={e => props.handleSerach(e.target.value)}
                    value={props.searchValue}
                    className={cn('', styles.textBoxSmall)}
                  />
                  <div className="input-group-append">
                    <span className={cn('input-group-text', styles.icon)}>
                      {props.searchValue.length > 0 ? (
                        <svg
                          width="24"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          role="presentation"
                          onClick={() => props.handleSerach('')}
                        >
                          <path
                            d="M18 6L6 18"
                            stroke="#1F1F1F"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 6L18 18"
                            stroke="#1F1F1F"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        <span style={{ width: '24px' }} />
                      )}
                    </span>
                  </div> */}
                </div>
          )}
          <div>
            <ul className="navbar-nav align-items-center mr-auto">
              {/* <li className="nav-item" id="support">
                <a
                  href="mailto:qats@qf.org.qa"
                  className={cn('nav-link disabled', styles.navFont)}
                  id="support"
                >
                  {CONSTANTS.SUPPORT}
                </a>
              </li>
              <li className="nav-item">
                <a href="/logout" className={cn('nav-link', styles.navFont)}>
                  {CONSTANTS.LOGOUT}
                </a>
              </li> */}
              { checkPermission(ALLOWED_SERVICES.WIDGET_DISPLAY) && checkPermission(window.location.pathname) && (
              <div className="row pr-3 pr-md-2 pr-lg-2 pr-xl-4">
                <li className={cn('nav-item pr-2 pr-md-2 pr-lg-2 pr-xl-4', 'SGBF-open-62415144ea425400121c2fc7')}>
                  <div className={cn('row', styles.fetaureRequest)}>
                    {CONSTANTS.FEATURE_REQUEST}
                  </div>
                </li>
              </div>
              )}
              { checkPermission(ALLOWED_SERVICES.WIDGET_DISPLAY) && checkPermission(window.location.pathname) && (
              <div className="row pr-3 pr-md-2 pr-lg-2 pr-xl-4">
                <li className={cn('nav-item pr-2 pr-md-2 pr-lg-2 pr-xl-4', 'SGBF-open-62415144ea425400121c2fc7')}>
                  <div className={cn('row', styles.whatsNew)}>
                    {CONSTANTS.WHATS_NEW}
                  </div>
                </li>
              </div>
              )}
              { checkPermission(ALLOWED_SERVICES.REMAINING_CREDIT) && (
              <div className="row pr-3 pr-md-2 pr-lg-2 pr-xl-4">
                <li className="nav-item pr-2 pr-md-2 pr-lg-2 pr-xl-4">
                  <div className={cn('row', styles.RemainingCredits)}>
                  Remaining Balance
                  </div>
                  <div className={cn(styles.remainingTimeStyle, 'row')}>
                    {`${hours} ${hoursTitle} ${minute} Minutes`}
                  </div>
                </li>
                {/* <li className="nav-item pr-2 pr-lg-4" id="transcribe">
                  <button type="button" className={false ? styles.TranscribeButton : styles.TranscribeButtonAppBuyCred} onClick={() => { console.log('buy more hours'); }}>
                    <span className="pl-2">
                    Buy credits
                    </span>
                  </button>
                </li> */}
              </div>
              )}
              { checkPermission(ALLOWED_SERVICES.HIDE_RECORD) && (
                <>
                  { window.location.href && (window.location.href.includes('/Dashboard') || window.location.href.includes('/workspace') || window.location.href.includes('/apiAccess')) && !(window.location.href.includes('/liveRecord') || window.location.href.includes('/workspace/new') || window.location.href.includes('/workspace/edit') || window.location.href.includes('/process') || window.location.href.includes('/translate')) && (
                  <li className="nav-item pr-2 pr-lg-4" id="transcribe">
                    <button type="button" className={checkPermission(ALLOWED_SERVICES.ISBUTTONAPP) ? styles.TranscribeButtonAppR : styles.TranscribeButtonR} onClick={() => createNewSession()}>
                      {/* <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.14518 4.69226C9.14518 2.65308 10.7983 1 12.8375 1C14.8767 1 16.5298 2.65308 16.5298 4.69226V10.2307C16.5298 12.2699 14.8767 13.9231 12.8375 13.9231C10.7983 13.9231 9.14518 12.2699 9.14518 10.2307V4.69226Z" fill="#FFFFFF" />
                      <path d="M12.8375 16.6094C9.65562 16.6094 7.01792 14.2797 6.53715 11.2329C6.45054 10.6841 6.00846 10.2307 5.45286 10.2307C4.89725 10.2307 4.44053 10.6832 4.50632 11.2349C4.96641 15.0935 8.04478 18.1477 11.9144 18.5713V21.2245C11.9144 21.7803 12.3649 22.2307 12.9206 22.2307C13.4764 22.2307 13.9269 21.7803 13.9269 21.2245V18.5515C17.7182 18.0599 20.7152 15.0381 21.1686 11.2349C21.2344 10.6832 20.7777 10.2307 20.2221 10.2307C19.6665 10.2307 19.2244 10.6841 19.1378 11.2329C18.657 14.2797 16.0193 16.6094 12.8375 16.6094Z" fill="#FFFFFF" />
                    </svg> */}
                      <span className="pl-2">
                    Record
                      </span>
                    </button>
                  </li>
                  )}
                  </>
              )}
              { checkPermission(ALLOWED_SERVICES.REMAINING_CREDIT) && (
                  <>
                    { window.location.href && !(window.location.href.includes('/addnewprocess') || window.location.href.includes('/workspace/new') || window.location.href.includes('/workspace/edit') || window.location.href.includes('/process') || window.location.href.includes('/translate') || window.location.href.includes('/liveRecord')) && (
                    <li className="nav-item pr-1 pr-md-4" id="transcribe">
                      <button type="button" className={checkPermission(ALLOWED_SERVICES.ISBUTTONAPP) ? styles.TranscribeButtonApp : styles.TranscribeButton} onClick={() => uploadFile()}>
                        {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 13L12 9L8 13" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M12 9V21" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M7 7H5C3.89543 7 3 6.10457 3 5V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V5C21 6.10457 20.1046 7 19 7H17" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" />
                    </svg> */}
                        <span className={cn(styles.alignCenter)}>
                          Upload
                        </span>
                      </button>
                    </li>
                    )}
              </>
              )}
              <li>
                {generateProfileDropdown()}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <Collapse isOpen={props.isSideNavOpen} className={cn(styles.backGroundSidemenu)}>
          <HappyScribeSideMenu />
        </Collapse>
      </div>
    </div>
  );
};

export default HeaderCom;
