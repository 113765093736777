export const REGISTER_REQUESTED = 'register/REGISTER_REQUESTED';
export const REGISTER_SUCCESS = 'register/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'register/REGISTER_FAILURE';

export const IS_EMAIL_EXIST_REQUESTED = 'register/IS_EMAIL_EXIST_REQUESTED';
export const IS_EMAIL_EXIST_SUCCESS = 'register/IS_EMAIL_EXIST_SUCCESS';
export const IS_EMAIL_EXIST_FAILURE = 'register/IS_EMAIL_EXIST_FAILURE';

export const IS_USERNAME_EXIST_REQUESTED = 'register/IS_USERNAME_EXIST_REQUESTED';
export const IS_USERNAME_EXIST_SUCCESS = 'register/IS_USERNAME_EXIST_SUCCESS';
export const IS_USERNAME_EXIST_FAILURE = 'register/IS_USERNAME_EXIST_FAILURE';

export const VERIFY_EMAIL_REQUESTED = 'register/VERIFY_EMAIL_REQUESTED';
export const VERIFY_EMAIL_SUCCESS = 'register/VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'register/VERIFY_EMAIL_FAILURE';

export const TRANSLATOR_KEY_REQUESTED = 'translatorKey/TRANSLATOR_KEY_REQUESTED';
export const TRANSLATOR_KEY_SUCCESS = 'translatorKey/TRANSLATOR_KEY_SUCCESS';
export const TRANSLATOR_KEY_FAILURE = 'translatorKey/TRANSLATOR_KEY_FAILURE';

export const RESEND_MAIL_REQUESTED = 'register/RESEND_MAIL_REQUESTED';
export const RESEND_MAIL_SUCCESS = 'register/RESEND_MAIL_SUCCESS';
export const RESEND_MAIL_FAILURE = 'register/RESEND_MAIL_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  isEmail: false,
  isUserName: false,
  data: [],
  isVerified: true,
  errorDesc: '',
  resendingloading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case REGISTER_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        registeredUserName: action.result.registeredUserName
      };
    }
    case REGISTER_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case TRANSLATOR_KEY_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case TRANSLATOR_KEY_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case TRANSLATOR_KEY_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case IS_EMAIL_EXIST_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        isEmail: false
      };
    }
    case IS_EMAIL_EXIST_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        isEmail: false
      };
    }
    case IS_EMAIL_EXIST_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        isEmail: true
      };
    }
    case IS_USERNAME_EXIST_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        isUserName: false
      };
    }
    case IS_USERNAME_EXIST_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        isUserName: false
      };
    }
    case IS_USERNAME_EXIST_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        isUserName: true
      };
    }
    case VERIFY_EMAIL_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        isVerified: false
      };
    }
    case VERIFY_EMAIL_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        isVerified: true
      };
    }
    case VERIFY_EMAIL_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        isVerified: false,
        errorDesc: action.error,
      };
    }
    case RESEND_MAIL_REQUESTED:
    {
      return {
        ...state,
        resendingloading: true,
        loaded: false
      };
    }
    case RESEND_MAIL_SUCCESS:
    {
      return {
        ...state,
        resendingloading: false,
        error: false,
        loaded: true
      };
    }
    case RESEND_MAIL_FAILURE:
    {
      return {
        ...state,
        resendingloading: false,
        loaded: false,
        error: true
      };
    }
    default:
      return state;
  }
};

export const register = (data) => {
  return {
    types: [REGISTER_REQUESTED, REGISTER_SUCCESS, REGISTER_FAILURE],
    promise: client => client.post('register', { data })
  };
};

export const updateTranslatorApiKey = (data) => {
  return {
    types: [TRANSLATOR_KEY_REQUESTED, TRANSLATOR_KEY_SUCCESS, TRANSLATOR_KEY_FAILURE],
    promise: client => client.post('updateTranslatorKey', { data })
  };
};

export const isEmailExist = (data) => {
  return {
    types: [IS_EMAIL_EXIST_REQUESTED, IS_EMAIL_EXIST_SUCCESS, REGISTER_FAILURE],
    promise: client => client.get(`isEmailExist?email=${data.email}&subdomain=${data.subdomain}`)
  };
};

export const isUserNameExist = (data) => {
  return {
    types: [IS_USERNAME_EXIST_REQUESTED, IS_USERNAME_EXIST_SUCCESS, IS_USERNAME_EXIST_FAILURE],
    promise: client => client.get(`isUserNameExist?userName=${data.userName}&subdomain=${data.subdomain}`)
  };
};

export const verifyEmail = (data) => {
  return {
    types: [VERIFY_EMAIL_REQUESTED, VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL_FAILURE],
    promise: client => client.get(`verifyEmail?userId=${data}`)
  };
};

export const resendLink = (userId) => {
  return {
    types: [RESEND_MAIL_REQUESTED, RESEND_MAIL_SUCCESS, RESEND_MAIL_FAILURE],
    promise: client => client.get(`resendRegistrationLink?userId=${userId}`)
  };
};
