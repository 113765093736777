export default {
  Dashboard: [
    {
      id: 1,
      name: 'Dashboard',
      url: '/Dashboard',
      icon: '',
      className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItem' },
      allowedService: ''
    },
    // {
    //   id: 2,
    //   name: 'What\'s New',
    //   url: '',
    //   icon: 'fa fa-newspaper-o',
    //   className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItem', wi: 'SGBF-open-62415144ea425400121c2fc7' },
    //   allowedService: 'widgetDisplay'
    // },
    // {
    //   id: 3,
    //   name: 'Feature Request',
    //   url: '',
    //   icon: 'fa fa-bolt',
    //   className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItem', wi: 'SGBF-open-62415144ea425400121c2fc7' },
    //   allowedService: 'widgetDisplay',
    // },
    {
      id: 4,
      name: 'Analytics',
      url: '/analytics',
      icon: 'fa fa-bar-chart',
      className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItem' },
      allowedService: 'Analytics',
    },
    // {
    //   id: 2,
    //   name: 'Pending jobs',
    //   url: '',
    //   icon: 'fa fa-hourglass',
    //   className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItemCurrent', wi: 'SGBF-open-62415144ea425400121c2fc7' },
    // }
  ]
};
