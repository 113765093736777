export const getKeyValue = (serviceName) => {
  let result = '';
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  if (userData != null) {
    userData.endUserDetails.AllowedServices.map((data) => {
      if (data.value.includes(serviceName)) {
        result = data.value.indexOf(serviceName) > -1 ? data.key : '';
      }
      return data;
    });
  }
  return result;
};
export default getKeyValue;
