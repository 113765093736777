import React, { Component } from 'react';
import HeaderCom from '@components/common/Header';
import HeaderUN from '@components/common/HeaderUN';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ALLOWED_SERVICES } from '@constants';
import ComponentCheckPermission from '@helpers/ComponentCheckPermission';
import {
  getSubscriptionDetail
} from '@reducers/addProcess';
import { bindActionCreators } from 'redux';
import {
  getProgramList
} from '@reducers/folder';
import { getCurrentFolder } from '@helpers/Utility';

let intervalKey;
/*
**** State Description ****
* error: true/false based on it message will be shown
* message:  Message to show once any action is perfomed
*/
class Header extends Component {
  components = {
    HeaderApp: HeaderCom,
    HeaderUN
  };

  constructor(props) {
    super(props);
    this.state = {
      isSideNavOpen: false,
      searchValue: '',
      marketMessage: 'Spring Sale: 65% off Pay as You Go Plans with coupon code SPRING2023',
    };
  }

  componentDidMount() {
    console.log('did mount header');
    this.callRecursively();
  }

  componentWillUnmount() {
    clearInterval(intervalKey);
  }

  callRecursively = () => {
    this.props.getSubscriptionDetail();
    intervalKey = setInterval(() => {
      this.props.getSubscriptionDetail();
    }, 25000);
  }

  // componentDidUpdate() {
  //   console.log('componde did update header');
  //   const paymentInProcess = JSON.parse(window.localStorage.getItem('paymentInProcess'));
  //   const madeIntervalCall = JSON.parse(window.localStorage.getItem('madeIntervalCall'));
  //   console.log('payment in process', paymentInProcess);
  //   if (paymentInProcess !== null && paymentInProcess) {
  //     console.log('if condition payment process');
  //     if (madeIntervalCall === null || madeIntervalCall === false) {
  //       window.localStorage.setItem('madeIntervalCall', true);
  //       this.callgetSubscriptionDetails();
  //     }
  //   }
  // }

  // callgetSubscriptionDetails = () => {
  //   intervalKey = setInterval(() => {
  //     const paymentInProcess = JSON.parse(window.localStorage.getItem('paymentInProcess'));
  //     this.props.getSubscriptionDetail();
  //     if (!paymentInProcess) {
  //       clearInterval(intervalKey);
  //       window.localStorage.setItem('madeIntervalCall', false);
  //     }
  //   }, 3000);
  // }

  toggleSideNav = () => {
    console.log('side nav called');
    window.scrollTo(0, 0);
    this.setState((prevState) => {
      return { isSideNavOpen: !prevState.isSideNavOpen };
    });
  };

  getLastVisitedFolderId = () => {
    const visitedFolders = getCurrentFolder();
    const lastVisitedFolder = visitedFolders[visitedFolders.length - 1];
    return lastVisitedFolder.value;
  }

  getListOfPrograms = () => {
    const folderId = this.getLastVisitedFolderId();
    const pageNo = Number(window.localStorage.getItem('pageNo'));
    const searchValue = window.localStorage.getItem('searchValue');
    const sortDirection = window.localStorage.getItem('sortDirectionValue');
    const sortKey = window.localStorage.getItem('sortkey');
    const itemsPerPage = window.localStorage.getItem('itemsPerPage');
    const workspaceId = window.localStorage.getItem('workspaceId');
    this.props.getProgramList(itemsPerPage, pageNo, sortKey, sortDirection, folderId, searchValue, workspaceId);
  }

  handleSerach = (value) => {
    const searchingValue = value.replace(/[^\w\s]/gi, '');
    window.localStorage.setItem('pageNo', 0);
    window.localStorage.setItem('searchValue', searchingValue);
    this.setState(
      {
        searchValue: searchingValue
      },
      () => {
        if (this.state.timeOut) clearTimeout(this.state.timeOut);
        this.setState({
          timeOut: setTimeout(() => {
            this.getListOfPrograms();
          }, 500)
        });
      }
    );
  }

  render() {
    const navigate = (navData) => {
      this.props.history.push(navData);
    };
    const path = window.location.pathname;
    const endUser = JSON.parse(window.localStorage.getItem('userData'));
    let component = this.components.HeaderApp;
    let HeaderComponent = component;
    if (endUser !== null) {
      component = ComponentCheckPermission(ALLOWED_SERVICES.HEADER_COMPONENT);
      HeaderComponent = this.components[`${component}`];
    }
    return (
      <>
        {window.localStorage.getItem('userData') && JSON.parse(window.localStorage.getItem('userData')).endUserDetails.id && !path.includes('/core') && !path.includes('/translation') && !path.includes('/logout') && !path.includes('/livetranslate') && !path.includes('/session/ended') && !path.includes('/session/watch') && !path.includes('/session/broadcast') && (
          <HeaderComponent
            navigate={navigate}
            audioName={this.props.audioName}
            toggleSideNav={this.toggleSideNav}
            isSideNavOpen={this.state.isSideNavOpen}
            isSideMenuActive={this.props.isSideMenuActive}
            searchValue={this.state.searchValue}
            handleSerach={this.handleSerach}
            remainingTime={this.props.remainingTime}
            mixtureName={this.props.mixtureName}
            fetchLoading={this.props.fetchLoading}
            marketMessage={this.state.marketMessage}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  audioName: state.processDetails.audioName,
  isSideMenuActive: state.addProcess.isSideMenuActive,
  remainingTime: state.addProcess.availableTime,
  mixtureName: state.processDetails.mixtureName,
  fetchLoading: state.processDetails.fetchLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getProgramList,
  getSubscriptionDetail
}, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
