export default {
  // This is ENGLISH language specific constant file
  // commons
  KANARI: 'Kanari',
  Dashboard: 'Dashboard',
  SOMETHING_WENT_WRONG: 'Something went wrong!',
  // Organization Structure Page
  Signin: 'Sign in',
  FreeAccess: 'Free access to our dashboard.',
  LoginErrorMsg: "Your username and password didn't match. Please try again.",
  LoginDataEmpty: 'Please enter the username and password',
  LoginInactiveUserErrorMsg: 'Failed to login as email is not verified.',
  VERIFY_LINK_RESEND: 'Resend verification email',
  VERIFY_LINK_SENT_TEXT: ' Verification link sent to your email',
  Username: 'Username',
  Password: 'Password',
  Forgotpassword: 'Forgotten Password?',
  Noaccount: "Don't have an account yet?",
  Signup: 'Sign up',
  RESET_PASSWORD: 'Reset your password',
  EMAIL_VALIDATION1: 'Email is invalid',
  EMAIL_VALIDATION2: 'Email is required',
  RESET_PASSWORD_MSG: 'Enter your email address below and we will send a reset link.',
  EMAIL_ADDRESS: 'Email*',
  EMAIL_PLACEHOLDER: 'Enter your email address',
  RESET_PASSWORD_SUCCESS_MSG: 'Password reset email has been sent',
  RESET_PASSWORD_LINK_MSG: '',
  LOGIN: 'Return to your login',
  EMAIL_ERROR: 'Entered email is not registered with any user',
  SUBMIT: 'Send Email',
  ERROR: 'Password reset failed. please try again',
  PASSWORD_RESET_CONFIRM: 'Confirm Reset Password',
  PASSWORDS: 'Please enter your new password',
  NEW_PASSWORD: 'Enter your new password',
  PASSWORD_INSTRUCTION1: 'Your password can\'t be too similar to your other personal information',
  PASSWORD_INSTRUCTION2: 'Your password must contain atleast 8 characters, atleast one special character, atleast one number, atleast one uppercase letter and  atleast one lowercase letter',
  PASSWORD_INSTRUCTION3: 'Your password can\'t be a commonly used password',
  PASSWORD_INSTRUCTION4: 'Your password can\'t be entirely numeric',
  PASSWORD_INSTRUCTION5: 'Your password can\'t be the same as your previous password',
  PASSWORD_LABEL: 'New Password',
  CONFIRM_PASSWORD_LABEL: 'Confirm New Password',
  CONFIRM_PASSWORD: 'Enter your Confirm Password',
  PASSWORD_VALIDATIONS: 'New Password is Required',
  CONFIRM_PASSWORD_VALIDATIONS: 'Please confirm the New Password',
  RESET: 'Reset Password',
  PASSWORD_VALIDATION: 'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character',
  NewPassword: 'New password',
  ConfirmPassword: 'Confirm new password',
  Update: 'Update',
  EmailAddress: 'Email Address',
  FirstName: 'First name',
  LastName: 'Last name',
  Overview: 'OVERVIEW',
  Settings: 'Settings',
  CurrentPasswordMsg: 'Enter current password',
  NewPasswordMsg: 'Enter new password',
  ConfirmPasswordMsg: 'Enter confirm password',
  IncorrectPassword: 'Incorrect password',
  PasswordLengthMsg: 'Password is too short - should be 8 chars minimum.',
  PasswordMatch: 'Passwords must match',
  PASSWORDTEST2: 'New Password cannot be same as old password',

  // register
  SIGN_UP: 'Sign up',
  FREE_ACCESS: 'Free access to our dashboard.',
  USERNAME: 'Username',
  EMAIL: 'Email address',
  PASSWORD: 'Password',
  REGISTRATION_SUCCESS: 'Registration successful!',
  CONFIRM_EMAIL: 'Please confirm your email address to complete the registration.',
  EMAIL_SENT: 'An email has been sent to your email address. Please check the spam folder if you have not recieved the mail.',
  RETURN_TO_LOGIN: 'Return to your login',
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  INVALID_CAPTCHA: 'Invalid captcha',
  INSERT_CAPTCHA: 'Insert captcha',
  USERNAME_EXIST: 'A user with that username already exists.',
  EMAIL_EXIST: 'A user with this email already exists.',
  VERIFY_FAILED: 'ACTIVATION INVALID!',
  VERIFY_FAILED_MESSAGE: 'Activation link is invalid! Please try to register again or contact customer support.',
  USER_ALREADY_REGISTERED: 'User has already registered, please login with the same credentials',
  VERIFY_SUCCESS: 'ACTIVATION SUCESSFUL!',
  VERIFY_SUCCESS_MESSAGE: 'Thank you for your email confirmation. Now you can login your account.',
  ENTER_USERNAME: 'Enter your username',
  ENTER_EMAIL: 'name@address.com',
  ENTER_PASSWORD: 'Enter your password',
  RE_ENTER_PASSWORD: 'Re-enter your password',
  PASSWORD_MATCH: 'The two password fields did not match.',
  REQUIRED: 'Required',
  INVALID_EMAIL: 'Invalid email format',
  PASSWORD_REGEX: 'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character',
  INVALID_NAME: 'Invalid name format',
  USERNAME_VALIDATION: 'Username is required',
  EMAIL_ADDRESS_VALIDATION: 'Email address is required',
  CAPTCHA_VALIDATION: 'Captcha is required',
  PASS_VALIDATION: 'Password is required',
  PASSWORD_VERIFY_FAILED: 'RESET PASSWORD LINK INVALID!',
  PASSWORD_VERIFY_FAILED_MESSAGE: 'Reset password link expired! Please try again.',
  REMEMBER_YOU_PASSWORD: 'Back to ',

  // Dashboard
  PROGRAM: 'Programs',
  PROCESS: 'Process',
  ADD_NEW: 'Add new',
  LOGOUT: 'Logout',
  SETTING: 'Settings',

  // Dashboard
  PROCESSING_PROGRAMS: 'PROCESSING PROGRAMS',
  TOTAL_NO_PROGRAMS: 'Total No. Programs',
  TOTAL_HOURS: 'Total hours',
  TOTAL_SPEECH_HOURS: 'TOTAL SPEECH HOURS',
  WEEKLY_USEAGE_NO_PROGRAMS_PROCESSED_PER_DAY: 'Weekly Usage (No. of programs processes per day)',
  PROCESS_LOGS: 'Process Logs',
  TASKS: 'TASKS',
  LANGUAGE: 'Language',
  TOTAL_DURATIONS: 'TOTAL DURATION (S)',
  SPEECH_DURATIONS: 'SPEECH DURATION (S)',
  STATUS: 'Status',
  SUBMITTED_DATE: 'SUBMITTED DATE',
  ADD_PROGRAM: 'Add program',
  VIEW_ALL_PROGRAM: 'View All Programs',

  // addProcess
  Kanariupload: 'KANARI > Upload',
  SubmitNewVideo: 'Submit new video for processing',
  Uploadfromcomputer: 'Upload from Computer',
  URLUpload: 'URL Upload',
  Name: '1. Name',
  DesicriptiveContext: 'Descriptive name for your video. Will take upload name as default if left blank.',
  UploadVideo: 'Upload from desktop',
  UploadsupportFiles: 'Upload video file from file system. For a list of supported file formats see the',
  ffmpegdocumentation: 'ffmpeg documentation',
  Warning: 'Warning',
  Maximumfilesize: 'Maximum file size: ',
  filesize: '5 GB',
  Maximumlength: 'Max file duration: ',
  hours: '2 hours',
  LanguageSelection: 'Select language spoken in file',
  Pleaseselect: 'Please select language of your video.',
  StartProcess: 'Start Process',
  Youaudio: 'You can specify a name for your audio or video here',
  VideoURL: 'Paste video URL',
  Pleaseinput: 'Please input your youtube or mp4 video link here',
  urlPlaceholder: 'Paste your Youtube link here',
  FILE_LARGE: 'File size is more than 5 GB',
  MIN_LENGTH_NAME: 'Name has to have at least 3 characters',
  MAX_LENGTH_NAME: 'Name has to have less than 20 characters',
  UNSUPPORTED_FILE_FORMATES: 'Unsupported file format',

  // myProfile
  PASSWORD_REQUIREMENTS: 'Password requirements',
  TO_CREATE: 'To create a new password, ',
  FOLLOWING_REQUIREMENTS: 'you have to meet all of the following requirements:',
  MINIMUM_CHARACTER: 'Minimum 8 characters',
  AT_LEAST_SPECIAL: 'At least one special character',
  AT_LEAST_NUMBER: 'At least one number',
  SAME_AS_OLD_PASSWORD: 'Can’t be the same as it\'s previous password',
  EMAIL_UPDATE: 'This contact will be used to send you updates about the program\'s process.',
  PREV_PASSWORD_MATCH: 'Can’t be the same as a previous password',
  ENTER_PREV_PASSWORD: 'Current password correctly did not match',
  UPDATE: 'Update',

  // intermediateScreen
  WHAT_TO_DO: 'What do you want to do',
  TRANSCRIBE: 'Transcribe',
  TRANSLATE: 'Translate',
  FREE_ACCESS_SERVICE: 'Free access to our services.',

  // Speech Aligner and Text Editor
  ASC: 'Asc',
  DESC: 'Desc',
  SORT_ORDER: 'Sort order',
  FILES: 'Files',
  ERROR_MSG_PROCESS_FAILED: 'There was a problem processing your file. Please try again. If it fails again, please contact ',
  SPEECH_ALIGNER_TEXT_EDITOR: 'Speech Aligner and Text Editor',
  ALPHA: 'Alpha',

  // program form
  TOTAL_DURATION: 'Total duration',
  SPEECH_DURATION: 'Speech duration',
  // translation url
  TRANSLATION_URL: 'https://app.kanari.ai/core/translation/',
  TOOLTIP_COLOR: '#e4e9f1 !important',
  LOG_OUT: 'Log Out',
  CUSTOMER_SUPPORT: 'Customer Support',

  // programs
  ALL_PROCESS: 'ALL PROCESS LOG',
  ARABIC: 'Arabic',
  EGYPT: 'Egyptian Arabic',
  ENGLISH: 'English (Provisional)',
  SUBMITTED: 'Submitted',
  PENDING_PROCESS: 'Pending process',
  STARTED: 'Started',
  PROCESSING: 'Processing',
  PROCESS_COMPLETED: 'Completed',
  PROCESS_FAILED: 'Process failed',
  STATUS_SUBMITTED: 1,
  STATUS_PROCESSING: 2,
  STATUS_PROCESS_COMPLETED: 3,
  STATUS_PROCESS_FAILED: 4,
  STATUS_SUBMITTED_STRING: '1',
  STATUS_PROCESSING_STRING: '2',
  STATUS_PROCESS_COMPLETED_STRING: '3',
  STATUS_PROCESS_FAILED_STRING: '4',
  PENDING: 'Pending',
  DENIED: 'Denied',
  ALL: 'All',
  BADGE: 'badge-',
  // file constants
  WAV_EXT: 'WAV',
  WAV_FILE_NAME: 'WAV Audio',
  CTM_EXT: 'CTM',
  CTM_FILE_NAME: 'Edited CTM',
  SRT_EXT: 'SRT',
  SRT_FILE_NAME: 'Edited SRT',
  XML_EXT: 'XML',
  XML_FILE_NAME: 'Edited XML',
  VTT_EXT: 'VTT',
  VTT_FILE_NAME: 'Edited VTT',
  TXT_EXT: 'TXT',
  MP3_EXT: 'MP3',
  TXT_FILE_NAME: 'Edited TXT',

  SUBTITLES_IN: 'Subtitles in',
  DOWNLOAD: 'Download',

  // sort orders
  ASCENDINGORDER: 1,
  DESCENDINGORDER: 2,

  FILEEXTENSIONLISTWITHNAME: [
    {
      fileExt: 'WAV',
      fileName: 'WAV Audio',
    },
    {
      fileExt: 'CTM',
      fileName: 'Edited CTM'
    },
    {
      fileExt: 'SRT',
      fileName: 'Edited SRT'
    },
    {
      fileExt: 'XML',
      fileName: 'Edited XML'
    },
    {
      fileExt: 'VTT',
      fileName: 'Edited VTT',
    },
    {
      fileExt: 'TXT',
      fileName: 'Edited TXT'
    },
    {
      fileExt: '.',
      fileName: 'Subtitles in'
    }],

  FILES_STARTED: 'Files successfully started processing!',
  CHECK_BACK_LATER: 'We are currently processing your files. You will be notified via email once the process is completed',

  FILES_BELOW: 'Your processed files will be presented below:',
  FILES_RECEIVED: 'Your file has been received and we will soon start processing it. You will be notified via email once the process is completed.',
  FILES_PROCESSED_SUCCESS: 'Files successfully processed!',
  PROCESSED_FILES_BELOW: 'You can see your processed file below:',
  SUBMITTED_DATE_TEXT: '',
  SAVE: 'Save',
  AUDIO: 'Audio',
  FILES_QUEUED_FOR_PROCESS: 'Files queued for process',
  TRANSALATION: 'Translation',
  SOURCE: 'Source',
  DOMAIN: 'Domain',
  TRANSLATE_TEXT: 'Translate text',
  TRANSLATION_SYSTEM: 'Translation System',
  KANARI_MACHINE_TRANSLATION: 'Kanari Machine Translation',
  EMPTY_INPUT: 'Empty Input',
  DESCRIPTION_REQUIRED: 'Name is Required',
  FILE_REQUIRED: 'File is Required',
  LANGUAGE_REQUIRED: 'Language is Required',
  TYPE_REQUIRED: 'Type is Required',
  URL_REQUIRED: 'URL is Required',
  UNSUPPORTED_URL: 'Provided url is not supported',
  TYPE: 'Type',
  PLEASE_SELECT_THE_MODE: 'Please select the mode',
  MAX_LENGTH: 'Must be 20 characters or less',
  MAX_LENGTH1: 'Must be 40 characters or less',
  MIN_LENGTH: 'Must be at least 3 characters',
  REMAINING_SUBSCRIPTION_DURATION: 'Remaining subscription duration after processing will be',
  VEDIO_LENGTH_IS_MORE_THAN_SUBSCIBED_TIME: 'File length is more than available subscribed time limit',
  REQUIRED_FIELD: '*',
  PACK_NAME: 'PACK_NAME',
  PACK_DESCRIPTION: 'PACK_DESCRIPTION',
  AVAILABLE_TIME: 'AVAILABLE_TIME',
  REQUESTED_TIME: 'REQUESTED_TIME',
  LICENSE_DETAILS: 'User Subscription Details',
  USER_NOT_SUBSCRIBED_TO_ANY_LICENSE: 'No Active Subscription',

  SEG: 'SEG',
  TRANSLATING: 'Translating....',

  NO_SPKR: 'Enter number of speakers',
  SPKR_CONETEXT: 'Enter no of speakers.',
  SPKR_REQUIRED: 'No of Speaker required',
  INVALID_NUMBER: 'Enter only numbers',
  SUPPORT: 'Support',
  HAPPY_SCRIBE_DASHBOARD: 'DASHBOARD',
  UPLOAD_NEW_FILE: 'UPLOAD NEW FILE',
  REMAINING_CREDIT: 'Remaining Credit',
  BUY_MORE_HOURS: 'Buy More Hours',
  WORKSPACE: 'Workspace',
  CREATE_WORKSPACE: 'Create Workspace',
  KANARI_AI: 'Kanari AI',
  EXPORT_SELECTED: 'Export Selected',
  DELETE_SELECTED: 'Delete Selected',
  DUPLICATED_SELECTED: 'Duplicate Selected',
  RENAME_SELECTED: 'Rename Selected',
  UPLOAD_DATE: 'Upload Date',
  ORIGINAL_FORMAT: 'Original Format',
  FILE_NAME: 'File Name',
  ITEMS_PER_PAGE: 'Items Per Page',
  NEW_FOLDER: 'New Folder',
  CREATE_FOLDER: 'Create Folder',
  CANCEL: 'Cancel',
  MOVE: 'Move',
  EXPORT: 'Export',
  DELETE: 'Delete',
  DELETE_MESSAGE: 'Are you sure you want to delete the file/folder?',
  DELETE_TITLE: 'Delete Item',
  MODAL_CANCEL: 'Cancel',
  FOLDER: 'Folder',
  VIDEO: 'Video',
  LENGTH: 'Length',
  PAGE: 'Page',
  ENTER_FOLDER_NAME: 'Enter folder name',
  FOLDER_NAME: 'Folder name',
  NO_RESULTS_FOUND: 'No results found',
  DELETE_MESSAGE_GLOBAL: 'Are you sure you want to delete the file/folder?',
  CHOOSE_FORMAT: 'Choose the format',
  WHAT_TO_EXPORT: 'What would you like to export?',
  OPEN: 'Open',
  MOVE_SELECTED: 'Move Selected',
  FILE: 'file',
  ITEM: 'file/folder',
  ENTER_CHANGED_NAME: 'Enter changed file/folder name',
  EDIT_NAME: 'Edit name',
  RENAME_FILE: 'Rename',
  SHARE: 'SHARE',
  DOWNLOAD_CAPS: 'DOWNLOAD',
  CONVERT_TO_SUBTITLES: 'Convert to Subtitles',
  SAVING: 'Saving..',
  STARTING_TIMECODE: 'Starting Timecode',
  ALIGN_AUDIO: 'Align Audio and Text',
  TEXT_TIMECODE: 'Enter the timecode at the very start of the original file',
  TEXT_ALIGN: 'After you have edited your file, the words may no longer align with your audio or video file. By realigning your file, we will adjust the time-code of each word.',
  TEXT_AUDIO: 'In the new version generated, you will lose your highlights, comments and speaker labels.',
  SEARCH_TEXT: 'Search..',
  REMOVE_USER: 'Remove',
  REMOVE_USER_MESSAGE: 'Are you sure you want to remove the user?',
  DELETE_WORKSPACE: 'Delete',
  DELETE_WORKSPACE_MESSAGE: 'Are you sure you want to delete the workspace?',
  ADMINISTARTOR: 'Administartor',
  REMOVE: 'Remove',
  MEMBER: 'Member',
  NEW_WORKSPACE_TEXT: 'Because you are a Sumo-ling, you have access to our Workspaces feature.',
  NEW_WORKSPACE_TEXT_1: 'In workspaces, you can invite other users to collaborate on the same files. Nevertheless, it does not imply centralized billing. So everyone pays for their files.',
  NEW_WORKSPACE: 'New Workspace',
  NAME: 'Name',
  WORKSPACE_DASHBOARD: 'Workspace Dashboard',
  MANAGE_WORKSPACE: 'Manage Workspace',
  DETAILS: 'Details',
  ADD_MEMBER: 'Add Member',
  INVITE: 'Invite',
  LIST_OF_MEMBERS: 'Workspace members',
  ACCESS_LEVEL: 'Access Level',
  PENDING_INVITATIONS: 'Pending Invitations',
  DELETE_WORKSPACE_TEXT: 'Danger Zone',
  DELETE_WORKSPACE_FILES: 'Delete workspace & files',
  EMAIL_TEXT: 'Email',
  WORKSPACES: 'Workspaces',
  BACK_TO_DASHBOARD: 'Back to dashboard',
  BACK_TO: 'Back to ',
  UPDATE_TRANSLATION: 'Updating translation to latest version',
  GENERATE_TRANSLATION: 'Generating translation',
  WELCOME: 'Welcome, sign in to continue',
  NO_ACCOUNT: "Don't have an account? ",
  LOG_IN: 'Log in',
  CREATING_ACCOUNT: 'Creating your account',
  EMAIL_ADDRESS_REQUIRED: 'Email is required',
  FULL_NAME: 'Full name',
  ENTER_FULL_NAME: 'Enter full name',
  MAX_CHAR: 'Maximum 255 characters allowed',
  ADD_TO_DICTONARY: 'Add To Dictionary',
  ADD_WORD: 'Add Word',
  CANCEL_WORD: 'Cancel Word',
  SOURCE_WORD: 'Source Word',
  REPLACE_WORD: 'Replacement Word',
  ACTION: 'Action',
  SINGLE_WORD_ERR: 'Space Not Allowed',
  ADDED_UPDATED_DATE: 'Added/Updated Date',
  REMOVE_WORD: 'Are you sure you want to remove this word?',
  REMOVE_WORDS: 'Are you sure you want to remove these words?',
  GET_LINK: 'Get Link',
  ACTIVATE_SHARING: 'Activate Sharing',
  ALLOW_EDITING: 'Allow Editing',
  COPY: 'Copy',
  SELECT_ITEMS_INCLUDE: ' 2. Select items to include',
  SELECT_FORMAT: '1. Select format',
  TRANSCRIPT: 'transcript',
  // subdomain
  STAGEUN_VALUE: 'stageun',
  SHARE_LC: 'Share',
  VERSION: 'Version',
  CONVERT_TO_SUBTITLES_CAPS: 'CONVERT TO SUBTITLES',
  TRANSLATE_CAPS: 'TRANSLATE',
  AMP_VALUE: 'amp',
  SHARE_SMALL: 'Share',
  SHARING_OPTIONS: 'Sharing Options',
  SUBMIT_TRANSCRIBE: 'Submit and Transcribe',
  ENTER_EMAIL_UN: 'Users with kanari.ai or un.org or undp.org domain can only register',
  ENTER_EMAIL_UN_ONLY: 'Please use your un.org or undp.org email to register',
  INVALID_EMAIL_UN: 'Users only with @un.org or @undp.org domains are accepted',
  END_LIVE: 'Live transcription will stop and the transcript will be available in the ',
  END_LIVE_WITH_TRANSLATION: 'Live transcription and translation will stop and the transcript will be available in the ',
  WOULD_LIKE: 'Would you like to proceed?',
  FILE_UPLOADED: 'This file is being uploaded.',
  EOS: '<eos>',

  // ApiAccess
  FirstLastName: 'First & Last Name',
  API_ACCESS: 'API ACCESS',
  Get_new_API_Key: 'Get a new API Key',
  GET_API_KEY: 'GET API KEY',
  EMAIL_ID: 'Email',

  GO_TO_SUMMARY: 'Go to “Summary Transcripts”',
  TRANSCRIPT_SAVED: 'Transcript saved in “Summary Transcripts” folder',
  TRANSCRIPT_DETAILS: 'You can access your summary transcript in the "Summary Transcripts" folder on your dashboard.',
  MORE_SUPPORT: 'Supported formats are wav, flac, mp3, mp4, ogg, mp2, m4a, mpg',
  WHATS_NEW: 'What\'s New',
  FEATURE_REQUEST: 'Feature Request',
  DOMAIN_NOT_SUPPORTED: 'The entered email domain is not supported',
  FILE_LENGTH_ERROR: 'Total duration of the files selected should not exceed 2 hrs',
};
