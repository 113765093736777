import React, { useState } from 'react';
import cn from 'classnames';
import * as availableLanguages from '@localization';
// import logo from '@assets/img/illustrations/sideMenuLogo.png';
// import logoText from '@assets/img/illustrations/kanariText.png';
import logo from '@assets/img/illustrations/Fenek_AI_Large.png';
import topLogoAmp from '@assets/img/illustrations/wrablerText.png';
import topSideMenuSmallLogo from '@assets/img/illustrations/topSideMenuSmallLogo.png';
// import Card from '@components/common/Card';
import {
  UncontrolledCollapse
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import {
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import {
  SIDE_MENU_CONFIG, ALLOWED_SERVICES, ADMIN_MENU_CONFIG, FEATURE_MENU_CONFIG
} from '@constants';
import ModalComponent from '@components/common/Modal';
import checkPermission from '@helpers/CheckPermission';
import ComponentCheckPermission from '@helpers/ComponentCheckPermission';
import { getSubdomain } from '@helpers/Utility';
// import Pattern from '@assets/img/illustrations/side.png';
import styles from './index.module.scss';

const CONSTANTS = availableLanguages.EN;

const HappyScribeSideMenuCom = (props) => {
  // let { remainingTime } = props;
  const {
    workspaceList,
    onWorkspaceClick,
    onToggleModalSeeAllTeams,
    toggleModalSeeAllTeams,
    onWorkspaceListSearch,
    sidebarList,
    redirectionOnClick,
    // handleManageWorkspace,
    isSideMenuActive,
  } = props;
  const subDomain = getSubdomain();
  const [isSideMenuSmall, setSideMenu] = useState(false);
  if (window.location.pathname.includes('/livetranslate') || window.location.pathname.includes('/session/ended') || window.location.pathname.includes('/session/watch') || window.location.pathname.includes('/session/broadcast') || window.location.pathname.includes('/mixture/tool')) {
    const sideMenuValue = document.getElementById('sideMenuId');
    if (!isSideMenuSmall) {
      setSideMenu(true);
    }
    if (sideMenuValue && sideMenuValue.classList && sideMenuValue.classList.length > 0) {
      sideMenuValue.classList.forEach((item) => {
        sideMenuValue.classList.remove(item);
      });
      sideMenuValue.className = `navbar navbar-vertical d-none d-md-block min-vh-100 col-md-3 col-lg-1 col-xl-1 pl-0 colWidthh ${checkPermission(ALLOWED_SERVICES.ISBUTTONAPP) ? 'side-menu-background-App' : 'side-menu-background-un'}`;
    }
    const mainDiv = document.getElementById('main_div');
    if (mainDiv && mainDiv.classList && mainDiv.classList.length > 0) {
      mainDiv.classList.forEach((item) => {
        mainDiv.classList.remove(item);
      });
      mainDiv.className = 'col-md-11 col-lg-11 col-xl-11 p-0 m-0';
    }
  } else {
    if (isSideMenuSmall) {
      setSideMenu(false);
    }
    const sideMenuValue = document.getElementById('sideMenuId');
    if (sideMenuValue && sideMenuValue.classList && sideMenuValue.classList.length > 0) {
      sideMenuValue.classList.forEach((item) => {
        sideMenuValue.classList.remove(item);
      });
      sideMenuValue.className = `navbar navbar-vertical d-none d-md-block min-vh-100 col-md-3 col-lg-2 col-xl-2 pl-0 colWidth ${checkPermission(ALLOWED_SERVICES.ISBUTTONAPP) ? 'side-menu-background-App' : 'side-menu-background-un'}`;
    }

    const mainDiv = document.getElementById('main_div');
    if (mainDiv && mainDiv.classList && mainDiv.classList.length > 0) {
      mainDiv.classList.forEach((item) => {
        mainDiv.classList.remove(item);
      });
      mainDiv.className = 'col-md-9 col-lg-10 col-xl-10 p-0 m-0';
    }
  }
  console.log('is side menu active ==========>', props);
  // remainingTime = remainingTime ? remainingTime.slice(0, remainingTime.lastIndexOf(':')) : '00:00';
  const redirectToNewWorkspace = () => {
    if (window.location.pathname.includes('/process/') || window.location.pathname.includes('/translate/')) {
      props.navigate('/workspace/new');
    } else {
      props.handleRedirect('/workspace/new');
    }
  };
  const redirectToDashboard = () => {
    console.log('condition redirect');
    window.localStorage.removeItem('folderArray');
    window.localStorage.setItem('workspaceId', 1);
    if (window.location.pathname.includes('/process/') || window.location.pathname.includes('/translate/')) {
      props.navigate('/Dashboard');
    } else if (window.location.pathname.includes('/livetranslate')) {
      const prompt = JSON.parse(window.localStorage.getItem('prompt'));
      if (prompt) {
        props.navigate('/Dashboard');
      } else {
        props.navigate('/Dashboard');
      }
    } else if (window.location.pathname.includes('/session/ended') || window.location.pathname.includes('/session/watch') || window.location.pathname.includes('/session/broadcast')) {
      props.navigate('/Dashboard');
    } else {
      console.log('this condition');
      props.handleRedirect('/Dashboard');
    }
  };
  // const getCardBody = () => {
  //   return (
  //     <div>
  //       <div className="d-flex align-items-center pb-2">
  //         <div className={styles.cardTitle}>
  //           {CONSTANTS.REMAINING_CREDIT}
  //         </div>
  //         {remainingTime && (
  //           <div className={cn('mb-0', styles.creditTime)}>
  //             {remainingTime}
  //           </div>
  //         )}
  //       </div>
  //       <button type="button" className={styles.creditButton} disabled={remainingTime !== '00:00'}>
  //         {CONSTANTS.BUY_MORE_HOURS}
  //       </button>
  //     </div>
  //   );
  // };

  const redirectionNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const generateSideMenuItems = (sideMenuArray, isFirstLevel = false) => {
    let menuItemList = sideMenuArray;
    const path = window.location.pathname;
    // const workspaceId = Number(window.localStorage.getItem('workspaceId'));
    if (isFirstLevel) {
      // const url = new URL(window.location);
      const pathName = 'Dashboard';
      menuItemList = sideMenuArray[pathName];
    }
    return menuItemList && menuItemList.map((menuItem) => {
      let allowed = true;
      if (menuItem.allowedService !== '' && !checkPermission(menuItem.allowedService)) {
        allowed = false;
      }
      return (
        <>
          { allowed && (
          <li className={cn(styles.dashboardTag, menuItem.url !== '' && path.includes(menuItem.url) ? styles.highlightWorkspace : '', 'nav-item pl-4 ml-2 pb-2')}>
            <div className="row pl-3">
              {menuItem.icon && menuItem.id !== 1 && (
                <div className="col-2 pl-1">
                  <i className={cn(menuItem.icon, menuItem.className.wi, 'pl-0', menuItem.url !== '' && path.includes(menuItem.url) ? styles.highlightedIcon : styles.notHighlightedIcon)} />
                </div>
              )}
              {menuItem.id === 1 && (
                <div className="col-2 pl-0">
                  <div className={cn('pr-0', menuItem.className.wi)} onClick={() => redirectionOnClick(menuItem.url)} role="presentation">
                <>
                  {menuItem.url !== '' && path.includes(menuItem.url) ? (
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z" stroke="#1F1F1F" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9 22V12H15V22" stroke="#1F1F1F" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  ) : (
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.svgHover}>
                      <path d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z" stroke="#ECECEE" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9 22V12H15V22" stroke="#ECECEE" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  )}
                </>
                  </div>
                </div>
              )}
              <div
                id={`toggler_${menuItem.id}`}
                className={cn('cursor-pointer', styles[menuItem.className.a], menuItem.className.wi, menuItem.url !== '' && path.includes(menuItem.url) ? styles.highlightWorkspaceName : '')}
                role="presentation"
                onClick={() => (menuItem.newTab ? redirectionNewTab(menuItem.url) : redirectionOnClick(menuItem.url))}
              >
                {menuItem.name}
              </div>
              {menuItem.submenu && (
              <UncontrolledCollapse toggler={`#toggler_${menuItem.id}`}>
                <ul className="nav nav-sm flex-column">
                  {generateSideMenuItems(menuItem.submenu)}
                </ul>
              </UncontrolledCollapse>
              )}
            </div>
          </li>
          )}
        </>
      );
    });
  };

  const generateWorkspace = (workspace, endIndex) => {
    const theme = createMuiTheme({
      overrides: {
        MuiTooltip: {
          tooltip: {
            color: 'white !important',
            backgroundColor: 'black !important',
          }
        }
      }
    });
    const workspaceId = Number(window.localStorage.getItem('workspaceId'));
    const workspaceData = workspace && workspace.slice(0, endIndex).map((item) => {
      return (
        <>
          { item.id !== 1 && (
          <li className="nav-item pt-2 ml-4">
            <div className={cn('row nav-item', styles.workspaceTag, workspaceId === item.id ? styles.highlightWorkspace : 'pl-4')} role="presentation">
              <div className={styles.workspaceIcon} role="presentation" onClick={() => onWorkspaceClick(item.id, item.name)}>
                {item.name.charAt(0)}
              </div>
              {/* {item && item.name && item.name.length > 10
                ? ( */}
              <MuiThemeProvider theme={theme}>
                <Tooltip title={item.name} placement="bottom">
                  <div className={workspaceId === item.id ? styles.highlightWorkspaceName1 : styles.workspaceName} role="presentation" onClick={() => onWorkspaceClick(item.id, item.name)}>
                    {/* {item.name.length >= 10 ? `${item.name.substr(0, 7)}...` : item.name} */}
                    {item.name}
                  </div>
                </Tooltip>
              </MuiThemeProvider>
              {/* ) : (
                  <div className={workspaceId === item.id ? styles.highlightWorkspaceName : styles.workspaceName} role="presentation" onClick={() => onWorkspaceClick(item.id, item.name)}>
                    {item.name.length >= 10 ? `${item.name.substr(0, 7)}...` : item.name}
                  </div>
                )} */}
            </div>
          </li>
          )}
        </>
      );
    });
    return workspaceData;
  };

  // const seeAllItem = () => {
  //   const seeAllItems = (
  //     <li className="nav-item pt-4 pl-3">
  //       <div className={cn('row nav-item pl-4 pt-2', styles.workspaceTag)} role="presentation" onClick={() => onToggleModalSeeAllTeams()}>
  //         <div className={styles.workspaceIcon}>
  //           ...
  //         </div>
  //         <div className={styles.workspaceName}>
  //           See All Workspaces
  //         </div>
  //       </div>
  //     </li>
  //   );
  //   return seeAllItems;
  // };

  // const createWorkspaceComp = () => {
  //   const newWorkspace = (
  //     <li className="nav-item pt-4 pl-3">
  //       <div className={cn('row nav-item pl-4', styles.workspaceTag)} role="presentation" onClick={() => redirectToNewWorkspace()}>
  //         <div className={styles.workspaceIcon}>
  //           +
  //         </div>
  //         <div className={styles.workspaceName}>
  //           New Workspace
  //         </div>
  //       </div>
  //     </li>
  //   );
  //   return newWorkspace;
  // };

  const myDashboard = () => {
    const dashboard = (<>
      {(isSideMenuSmall) && (
      // <li className="nav-item pl-4 ml-3">
      //   <div className="row">
      //     <div onClick={() => redirectToDashboard()} role="presentation">
      //       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      //         <path d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z" stroke="#ECECEE" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      //         <path d="M9 22V12H15V22" stroke="#ECECEE" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      //       </svg>
      //     </div>
      //     <div id="toggler_1" className={cn('cursor-pointer pt-1', styles.workspaceName)} onClick={() => redirectToDashboard()} role="presentation">
      //     Dashboard
      //     </div>
      //   </div>
      // </li>) : (
      <li className={cn('nav-item ml-4 mb-2')}>
        <div className="row">
          <div className={cn(styles.home)} onClick={() => redirectToDashboard()} role="presentation">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 6C0 2.68629 2.68629 0 6 0H42C45.3137 0 48 2.68629 48 6V42C48 45.3137 45.3137 48 42 48H6C2.68629 48 0 45.3137 0 42V6Z" fill="#ECECEE" />
              <path d="M15 21L24 14L33 21V32C33 32.5304 32.7893 33.0391 32.4142 33.4142C32.0391 33.7893 31.5304 34 31 34H17C16.4696 34 15.9609 33.7893 15.5858 33.4142C15.2107 33.0391 15 32.5304 15 32V21Z" stroke="#1F1F1F" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M21 34V24H27V34" stroke="#1F1F1F" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        </div>
      </li>)}
    </>);
    return dashboard;
  };

  // const backToDashboard = () => {
  //   const workspaceId = Number(window.localStorage.getItem('workspaceId'));
  //   const workspaceName = CONSTANTS.BACK_TO + window.localStorage.getItem('workspaceName');
  //   let url = '/Dashboard';
  //   if (workspaceId !== 1) {
  //     url = `/workspace/${workspaceId}`;
  //   }
  //   const backTo = (
  //     <li className="nav-item pt-4 pl-4">
  //       <i className="fa fa-caret-left p-2" />
  //       <a
  //         href={url}
  //         id={`toggler_${workspaceId}`}
  //         className={styles.sideMenuItem}
  //       >
  //         {Number(window.localStorage.getItem('workspaceId')) > 1 ? workspaceName : CONSTANTS.BACK_TO_DASHBOARD}
  //       </a>
  //     </li>
  //   );
  //   return backTo;
  // };

  const generateWorkspaceListForSeeAllItem = (workspace) => {
    const workspaceData = workspace && workspace.map((item) => {
      return (
        <>
          {item.id !== 1 && (
          <li className="nav-item pt-0 pl-1 pb-1">
            <div className={cn('row nav-item pl-4', styles.workspaceTag)} role="presentation" onClick={() => onWorkspaceClick(item.id, item.name)}>
              <div className={styles.workspaceIcon}>
                {item.name.charAt(0)}
              </div>
              <div className={styles.workspaceNamePopup}>
                {item.name}
              </div>
            </div>
          </li>
          )}
        </>
      );
    });
    return workspaceData;
  };

  const themetooltip = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: 'white !important',
          backgroundColor: 'black !important',
          fontSize: '15px',
        }
      }
    }
  });

  // const adminItemList = () => {
  //   const path = window.location.pathname;
  //   console.log('pathname', path);
  //   const adminMenuList = ADMIN_MENU_CONFIG.admin.map((item) => {
  //     console.log('item', item);
  //     const liTag = (
  //       <li className={cn(styles.dashboardTag, path.includes(item.url) ? styles.highlightWorkspace : '', 'nav-item pl-4 ml-2 pb-2 mt-2')}>
  //         <div className="row pl-3">
  //           {item.icon && (
  //           <i className={cn(item.icon, styles.paddingIcon, path.includes(item.url) ? styles.highlightedColor : styles.NotHighlightedcolor)} />
  //           )}
  //           <div
  //             id={`toggler_${item.id}`}
  //             className={cn('cursor-pointer', styles[item.className.a], item.className.wi, path.includes(item.url) ? styles.highlightWorkspaceName : '')}
  //             role="presentation"
  //             onClick={() => redirectionOnClick(item.url)}
  //           >
  //             {item.name}
  //           </div>
  //         </div>
  //       </li>
  //     );
  //     return liTag;
  //   });
  //   return adminMenuList;
  // };

  const classNameSelect = (sideBarList) => {
    const length = sideBarList === undefined ? 0 : sideBarList.length;
    let className = 'overFlowControlWorkspace';
    if (length === 0) {
      className = 'overFlowControlWorkspaceEmpty';
    } else if (length === 1) {
      className = 'overFlowControlWorkspaceExtraSmall';
    } else if (length === 2) {
      className = 'overFlowControlWorkspaceSmall';
    } else if (length === 3) {
      className = 'overFlowControlWorkspaceMedium';
    }
    return className;
  };

  return (
    <nav className={isSideMenuActive ? cn('border-bottom-0', styles.sideMenuLayout, styles.topWidth) : cn('border-bottom-0', styles.sideMenuLayoutInActive, styles.topWidth)}>
      { checkPermission(ALLOWED_SERVICES.SIDE_MENU_LOGO) ? (
        <>
          {isSideMenuSmall ? (
            <img src={topSideMenuSmallLogo} className={cn('navbar-brand-img', styles.alignImageSmall)} alt="..." />
          ) : (
            <img src={topLogoAmp} className={cn('navbar-brand-img', styles.alignImage)} alt="..." />
          )}
        </>
      ) : (<> {isSideMenuSmall ? (<></>) : (<>
        <img src={logo} className={cn('navbar-brand-img', styles.alignImage)} alt="..." />
        {/* <img src={logoText} className={cn('navbar-brand-img', styles.alignImageText)} alt="..." /> */}
        </>)} </>
      )}
      <>
        <ul className="pt-4 navbar-nav">
          { !isSideMenuSmall && (
            generateSideMenuItems(ComponentCheckPermission(ALLOWED_SERVICES.ANALYTICS) === 'Analytics' ? ADMIN_MENU_CONFIG : SIDE_MENU_CONFIG, true)
          )}
          {/* { window.location.href && (window.location.href.includes('/addnewprocess') || window.location.href.includes('/process') || window.location.href.includes('/translate')) && (
            <>
              {backToDashboard()}
            </>
          )} */}
          { window.location.href && !window.location.href.includes('/Dashboard') && (
            <>
              {myDashboard()}
            </>
          )}
          {/* { !isSideMenuSmall && ComponentCheckPermission(ALLOWED_SERVICES.ANALYTICS) === 'Analytics' && (
          <div>
            {adminItemList()}
          </div>
          )} */}
          <>
            <li
              className={cn('nav-item pt-2', styles.workspacesHead)}
            >
              {isSideMenuSmall ? (<><div className={cn(styles.hrlinee)} /> </>) : <div className={styles.hrline} /> }
            </li>
            {!(isSideMenuSmall) ? (<>
              <li className={cn('nav-item pt-2', styles.workspacesHead)}>
                <div>
                  <span className="pr-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="2.8" y="2.8" width="13.4" height="13.4" rx="1.2" stroke="white" strokeWidth="1.6" />
                      <rect x="8.8" y="8.8" width="12.4" height="12.4" rx="1.2" stroke="white" strokeWidth="1.6" />
                    </svg>
                  </span>
                  <span className={styles.workspacesStyle}>
                    {CONSTANTS.WORKSPACES}
                  </span>
                  <MuiThemeProvider theme={themetooltip}>
                    <Tooltip title="New workspace" placement="bottom">
                      <span className={styles.createNewIcon} role="presentation" onClick={() => redirectToNewWorkspace()}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 5V19" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M5 12H19" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                    </Tooltip>
                  </MuiThemeProvider>
                </div>
              </li>
              <div className={subDomain === 'amp' || subDomain === 'untest' ? styles.overFlowControlWorkspaceAmp : styles[classNameSelect(sidebarList)]}>
                {generateWorkspace(sidebarList, sidebarList === undefined ? 0 : sidebarList.length)}
              </div>
              {checkPermission(ALLOWED_SERVICES.ISBUTTONAPP) && (
                <li
                  className={cn('nav-item pt-2 mb-2', styles.workspacesHead)}
                >
                  {isSideMenuSmall ? (<><div className={cn(styles.hrlinee)} /> </>) : <div className={styles.hrline} /> }
                </li>
              )}
              {generateSideMenuItems(FEATURE_MENU_CONFIG, true)}
              {/* {seeAllItem()} */}
              {/* {createWorkspaceComp()} */}</>) : (<>
                { !checkPermission(ALLOWED_SERVICES.ISBUTTONAPP) && (
                <li className={cn('nav-item pt-2 ml-0 mt-2', styles.workspacesHead)}>
                  <div className={styles.worksp}>
                    <span className="pr-2 ml-4">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="2.8" y="2.8" width="13.4" height="13.4" rx="1.2" stroke="white" strokeWidth="1.6" />
                        <rect x="8.8" y="8.8" width="12.4" height="12.4" rx="1.2" stroke="white" strokeWidth="1.6" />
                      </svg>
                    </span>
                  </div>
                </li>
                )}
            </>)}
          </>
        </ul>

        <ModalComponent
          type="popup"
          dialogClassName="align-items-center"
          isOpen={toggleModalSeeAllTeams}
          toggle={onToggleModalSeeAllTeams}
          size="md"
          customHeaderClass="fs-24 clr-black font-bold p-0"
          modalCloseClass="fs-20"
          modalTitleClass="w-10 text-center"
          contentClassName="border-radius-12 vh-50"
        >
          <div>
            <div className="row pb-2">
              <div className="col-12">
                <input type="text" placeholder={CONSTANTS.SEARCH_TEXT} className={styles.searchStyle} onChange={e => onWorkspaceListSearch(e.target.value)} />
              </div>
            </div>
            <div className={cn('container', styles.overFlowControl)}>
              <div className="row mt-1 d-flex">
                <div className="col-lg-12 col-12 text-left text-dark">
                  <ul className="navbar-nav">
                    {generateWorkspaceListForSeeAllItem(workspaceList)}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ModalComponent>
      </>
    </nav>
  );
};

export default HappyScribeSideMenuCom;
