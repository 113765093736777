import React from 'react';
import cn from 'classnames';
import Styles from './index.module.css';

const Card = (props) => {
  const {
    cardHeader, cardBody, cardStyle, cardHeaderStyles, cardBodyStyles
  } = props;
  return (
    <div className={cn('card', cardStyle && Styles.cardBox)}>
      {cardHeader && (
        <div className={cn('card-header', cardHeaderStyles)}>
          {cardHeader}
        </div>
      )}
      {cardBody && (
        <div className={cn('card-body', cardBodyStyles)}>
          {cardBody}
        </div>
      )}
    </div>
  );
};
export default Card;
