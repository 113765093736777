import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { updateRoute } from '@reducers/ui';
import { getSubdomain } from '@helpers/Utility';
// import topLogo from '@assets/img/illustrations/client_logo.png';

class ScrollToTop extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (window.localStorage.getItem('userData') === 'undefined' || typeof window.localStorage.getItem('userData') === 'undefined') {
      window.localStorage.removeItem('userData');
    }
  }

  componentDidMount() {
    const url = window.location.search;
    const params = new URLSearchParams(url);
    const token = (params.get('token')) ? params.get('token') : '';
    if (window.location.href.indexOf('/authenticate') !== -1 && window.localStorage.getItem('userData')) {
      window.localStorage.clear();
      this.props.history.push(`${window.location.pathname}?token=${token}`);
    }

    const subDomain = getSubdomain();
    if (subDomain === 'amp' || subDomain === 'untest') {
      document.title = 'Warbler';
    } else {
      document.title = 'Fenek AI';
    }
    window.addEventListener('orientationchange', () => {
      this.forceUpdate();
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      localStorage.removeItem('countTraslation');
    }
  }

  getSecondaryUrl = (pathname) => {
    const metakey = pathname.split('/');
    if (metakey.length > 2) {
      metakey.splice(metakey.length - 1, 1);
    }
    metakey.push('**');
    return metakey.join('/');
  }

  render() {
    return this.props.children;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateRoute
  }, dispatch);
}

export default withRouter(connect(mapDispatchToProps)(ScrollToTop));
