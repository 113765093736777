export const PROGRAMS_COUNT_REQUESTED = 'dashboard/PROGRAMS_COUNT_REQUESTED';
export const PROGRAMS_COUNT_SUCCESS = 'dashboard/PROGRAMS_COUNT_SUCCESS';
export const PROGRAMS_COUNT_FAILURE = 'dashboard/PROGRAMS_COUNT_FAILURE';

export const WEEKLY_USEAGE_REQUESTED = 'dashboard/WEEKLY_USEAGE_REQUESTED';
export const WEEKLY_USEAGE_SUCCESS = 'dashboard/WEEKLY_USEAGE_SUCCESS';
export const WEEKLY_USEAGE_FAILURE = 'dashboard/WEEKLY_USEAGE_FAILURE';

export const PAGE_NO_ARRAY = 'dashboard/PAGE_NO_ARRAY';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  totalProgram: 0,
  progressCount: 0,
  totalHours: 0,
  totalSpeechHours: 0,
  pageNoArray: [0]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROGRAMS_COUNT_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case PROGRAMS_COUNT_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        totalProgram: action.result.total_program,
        progressCount: action.result.progress_count,
        totalHours: action.result.total_hours,
        totalSpeechHours: action.result.total_speech_hours,
      };
    }
    case PROGRAMS_COUNT_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case WEEKLY_USEAGE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case WEEKLY_USEAGE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        victoryData: action.result.programs
      };
    }
    case WEEKLY_USEAGE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case PAGE_NO_ARRAY: {
      return {
        ...state,
        pageNoArray: action.payload,
      };
    }
    default:
      return state;
  }
};

export const getDashboardCount = () => {
  return {
    types: [PROGRAMS_COUNT_REQUESTED, PROGRAMS_COUNT_SUCCESS, PROGRAMS_COUNT_FAILURE],
    promise: client => client.post('getDashboardCount')
  };
};

export const getWeeklyUsage = () => {
  return {
    types: [WEEKLY_USEAGE_REQUESTED, WEEKLY_USEAGE_SUCCESS, WEEKLY_USEAGE_FAILURE],
    promise: client => client.post('getWeeklyUsage')
  };
};

export const updatePageNoArray = (payload) => {
  return {
    type: PAGE_NO_ARRAY,
    payload
  };
};
