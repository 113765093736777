export const componentCheckPermission = (serviceName) => {
  let result = '';
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  userData.endUserDetails.AllowedServices.map((data) => {
    if (data.key.includes(serviceName)) {
      result = data.value;
    }
    return data;
  });
  // console.log('userData.', userData.endUserDetails.AllowedServices[`${serviceName}`]);
  return result;
};
export default componentCheckPermission;
