import React from 'react';
import avatarOne from '@assets/img/avatars/profiles/avatar-1.jpg';
import logo from '@assets/img/illustrations/kanari_logo_v2.png';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Collapse
} from 'reactstrap';
import cn from 'classnames';
import HappyScribeSideMenu from '@containers/common/HappyScribeSideMenu';
import * as availableLanguages from '@localization';
import styles from './index.module.css';

const CONSTANTS = availableLanguages.EN;

const HeaderCom = (props) => {
  const generateProfileDropdown = () => {
    const userData = window.localStorage.getItem('userData');
    const { name, email } = JSON.parse(userData).endUserDetails;
    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="a">
          <img
            alt="..."
            className={cn(styles.avatar, 'rounded-circle')}
            src={avatarOne}
          />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>
            {name}
          </DropdownItem>
          <DropdownItem header>
            {email}
          </DropdownItem>
          <DropdownItem divider />
          {/* <DropdownItem disabled>
            My Transcript
          </DropdownItem>
          <DropdownItem disabled>
            Top-up Plan
          </DropdownItem>
          <DropdownItem disabled>
            Vocabulary
          </DropdownItem> */}
          <DropdownItem className="pb-0">
            <a href="/addtodictonary" className="nav-link pl-0">
              Add to Dictionary
            </a>
          </DropdownItem>
          <DropdownItem>
            <a href="/apiAccess" className={props.isSideMenuActive ? 'nav-link pl-0' : 'nav-link disabled pl-0'}>
            Api Access
            </a>
          </DropdownItem>
          <DropdownItem className="pb-0">
            <a href="mailto:support@kanari.ai" className="nav-link pl-0">
            Support
            </a>
          </DropdownItem>
          <DropdownItem>
            <a href="/users/settings" className={props.isSideMenuActive ? 'nav-link pl-0' : 'nav-link disabled pl-0'}>
            Settings
            </a>
          </DropdownItem>
          <DropdownItem className="pt-1">
            <a href="/logout" className="nav-link pl-0 pt-0">
              {CONSTANTS.LOGOUT}
            </a>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };
  return (
    <div className="col-md-12">
      <nav
        className={cn(
          'navbar navbar-expand fixed-top',
          styles.backGroundColor
        )}
        id="sidebar"
      >
        <div className="container-fluid">
          <button type="button" onClick={props.toggleSideNav} className={cn('d-inline d-md-none', styles.sideMenuButton)}>
            <span className={props.isSideNavOpen ? 'fa fa-close' : 'fa fa-bars'} />
          </button>
          <img src={logo} className="navbar-brand-img" alt="..." />
          <div>
            <ul className="navbar-nav align-items-center mr-auto">
              {/* <li className="nav-item" id="support">
                <a
                  href="mailto:qats@qf.org.qa"
                  className={cn('nav-link disabled', styles.navFont)}
                  id="support"
                >
                  {CONSTANTS.SUPPORT}
                </a>
              </li>
              <li className="nav-item">
                <a href="/logout" className={cn('nav-link', styles.navFont)}>
                  {CONSTANTS.LOGOUT}
                </a>
              </li> */}
              <li>
                {generateProfileDropdown()}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <Collapse isOpen={props.isSideNavOpen}>
          <HappyScribeSideMenu />
        </Collapse>
      </div>
    </div>
  );
};

export default HeaderCom;
