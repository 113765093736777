import APP_CONFIG from './appConfig';

const baseMeta = {
  charset: 'utf-8',
  keywords: 'Kanari',
};

export default {
  '/login': {
    title: {
      EN: 'Login',
    },
    description: 'Welcome to Kanari',
    canonical: `${APP_CONFIG.BASE_URL}/login`,
    meta: {
      charset: baseMeta.charset,
      name: {
        keywords: baseMeta.keywords.concat('Login'),
      },
    },
  },
  '/dashboard': {
    title: {
      EN: 'Dashboard'
    },
    description: 'Application Dashboard',
    canonical: `${APP_CONFIG.BASE_URL}/dashboard`,
    meta: {
      charset: baseMeta.charset,
      name: {
        keywords: baseMeta.keywords.concat('Dasboard, Brief details, At a glance'),
      },
    },
  },
};
