const currentReducer = 'ui';

export const SET_HEADER_STYLE = `${currentReducer}/SET_HEADER_STYLE`;
export const UPDATE_ROUTE = `${currentReducer}/UPDATE_ROUTE`;

const initialState = {
  headerStyle: window.scrollY > 100 ? 'compact' : 'full',
  router: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HEADER_STYLE: {
      return {
        ...state,
        headerStyle: window.scrollY > 100 ? 'compact' : 'full'
      };
    }
    case UPDATE_ROUTE: {
      return {
        ...state,
        router: action.payload.pathname
      };
    }
    default:
      return state;
  }
};

export const setHeaderStyle = () => {
  return {
    type: SET_HEADER_STYLE
  };
};
export const updateRoute = (loc) => {
  return {
    type: UPDATE_ROUTE,
    payload: loc
  };
};
