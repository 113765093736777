import React, { Fragment } from 'react';
import FooterComponent from '@components/common/Modal/Footer';
import {
  Modal, ModalHeader, ModalBody
} from 'reactstrap';
import cn from 'classnames';
import styles from './modal.module.css';

const ModalComponent = (props) => {
  const {
    type,
    title,
    subTitle,
    toggle,
    isOpen,
    message,
    submitButtonName = 'OK',
    onCancel,
    onSubmit = props.toggle,
    backdrop,
    wrapClassName,
    modalClassName,
    backdropClassName,
    contentClassName,
    dialogClassName,
    isOk = false,
    zIndex,
    size,
    customHeaderClass,
    modalTitleClass,
    modalCloseClass,
    customBodyClassName
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={cn('modal-header', 'border-0', dialogClassName)}
      backdrop={backdrop}
      wrapClassName={wrapClassName}
      modalClassName={modalClassName}
      backdropClassName={backdropClassName}
      contentClassName={contentClassName}
      centered
      zIndex={zIndex || 1150}
      size={size}
    >
      <ModalHeader toggle={toggle} cssModule={{ 'modal-title': modalTitleClass || '' }} className={cn(styles.modalClose, modalCloseClass, 'border-bottom-0 pb-0 pt-2')} tag="div">
        {title && (
          <h3 className={cn(customHeaderClass || 'fs-14 font-weight-bold')}>
            {title}
          </h3>
        )}
        {subTitle && (
          <h5>
            {subTitle}
          </h5>
        )}
      </ModalHeader>
      {/* <ModalHeader toggle={toggle} className={cn(styles.modalClose, modalCloseClass, 'border-bottom-0')} /> */}
      <ModalBody className={customBodyClassName}>
        {type === 'confirmation' && (
          <Fragment>
            {message}
          </Fragment>
        )}
        {type === 'popup' && (
          <Fragment>
            {props.children}
          </Fragment>
        )}

      </ModalBody>
      {type === 'confirmation' && (
        <FooterComponent
          onSubmit={onSubmit}
          onCancel={onCancel}
          submitButtonName={submitButtonName}
          selectedLanguage={props.selectedLanguage}
        />
      )}
      {type === 'popup' && isOk && (
        <FooterComponent
          onSubmit={onSubmit}
          submitButtonName={submitButtonName}
          selectedLanguage={props.selectedLanguage}
        />
      )}
    </Modal>
  );
};

export default ModalComponent;
