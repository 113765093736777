export const WORKSPACE_CREATE_REQUESTED = 'workspace/WORKSPACE_CREATE_REQUESTED';
export const WORKSPACE_CREATE_SUCCESS = 'workspace/WORKSPACE_CREATE_SUCCESS';
export const WORKSPACE_CREATE_FAILURE = 'workspace/WORKSPACE_CREATE_FAILURE';

export const WORKSPACE_INVITE_REQUESTED = 'workspace/WORKSPACE_INVITE_REQUESTED';
export const WORKSPACE_INVITE_SUCCESS = 'workspace/WORKSPACE_INVITE_SUCCESS';
export const WORKSPACE_INVITE_FAILURE = 'workspace/WORKSPACE_INVITE_FAILURE';

export const WORKSPACE_LIST_REQUESTED = 'workspace/WORKSPACE_LIST_REQUESTED';
export const WORKSPACE_LIST_SUCCESS = 'workspace/WORKSPACE_LIST_SUCCESS';
export const WORKSPACE_LIST_FAILURE = 'workspace/WORKSPACE_LIST_FAILURE';

export const WORKSPACE_DETAILS_REQUESTED = 'workspace/WORKSPACE_DETAILS_REQUESTED';
export const WORKSPACE_DETAILS_SUCCESS = 'workspace/WORKSPACE_DETAILS_SUCCESS';
export const WORKSPACE_DETAILS_FAILURE = 'workspace/WORKSPACE_DETAILS_FAILURE';

export const WORKSPACE_DELETE_REQUESTED = 'workspace/WORKSPACE_DELETE_REQUESTED';
export const WORKSPACE_DELETE_SUCCESS = 'workspace/WORKSPACE_DELETE_SUCCESS';
export const WORKSPACE_DELETE_FAILURE = 'workspace/WORKSPACE_DELETE_FAILURE';

export const WORKSPACE_USER_DELETE_REQUESTED = 'workspace/WORKSPACE_USER_DELETE_REQUESTED';
export const WORKSPACE_USER_DELETE_SUCCESS = 'workspace/WORKSPACE_USER_DELETE_SUCCESS';
export const WORKSPACE_USER_DELETE_FAILURE = 'workspace/WORKSPACE_USER_DELETE_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  workspaceName: '',
  workspaceMember: [],
  workspaceId: '',
  workspaceList: [],
  workspaceDeleteLoading: false,
  errorMessageInvite: '',
  workspaceLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WORKSPACE_CREATE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        workspaceLoading: true,
      };
    }
    case WORKSPACE_CREATE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        workspaceId: action.result.workspaceId,
        workspaceLoading: false,
      };
    }
    case WORKSPACE_CREATE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        workspaceLoading: false
      };
    }
    case WORKSPACE_INVITE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case WORKSPACE_INVITE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case WORKSPACE_INVITE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        errorMessageInvite: action.error.errorMessage,
      };
    }
    case WORKSPACE_LIST_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case WORKSPACE_LIST_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        workspaceList: action.result.workspaceList
      };
    }
    case WORKSPACE_LIST_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case WORKSPACE_DETAILS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case WORKSPACE_DETAILS_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        workspaceName: action.result.usersListwithWorkspace.workspaceName,
        workspaceMember: action.result.usersListwithWorkspace.usersList,
      };
    }
    case WORKSPACE_DETAILS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case WORKSPACE_DELETE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        workspaceDeleteLoading: true,
      };
    }
    case WORKSPACE_DELETE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        workspaceDeleteLoading: false,
      };
    }
    case WORKSPACE_DELETE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        workspaceDeleteLoading: false,
      };
    }
    case WORKSPACE_USER_DELETE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case WORKSPACE_USER_DELETE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case WORKSPACE_USER_DELETE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const createWorkspace = (data) => {
  return {
    types: [WORKSPACE_CREATE_REQUESTED, WORKSPACE_CREATE_SUCCESS, WORKSPACE_CREATE_FAILURE],
    promise: client => client.post('addWorkspace', { data }),
  };
};

export const inviteMember = (id, data) => {
  return {
    types: [WORKSPACE_INVITE_REQUESTED, WORKSPACE_INVITE_SUCCESS, WORKSPACE_INVITE_FAILURE],
    promise: client => client.post(`inviteUsers?workspaceId=${id}`, { data }),
  };
};

export const getWorkspaceList = (searchKey, data) => {
  return {
    types: [WORKSPACE_LIST_REQUESTED, WORKSPACE_LIST_SUCCESS, WORKSPACE_LIST_FAILURE],
    promise: client => client.post(`workspaceLists?searchKey=${searchKey}`, { data }),
  };
};

export const getWorkspaceDetails = (workspaceId, searchKey) => {
  return {
    types: [WORKSPACE_DETAILS_REQUESTED, WORKSPACE_DETAILS_SUCCESS, WORKSPACE_DETAILS_FAILURE],
    promise: client => client.get(`workspaceDetails?workspaceId=${workspaceId}&searchKey=${searchKey}`),
  };
};

export const deleteWorkspaceDetails = (workspaceId) => {
  return {
    types: [WORKSPACE_DELETE_REQUESTED, WORKSPACE_DELETE_SUCCESS, WORKSPACE_DELETE_FAILURE],
    promise: client => client.get(`deleteWorkspace?workspaceId=${workspaceId}`),
  };
};

export const removeUserFromWorkspace = (data) => {
  return {
    types: [WORKSPACE_USER_DELETE_REQUESTED, WORKSPACE_USER_DELETE_SUCCESS, WORKSPACE_USER_DELETE_FAILURE],
    promise: client => client.post('removeUser', { data }),
  };
};
