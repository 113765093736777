/* eslint-disable no-console */
export const LOGIN_REQUESTED = 'login/LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'login/LOGIN_FAILURE';

export const AZURE_USER_LOGIN_REQUESTED = 'login/AZURE_USER_LOGIN_REQUESTED';
export const AZURE_USER_LOGIN_SUCCESS = 'login/AZURE_USER_LOGIN_SUCCESS';
export const AZURE_USER_LOGIN_FAILURE = 'login/AZURE_USER_LOGIN_FAILURE';

export const VERIFY_EMAIL_REQUESTED = 'register/VERIFY_EMAIL_REQUESTED';
export const VERIFY_EMAIL_SUCCESS = 'register/VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'register/VERIFY_EMAIL_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  data: [],
  uname: '',
  loginLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        loginLoading: true,
      };
    }
    case LOGIN_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        data: action.result,
        loginLoading: false,
      };
    }
    case LOGIN_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        errorDesc: action.error,
        loginLoading: false,
      };
    }
    case AZURE_USER_LOGIN_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case AZURE_USER_LOGIN_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        data: action.result
      };
    }
    case AZURE_USER_LOGIN_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case VERIFY_EMAIL_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        isVerified: false
      };
    }
    case VERIFY_EMAIL_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        isVerified: true
      };
    }
    case VERIFY_EMAIL_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        isVerified: false,
        errorDesc: action.error,
      };
    }
    default:
      return state;
  }
};

export const loginCall = (data) => {
  return {
    types: [LOGIN_REQUESTED, LOGIN_SUCCESS, LOGIN_FAILURE],
    promise: client => client.post('loginapi', {
      data
    })
  };
};

export const getUserDetailsForAzureUsers = () => {
  return {
    types: [LOGIN_REQUESTED, LOGIN_SUCCESS, LOGIN_FAILURE],
    promise: client => client.get('getUserDetailsForAzureUsers')
  };
};
export const resendVerifyEmail = (data) => {
  console.log(data, 'reducer');
  return {
    types: [VERIFY_EMAIL_REQUESTED, VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL_FAILURE],
    promise: client => client.post('resendVerifyMail', {
      data
    })
  };
};
