import React from 'react';

const AppBody = (props) => {
  return (
    <div>
      {props.children}
    </div>
  );
};


export default AppBody;
