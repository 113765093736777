import React, { Component } from 'react';
import HappyScribeSideMenuCom from '@components/common/HappyScribeSideMenu';
import SideMenuUNCom from '@components/common/SideMenuUN';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  getSubscriptionDetail
} from '@reducers/addProcess';
import { getWorkspaceList } from '@reducers/workspace';
import { updatePageNoArray } from '@reducers/Dashboard';
import { APP_CONFIG, ALLOWED_SERVICES } from '@constants';
import ComponentCheckPermission from '@helpers/ComponentCheckPermission';
import { getSubdomain } from '@helpers/Utility';

/*
**** State Description ****
* error: true/false based on it message will be shown
* message:  Message to show once any action is perfomed
*/
class HappyScribeSideMenu extends Component {
  components = {
    SideMenuApp: HappyScribeSideMenuCom,
    SideMenuUN: SideMenuUNCom
  };

  constructor(props) {
    super(props);
    this.state = {
      toggleModalSeeAllTeams: false,
      workspaceList: [],
      sidebarList: [],
      timeOut: 0,
    };
  }

  componentDidMount() {
    this.props.getSubscriptionDetail();
    this.getWorkspaceList();
    // this.props.getWorkspaceList('', [])
    //   .then(() => {
    //     const workspaceId = Number(window.localStorage.getItem('workspaceId'));
    //     let sideList = this.props.workspaceList;
    //     if (workspaceId > 1 && sideList.length > 3) {
    //       let workspceIdIndex = -1;
    //       sideList.forEach((item, index) => {
    //         if (workspaceId === item.id) {
    //           workspceIdIndex = index;
    //         }
    //       });
    //       if (workspceIdIndex > 2) {
    //         const reFormattedArray = [];
    //         const subDomain = getSubdomain();
    //         if (subDomain.includes('stageun') || subDomain.includes('amp')) {
    //           reFormattedArray[0] = sideList[workspceIdIndex];
    //           sideList.forEach((item) => {
    //             if (workspaceId !== item.id) {
    //               reFormattedArray.push(item);
    //             }
    //           });
    //           sideList = reFormattedArray;
    //         } else {
    //           // reFormattedArray[0] = sideList[workspceIdIndex];
    //           // reFormattedArray[1] = sideList[1];
    //           // sideList = reFormattedArray;
    //           reFormattedArray[0] = sideList[workspceIdIndex];
    //           sideList.forEach((item) => {
    //             if (workspaceId !== item.id) {
    //               reFormattedArray.push(item);
    //             }
    //           });
    //           sideList = reFormattedArray;
    //         }
    //       }
    //     }
    //     this.setState({ workspaceList: this.props.workspaceList, sidebarList: this.props.workspaceList });
    //   });
  }

  componentDidUpdate(prevProps) {
    console.log('component dis update');
    if (this.props.location !== prevProps.location) {
      this.getWorkspaceList();
    }
  }

  getWorkspaceList = () => {
    this.props.getWorkspaceList('', [])
      .then(() => {
        const workspaceId = Number(window.localStorage.getItem('workspaceId'));
        let sideList = this.props.workspaceList && this.props.workspaceList[1] && this.props.workspaceList[1].children;
        if (workspaceId > 1 && sideList.length > 3) {
          let workspceIdIndex = -1;
          sideList.forEach((item, index) => {
            if (workspaceId === item.id) {
              workspceIdIndex = index;
            }
          });
          if (workspceIdIndex > 2) {
            const reFormattedArray = [];
            const subDomain = getSubdomain();
            if (subDomain.includes('stageun') || subDomain.includes('amp')) {
              reFormattedArray[0] = sideList[workspceIdIndex];
              sideList.forEach((item) => {
                if (workspaceId !== item.id) {
                  reFormattedArray.push(item);
                }
              });
              sideList = reFormattedArray;
            } else {
              // reFormattedArray[0] = sideList[workspceIdIndex];
              // reFormattedArray[1] = sideList[1];
              // sideList = reFormattedArray;
              reFormattedArray[0] = sideList[workspceIdIndex];
              sideList.forEach((item) => {
                if (workspaceId !== item.id) {
                  reFormattedArray.push(item);
                }
              });
              sideList = reFormattedArray;
            }
          }
        }
        this.setState({ workspaceList: this.props.workspaceList && this.props.workspaceList[1] && this.props.workspaceList[1].children, sidebarList: this.props.workspaceList && this.props.workspaceList[1] && this.props.workspaceList[1].children });
      });
  }

  onWorkspaceClick = (id, name) => {
    this.props.updatePageNoArray([0]);
    window.localStorage.removeItem('folderArray');
    window.localStorage.setItem('workspaceId', id);
    window.localStorage.setItem('workspaceName', name);
    if (window.location.pathname.includes('/process/') || window.location.pathname.includes('/translate/')) {
      this.props.history.push(`/workspace/${id}`);
    } else {
      this.props.history.push(`/workspace/${id}`);
    }
  }

  onToggleModalSeeAllTeams = () => {
    const toggleState = this.state.toggleModalSeeAllTeams;
    if (!toggleState) {
      this.props.getWorkspaceList('', [])
        .then(() => {
          this.setState({ workspaceList: this.props.workspaceList });
        });
    }
    const finalState = !toggleState;
    this.setState({ toggleModalSeeAllTeams: finalState });
  }

  onWorkspaceListSearch = (value) => {
    if (this.state.timeOut) clearTimeout(this.state.timeOut);
    this.setState({
      timeOut: setTimeout(() => {
        this.props.getWorkspaceList(value, [])
          .then(() => {
            this.setState({ workspaceList: this.props.workspaceList });
          });
      }, 500)
    });
  }

  redirectionOnClick = (url) => {
    console.log('click');
    if (url.includes('/Dashboard')) {
      console.log('window.lo', window.location);
      if (window.location.pathname.includes('/Dashboard')) {
        window.localStorage.setItem('callApi', true);
      }
      this.props.updatePageNoArray([0]);
      window.localStorage.removeItem('folderArray');
    }
    if (url !== '') {
      this.props.history.push(url.replace(APP_CONFIG.BASE_URL, ''));
    }
  }

  handleManageWorkspace = (id, name) => {
    console.log('clicl', id, name);
    window.localStorage.setItem('workspaceId', id);
    window.localStorage.setItem('workspaceName', name);
    this.props.history.push(`/workspace/edit/${id}`);
  }

  handleRedirect = (path) => {
    this.props.history.push(path);
  }

  handleDashboardRedirect = () => {
    this.props.updatePageNoArray([0]);
    this.props.history.push('/Dashboard');
  }

  render() {
    const navigate = (navData) => {
      this.props.history.push(navData);
    };
    const path = window.location.pathname;
    const endUser = JSON.parse(window.localStorage.getItem('userData'));
    let component = this.components.HeaderApp;
    let SideMenuComponent = component;
    if (endUser !== null) {
      component = ComponentCheckPermission(ALLOWED_SERVICES.SIDEMENU_COMPONENT);
      SideMenuComponent = this.components[`${component}`];
    }
    return (
      <>
        {window.localStorage.getItem('userData') && JSON.parse(window.localStorage.getItem('userData')).endUserDetails.id && !path.includes('/core') && !path.includes('/translation') && (
          <SideMenuComponent
            navigate={navigate}
            remainingTime={this.props.remainingTime}
            workspaceList={this.state.workspaceList}
            onWorkspaceClick={this.onWorkspaceClick}
            toggleModalSeeAllTeams={this.state.toggleModalSeeAllTeams}
            onToggleModalSeeAllTeams={this.onToggleModalSeeAllTeams}
            onWorkspaceListSearch={this.onWorkspaceListSearch}
            sidebarList={this.state.sidebarList}
            redirectionOnClick={this.redirectionOnClick}
            handleManageWorkspace={this.handleManageWorkspace}
            isSideMenuActive={this.props.isSideMenuActive}
            handleRedirect={this.handleRedirect}
            handleDashboardRedirect={this.handleDashboardRedirect}
          />
        )}
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    remainingTime: state.addProcess.availableTime,
    workspaceList: state.workspace.workspaceList,
    isSideMenuActive: state.addProcess.isSideMenuActive,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getSubscriptionDetail,
  getWorkspaceList,
  updatePageNoArray,
}, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HappyScribeSideMenu)
);
