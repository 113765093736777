import includes from 'lodash/includes';

const TITLE = '';
export const LOCALHOST_HOSTNAME_LIST = ['localshost'];

const DOMAIN_BASED_HOST_URL = {
  hostname: 'https://app.kanari.ai/KanariAccount',
  hostname2: 'https://app.kanari.ai/KanariProcess',
  hostname3: 'https://app.kanari.ai/LicenseService'
  // hostname: 'http://localhost:8081/KanariAccount',
  // hostname2: 'http://localhost:8082/KanariProcess',
  // hostname3: 'http://localhost:8085/LicenseService'
  // hostname: 'https://stageapp.kanari.ai:8443/KanariAccount',
  // hostname2: 'https://stageapp.kanari.ai:8444/KanariProcess',
  // hostname3: 'https://stageapp.kanari.ai:8447/LicenseService'
};
const HOST_TYPE_LOCALHOST = 'localhost';

const SUB = 1;
const SHOW_LANG = 1;
// default language for all will be english and no lang picker will be shown for the domain if no other language option is available in the domain
const LANG_OPTION = {
  localhost: ['EN'],
};

// const httpProt = window.location.protocol;

export const checkVisibilityInDomain = domain => includes(domain, window.location.hostname);

// eslint-disable-next-line new-cap
export const socket = new window.io.connect('https://arabicasr.kanari.ai:8000');


function getDataForHostedDomain() {
  let itemsPerPage;
  let hostType;
  const sub = 0;
  const showLang = 1;
  if (includes(LOCALHOST_HOSTNAME_LIST, window.location.hostname)) {
    itemsPerPage = 1;
    hostType = HOST_TYPE_LOCALHOST;
  }

  return {
    itemsPerPage,
    hostType,
    sub,
    showLang,
  };
}

export default {
  CURRENT_HOST_NAME: DOMAIN_BASED_HOST_URL.hostname,
  CURRENT_HOST_NAME_PROCESS: DOMAIN_BASED_HOST_URL.hostname2,
  CURRENT_HOST_NAME_LICENSE: DOMAIN_BASED_HOST_URL.hostname3,
  LOGIN: `${TITLE} Login`,
  SIGNUP: `${TITLE} Sign up`,
  RESET_PASSWORD: `${TITLE} Reset password`,
  BASE_URL: process.env.PUBLIC_URL,
  PAGE_SIZE: 5,
  ITEM_PER_PAGE_5: 5,
  ITEM_PER_PAGE_10: 10,
  ORDER_ASC: 'asc',
  ORDER_DESC: 'desc',
  ITEMS_PER_PAGE: [5, 10, 20, 30, 40, 50],
  CATALOG_COURSES_PER_PAGE: getDataForHostedDomain().itemsPerPage,
  HOST_TYPE: getDataForHostedDomain().hostType,
  TEACHER_TRAINING_SUB: getDataForHostedDomain().sub,
  SHOW_LANG_SUB: getDataForHostedDomain().showLang,
  SHOW_LANG,
  SUB,
  LANG_OPTION,
  INTERVAL_TIME: 600000,
  TRANSLATOR_URL: 'https://mt.qcri.org/api/addSubUser',
  TRANSLATE_URL: 'https://mt.qcri.org/api/v1/translate',
  TRANSLATOR_KEY: '7eb66cd0361abc431a715ba920eeb3e5',
  YOUTUBE_TITLE_API: 'https://noembed.com/embed?dataType=json&url=',
  DEFAULT_TRANSLATOR_KEY: '005091e1d21d69a672a5af21cdbaa262',
  MAIL_TO: 'mailto:support@fenek.ai',
  SORT_DIRECTION: 'sortDirection',
  AZURE_LANDING_PAGE_URL: `${process.env.PUBLIC_URL}/kanariweb/azurelogin`,
  // SORT_DIRECTION: 'sortDirection',
  PLAY_BACK_SPEED_OPTIONS: [
    { label: '0.5X', value: 0.5 },
    { label: '1.0X', value: 1.0 },
    { label: '1.5X', value: 1.5 },
    { label: '2.0X', value: 2.0 },
  ],
  OPTION: [
    { label: 'Text document (.txt)', value: 1 },
    { label: 'PDF (.pdf)', value: 2 },
    // { label: 'SRT (.srt)', value: 3 },
    // { label: 'VTT (.vtt)', value: 4 },
    // { label: 'XML (.xml)', value: 5 },
    { label: 'Docx (.docx)', value: 6 },
  ],
  PLAYCODE: [
    { label: 'MS', value: 1000 },
    { label: '24fqs', value: 24 },
    { label: '25fqs', value: 25 },
    { label: '29.97fqs', value: 29.97 },
    { label: '30fqs', value: 30 },
    { label: '60fqs', value: 60 },
  ],
  PAY_AS_YOU_GO: 2,
  RECURRING: 3,
  CONSTANT_USER_ID: 0,
};
