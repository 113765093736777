

export const GET_ANALYTICS_REQUESTED = 'header/GET_ANALYTICS_REQUESTED';
export const GET_ANALYTICS_SUCCESS = 'header/GET_ANALYTICS_SUCCESS';
export const GET_ANALYTICS_FAILURE = 'header/GET_ANALYTICS_FAILURE';

export const GET_ANALYTICS_FOR_USAGE_REQUESTED = 'header/GET_ANALYTICS_FOR_USAGE_REQUESTED';
export const GET_ANALYTICS_FOR_USAGE_SUCCESS = 'header/GET_ANALYTICS_FOR_USAGE_SUCCESS';
export const GET_ANALYTICS_FOR_USAGE_FAILURE = 'header/GET_ANALYTICS_FOR_USAGE_FAILURE';

export const GET_ANALYTICS_FOR_USER_USAGE_REQUESTED = 'header/GET_ANALYTICS_FOR_USER_USAGE_REQUESTED';
export const GET_ANALYTICS_FOR_USER_USAGE_SUCCESS = 'header/GET_ANALYTICS_FOR_USER_USAGE_SUCCESS';
export const GET_ANALYTICS_FOR_USER_USAGE_FAILURE = 'header/GET_ANALYTICS_FOR_USER_USAGE_FAILURE';

export const GET_PENDING_COUNT_REQUESTED = 'header/GET_PENDING_COUNT_REQUESTED';
export const GET_PENDING_COUNT_SUCCESS = 'header/GET_PENDING_COUNT_SUCCESS';
export const GET_PENDING_COUNT_FAILURE = 'header/GET_PENDING_COUNT_FAILURE';

export const GET_ANALYTICS_USER_DATA_REQUESTED = 'header/GET_ANALYTICS_USER_DATA_REQUESTED';
export const GET_ANALYTICS_USER_DATA_SUCCESS = 'header/GET_ANALYTICS_USER_DATA_SUCCESS';
export const GET_ANALYTICS_USER_DATA_FAILURE = 'header/GET_ANALYTICS_USER_DATA_FAILURE';

export const GET_GRAPH_ANALYTICS_REQUESTED = 'header/GET_GRAPH_ANALYTICS_REQUESTED';
export const GET_GRAPH_ANALYTICS_SUCCESS = 'header/GET_GRAPH_ANALYTICS_SUCCESS';
export const GET_GRAPH_ANALYTICS_FAILURE = 'header/GET_GRAPH_ANALYTICS_FAILURE';

export const GET_REGISTRATION_GRAPH_REQUESTED = 'header/GET_REGISTRATION_GRAPH_REQUESTED';
export const GET_REGISTRATION_GRAPH_SUCCESS = 'header/GET_REGISTRATION_GRAPH_SUCCESS';
export const GET_REGISTRATION_GRAPH_FAILURE = 'header/GET_REGISTRATION_GRAPH_FAILURE';

export const DOWNLOAD_USER_ACCOUNT_DATA_REQUESTED = 'header/DOWNLOAD_USER_ACCOUNT_DATA_REQUESTED';
export const DOWNLOAD_USER_ACCOUNT_DATA_SUCCESS = 'header/DOWNLOAD_USER_ACCOUNT_DATA_SUCCESS';
export const DOWNLOAD_USER_ACCOUNT_DATA_FAILURE = 'header/DOWNLOAD_USER_ACCOUNT_DATA_FAILURE';

export const DOWNLOAD_USER_USAGE_DATA_REQUESTED = 'header/DOWNLOAD_USER_USAGE_DATA_REQUESTED';
export const DOWNLOAD_USER_USAGE_DATA_SUCCESS = 'header/DOWNLOAD_USER_USAGE_DATA_SUCCESS';
export const DOWNLOAD_USER_USAGE_DATA_FAILURE = 'header/DOWNLOAD_USER_USAGE_DATA_FAILURE';

export const GET_ANALYTICS_POP_UP_USER_DETAILS_REQUESTED = 'header/GET_ANALYTICS_POP_UP_USER_DETAILS_REQUESTED';
export const GET_ANALYTICS_POP_UP_USER_DETAILS_SUCCESS = 'header/GET_ANALYTICS_POP_UP_USER_DETAILS_SUCCESS';
export const GET_ANALYTICS_POP_UP_USER_DETAILS_FAILURE = 'header/GET_ANALYTICS_POP_UP_USER_DETAILS_FAILURE';

const initialState = {
  loading: false,
  loadingTable: false,
  loaded: false,
  error: false,
  analyticsData: {},
  usageDetails: 0,
  userUsageList: [],
  totalCount: 0,
  pendingCount: 0,
  analyticsUserData: [],
  totalUserCount: 0,
  graphData: [],
  registrationGraphData: [],
  downloadUserAccountData: [],
  downloadUserUsageData: [],
  completeDetails: {},
  loadingPop: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ANALYTICS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ANALYTICS_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        analyticsData: action.result,
      };
    }
    case GET_ANALYTICS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_ANALYTICS_FOR_USAGE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ANALYTICS_FOR_USAGE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        usageDetails: action.result.analyticsReport,
      };
    }
    case GET_ANALYTICS_FOR_USAGE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_ANALYTICS_FOR_USER_USAGE_REQUESTED:
    {
      return {
        ...state,
        loadingTable: true,
        loaded: false,
      };
    }
    case GET_ANALYTICS_FOR_USER_USAGE_SUCCESS:
    {
      return {
        ...state,
        loadingTable: false,
        error: false,
        loaded: true,
        userUsageList: action.result.userList.userWithUsedTime,
        totalCount: action.result.userList.count,
      };
    }
    case GET_ANALYTICS_FOR_USER_USAGE_FAILURE:
    {
      return {
        ...state,
        loadingTable: false,
        loaded: false,
        error: true,
      };
    }
    case GET_PENDING_COUNT_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PENDING_COUNT_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        pendingCount: action.result.pending
      };
    }
    case GET_PENDING_COUNT_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_ANALYTICS_USER_DATA_REQUESTED:
    {
      return {
        ...state,
        loadingTable: true,
        loaded: false,
      };
    }
    case GET_ANALYTICS_USER_DATA_SUCCESS:
    {
      return {
        ...state,
        loadingTable: false,
        error: false,
        loaded: true,
        analyticsUserData: action.result.userAccountDetailsList,
        totalUserCount: action.result.count,
      };
    }
    case GET_ANALYTICS_USER_DATA_FAILURE:
    {
      return {
        ...state,
        loadingTable: false,
        loaded: false,
        error: true,
      };
    }
    case GET_GRAPH_ANALYTICS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_GRAPH_ANALYTICS_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        graphData: action.result,
      };
    }
    case GET_GRAPH_ANALYTICS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_REGISTRATION_GRAPH_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_REGISTRATION_GRAPH_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        registrationGraphData: action.result,
      };
    }
    case GET_REGISTRATION_GRAPH_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DOWNLOAD_USER_ACCOUNT_DATA_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DOWNLOAD_USER_ACCOUNT_DATA_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        downloadUserAccountData: action.result,
      };
    }
    case DOWNLOAD_USER_ACCOUNT_DATA_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DOWNLOAD_USER_USAGE_DATA_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DOWNLOAD_USER_USAGE_DATA_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        downloadUserUsageData: action.result,
      };
    }
    case DOWNLOAD_USER_USAGE_DATA_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_ANALYTICS_POP_UP_USER_DETAILS_REQUESTED:
    {
      return {
        ...state,
        loadingPop: true,
        loaded: false,
      };
    }
    case GET_ANALYTICS_POP_UP_USER_DETAILS_SUCCESS:
    {
      return {
        ...state,
        loadingPop: false,
        error: false,
        loaded: true,
        completeDetails: action.result.completeDetails,
        // totalUserCount: action.result.count,
      };
    }
    case GET_ANALYTICS_POP_UP_USER_DETAILS_FAILURE:
    {
      return {
        ...state,
        loadingPop: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getAnalyticsData = (data) => {
  return {
    types: [GET_ANALYTICS_REQUESTED, GET_ANALYTICS_SUCCESS, GET_ANALYTICS_FAILURE],
    promise: client => client.post('analytics', { data }),
  };
};


export const getAnalyticsDataForUsage = (data) => {
  return {
    types: [GET_ANALYTICS_FOR_USAGE_REQUESTED, GET_ANALYTICS_FOR_USAGE_SUCCESS, GET_ANALYTICS_FOR_USAGE_FAILURE],
    promise: client => client.post('analyticsUsage', { data }),
  };
};

export const getAnalyticsDataForUsageOfUser = (itemsPerPage, pageNo, searchKey, data) => {
  return {
    types: [GET_ANALYTICS_FOR_USER_USAGE_REQUESTED, GET_ANALYTICS_FOR_USER_USAGE_SUCCESS, GET_ANALYTICS_FOR_USER_USAGE_FAILURE],
    promise: client => client.post(`analyticsUsageOfUser/${itemsPerPage}/${pageNo}?searchKey=${searchKey}`, { data }),
  };
};

export const getPendingDataCount = () => {
  return {
    types: [GET_PENDING_COUNT_REQUESTED, GET_PENDING_COUNT_SUCCESS, GET_PENDING_COUNT_FAILURE],
    promise: client => client.get('analyticsPending'),
  };
};

export const getAnalyticsUserData = (pageNo, itemsPerPage, data) => {
  return {
    types: [GET_ANALYTICS_USER_DATA_REQUESTED, GET_ANALYTICS_USER_DATA_SUCCESS, GET_ANALYTICS_USER_DATA_FAILURE],
    promise: client => client.post(`usertabledata/${pageNo}/${itemsPerPage}`, { data }),
  };
};

export const getGraphAnalyticsData = (value) => {
  return {
    types: [GET_GRAPH_ANALYTICS_REQUESTED, GET_GRAPH_ANALYTICS_SUCCESS, GET_GRAPH_ANALYTICS_FAILURE],
    promise: client => client.get(`analyticsgraphdata/${value}`),
  };
};
export const getRegistrationGraphData = (value) => {
  return {
    types: [GET_REGISTRATION_GRAPH_REQUESTED, GET_REGISTRATION_GRAPH_SUCCESS, GET_REGISTRATION_GRAPH_FAILURE],
    promise: client => client.get(`analyticsRegistrationGraphData/${value}`),
  };
};

export const downloadUserAccountTabledata = (data) => {
  return {
    types: [DOWNLOAD_USER_ACCOUNT_DATA_REQUESTED, DOWNLOAD_USER_ACCOUNT_DATA_SUCCESS, DOWNLOAD_USER_ACCOUNT_DATA_FAILURE],
    promise: client => client.post('downloadUserAccountTabledata', { data }),
  };
};

export const downloadUserUsageTabledata = (searchKey, data) => {
  return {
    types: [DOWNLOAD_USER_USAGE_DATA_REQUESTED, DOWNLOAD_USER_USAGE_DATA_SUCCESS, DOWNLOAD_USER_USAGE_DATA_FAILURE],
    promise: client => client.post(`downloadUserUsageTabledata?searchKey=${searchKey}`, { data }),
  };
};

export const getPopUpUserDetails = (value) => {
  // console.log('completeDetails,', completeDetails);
  return {
    types: [GET_ANALYTICS_POP_UP_USER_DETAILS_REQUESTED, GET_ANALYTICS_POP_UP_USER_DETAILS_SUCCESS, GET_ANALYTICS_POP_UP_USER_DETAILS_FAILURE],
    promise: client => client.get(`getPopupUserDetails/${value}`),
  };
};
