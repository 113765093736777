export default {
  emailList: [
    'instaddr',
    'nekosan',
    'secretmail',
    'qq',
    '126',
    '123',
    'aliyun',
    'foxmail',
  ],
};
