export const STRIPE_PAYMENT_REQUESTED = 'stripe/STRIPE_PAYMENT_REQUESTED';
export const STRIPE_PAYMENT_SUCCESS = 'password/STRIPE_PAYMENT_SUCCESS';
export const STRIPE_PAYMENT_FAILURE = 'password/STRIPE_PAYMENT_FAILURE';

export const STRIPE_CREATE_SESSION_REQUESTED = 'stripe/STRIPE_CREATE_SESSION_REQUESTED';
export const STRIPE_CREATE_SESSION_SUCCESS = 'password/STRIPE_CREATE_SESSION_SUCCESS';
export const STRIPE_CREATE_SESSION_FAILURE = 'password/STRIPE_CREATE_SESSION_FAILURE';

export const STRIPE_CHECK_SUBSCRIPTION_REQUESTED = 'stripe/STRIPE_CHECK_SUBSCRIPTION_REQUESTED';
export const STRIPE_CHECK_SUBSCRIPTION_SUCCESS = 'password/STRIPE_CHECK_SUBSCRIPTION_SUCCESS';
export const STRIPE_CHECK_SUBSCRIPTION_FAILURE = 'password/STRIPE_CHECK_SUBSCRIPTION_FAILURE';

export const STRIPE_CANCEL_SUBSCRIPTION_REQUESTED = 'stripe/STRIPE_CANCEL_SUBSCRIPTION_REQUESTED';
export const STRIPE_CANCEL_SUBSCRIPTION_SUCCESS = 'password/STRIPE_CANCEL_SUBSCRIPTION_SUCCESS';
export const STRIPE_CANCEL_SUBSCRIPTION_FAILURE = 'password/STRIPE_CANCEL_SUBSCRIPTION_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  isPaymentSucces: false,
  paymentStatusMessageFromBackend: '',
  paymentLoading: false,
  createSessionLoading: false,
  redirectionUrl: '',
  createSessionErrorMessage: '',
  subscribed: false,
  subscribedLoading: false,
  cancelLoading: false,
  redirectionUrlPayAsYougo: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STRIPE_PAYMENT_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        isPaymentSucces: false,
        paymentLoading: true,
      };
    }
    case STRIPE_PAYMENT_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        isPaymentSucces: true,
        paymentStatusMessageFromBackend: 'Requested hours credited into your account.',
        paymentLoading: false,
        redirectionUrlPayAsYougo: action.result.sessionDetails.redirectUrl,
      };
    }
    case STRIPE_PAYMENT_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        isPaymentSucces: false,
        paymentStatusMessageFromBackend: action.error.errorMessage,
        paymentLoading: false,
      };
    }
    case STRIPE_CREATE_SESSION_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        isPaymentSucces: false,
        createSessionLoading: true,
      };
    }
    case STRIPE_CREATE_SESSION_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        isPaymentSucces: true,
        createSessionLoading: false,
        redirectionUrl: action.result.sessionDetails.redirectUrl,
      };
    }
    case STRIPE_CREATE_SESSION_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        isPaymentSucces: false,
        createSessionLoading: false,
        createSessionErrorMessage: action.error.errorMessage,
      };
    }
    case STRIPE_CHECK_SUBSCRIPTION_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        subscribedLoading: true,
      };
    }
    case STRIPE_CHECK_SUBSCRIPTION_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        subscribedLoading: false,
        subscribed: action.result.subscribed,
      };
    }
    case STRIPE_CHECK_SUBSCRIPTION_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        subscribedLoading: false,
      };
    }
    case STRIPE_CANCEL_SUBSCRIPTION_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        cancelLoading: true,
      };
    }
    case STRIPE_CANCEL_SUBSCRIPTION_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        cancelLoading: false,
      };
    }
    case STRIPE_CANCEL_SUBSCRIPTION_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        cancelLoading: false,
      };
    }
    default:
      return state;
  }
};

export const createCharge = (data) => {
  return {
    types: [STRIPE_PAYMENT_REQUESTED, STRIPE_PAYMENT_SUCCESS, STRIPE_PAYMENT_FAILURE],
    promise: client => client.post('stripeCreateCharge', { data })
  };
};

export const createSession = () => {
  return {
    types: [STRIPE_CREATE_SESSION_REQUESTED, STRIPE_CREATE_SESSION_SUCCESS, STRIPE_CREATE_SESSION_FAILURE],
    promise: client => client.post('stripeCreateSession')
  };
};

export const checkSubscription = () => {
  return {
    types: [STRIPE_CHECK_SUBSCRIPTION_REQUESTED, STRIPE_CHECK_SUBSCRIPTION_SUCCESS, STRIPE_CHECK_SUBSCRIPTION_FAILURE],
    promise: client => client.get('checkSubscription')
  };
};

export const cancelSubscription = () => {
  return {
    types: [STRIPE_CANCEL_SUBSCRIPTION_REQUESTED, STRIPE_CANCEL_SUBSCRIPTION_SUCCESS, STRIPE_CANCEL_SUBSCRIPTION_FAILURE],
    promise: client => client.get('cancelSubscription')
  };
};
