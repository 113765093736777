export const convertMillisecondsToDateAndDate = (Milliseconds) => {
  const temp = new Date(Milliseconds);
  const date = (`0${temp.getDate()}`).slice(-2);
  const year = temp.getFullYear();
  const month = (`0${temp.getMonth() + 1}`).slice(-2);
  const convertedToDate = `${date}-${month}-${year}`;
  return convertedToDate;
};

// * date is in mm/dd/yyyy format*
export const convertMilliSecondToDate = (ms) => {
  const temp = new Date(ms);
  const date = (`0${temp.getDate()}`).slice(-2);
  const year = temp.getFullYear();
  const month = (`0${temp.getMonth() + 1}`).slice(-2);
  const convertedToDate = `${month}/${date}/${year}`;
  return convertedToDate;
};

export const goBackFromCurrectDate = (days) => {
  const ourDate = new Date();
  let retrunDate = {};
  const goBackFromCurrectDateArry = [];
  const monthNameAnddateArry = [];
  // Change it so that it is 7 days in the past.
  for (let i = 1; i < days; i += 1) {
    const date = (`0${ourDate.getDate()}`).slice(-2);
    const month = (`0${ourDate.getMonth() + 1}`).slice(-2);
    const year = ourDate.getFullYear();
    goBackFromCurrectDateArry.push(`${date}-${month}-${year}`);
    const monthArray = [];
    monthArray[0] = 'Jan';
    monthArray[1] = 'Feb';
    monthArray[2] = 'Mar';
    monthArray[3] = 'Apr';
    monthArray[4] = 'May';
    monthArray[5] = 'Jun';
    monthArray[6] = 'Jul';
    monthArray[7] = 'Aug';
    monthArray[8] = 'Sept';
    monthArray[9] = 'Oct';
    monthArray[10] = 'Nov';
    monthArray[11] = 'Dec';
    const month2 = monthArray[ourDate.getMonth()];
    monthNameAnddateArry.push(`${month2} ${date}`);
    const pastDate = ourDate.getDate() - 1;
    ourDate.setDate(pastDate);
  }
  retrunDate = {
    monthNameAnddateArry,
    goBackFromCurrectDateArry
  };
  return retrunDate;
};

export const initDownaload = ({ response, fileName = 'download' }) => {
  const blob = new Blob([response], { type: 'application/octet-stream' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('id', 'downloadRJ');
  document.body.appendChild(a);
  a.style = 'display: none';
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const getUrl = ({ response }) => {
  const blob = new Blob([response], { type: 'application/octet-stream' });
  const url = window.URL.createObjectURL(blob);
  return url;
};

export const convertTimingsToJSON = (data) => {
  const lines = `${data}\n`.split('\n');

  let delay = 0;
  const output = [];
  let buffer = {
    content: ''
  };

  /**
   * `reducer` is executed by `reduce()` and goes line by line in the multi-line string, allow us to
   * parse it and do any necessary modifications.
   *
   * @property {object} [accumulator] The accumulated callback's return values.
   * @property {number} [initialLine] The current value
   * @returns {object}
   */
  const reducer = (accumulator, initialLine) => {
    // The current line, minus any white space at the start or end
    const line = initialLine.trim();

    // Line by line, we go through and find out what type of content we have
    // Check if this `line` is the starting line number
    if (line && !buffer.id) {
      buffer.id = line;

      // Check if this `line` start/end timings
    } else if (!buffer.start) {
      // Split the start/end timings string by the ' --> '
      const range = line.split(' --> ');
      // Split start times (range[0]) into the separate timing values
      let start;
      if (range.length !== 1) {
        start = {
          minutes: range[0].split(':')[1],
          seconds: range[0].split(':')[2].split(',')[0],
          milliseconds: range[0].split(':')[2].split(',')[1]
        };

        // Convert the time to a `new Date`
        // The date here doesn't really matter and won't be used, we only want the time
        const startTotal = new Date(
          `2000-01-01T00:${start.minutes}:${start.seconds}.${start.milliseconds}`
        );

        // Convert the seconds processed above to a number
        const startTotalSeconds = parseInt(
          `${start.seconds}${start.milliseconds}`,
          10
        );

        // Split end times (range[1]) into the separate timing values
        const end = {
          minutes: range[1].split(':')[1],
          seconds: range[1].split(':')[2].split(',')[0],
          milliseconds: range[1].split(':')[2].split(',')[1]
        };

        // Convert the time to a `new Date`
        // The date here doesn't really matter and won't be used, we only want the time
        const endTotal = new Date(
          `2000-01-01T00:${end.minutes}:${end.seconds}.${end.milliseconds}`
        );

        // Convert the seconds processed above to a number
        const endTotalSeconds = parseInt(`${end.seconds}${end.milliseconds}`, 10);

        // The delay amount for how long the animation needs to wait before start
        // Used as between sentences, the narrator generally will leave a natural gap inbetween, but
        // we don't want animation to begin too early
        buffer.delay = startTotalSeconds - delay;

        // Set this loops delay to the `endTotalSeconds`
        delay = endTotalSeconds;

        // Set the start time
        buffer.start = startTotal.getTime() - new Date('2000-01-01T00:00:00.00').getTime();

        // Set the duration, which is the end time minus the start time
        buffer.duration = endTotal - startTotal;

        // Set the end time
        buffer.end = startTotalSeconds + buffer.duration;
      }

      // Check if this `line` is the sentence copy
    } else if (line !== '') {
      // The sentence with a space before if not the first
      const lineToAdd = buffer.content !== '' ? ` ${line}` : line;

      // Add each line as a sentence, with a space before if not the first
      buffer.content += lineToAdd;
    } else {
      // Add all the created content to the output
      output.push(buffer);

      // Reset the buffer for the next line
      buffer = {
        content: ''
      };
    }

    // Return this lines content
    return output;
  };

  // Return all the lines
  return lines.reduce(reducer, 0);
};

export const removePlus = (str) => {
  return str ? str.replace(/\+/gi, ' ') : str;
};

// video time format hh:mm:ss
export const getVideoTime = (videoSeconds) => {
  let hours = Math.floor(videoSeconds / (60 * 60));
  const secondsLeft = videoSeconds - Math.round(hours * (60 * 60));
  let minutes = Math.floor(secondsLeft / 60);
  let seconds = Math.floor(secondsLeft % 60);
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;
  const time = `${hours}:${minutes}:${seconds}`;
  return time;
};

export const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

// video time formatted into seconds
export const getSecondsByTime = (videoLength) => {
  return +(videoLength.split(':').reduce((acc, time) => (60 * acc) + +time));
};

export const getCurrentFolder = () => {
  const folderArray = JSON.parse(window.localStorage.getItem('folderArray'));
  if (folderArray && folderArray !== '') {
    return folderArray;
  }
  const workspaceName = window.localStorage.getItem('workspaceName');
  return [Number(window.localStorage.getItem('workspaceId')) === 1 || Number(window.localStorage.getItem('workspaceId')) === 0 ? { key: `${workspaceName}`, value: 0, isFolder: 0 } : { key: `${workspaceName}`, value: 0, isFolder: 0 }];
};

export const getSubdomain = () => {
  const url = new URL(window.location);
  const res = url.origin.split('//')[1].split('.')[0];
  if (res.includes('app')) {
    return 'app';
  }
  if (res.includes('amp') || res.includes('warbler')) {
    return 'amp';
  }
  // return url.hostname.split('.')[0];
  return 'app';
};
