export const checkPermission = (serviceName) => {
  let result = false;
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  if (userData != null) {
    userData.endUserDetails.AllowedServices.map((data) => {
      if (data.value.includes(serviceName)) {
        result = data.value.indexOf(serviceName) > -1;
      }
      return data;
    });
  }
  return result;
};
export default checkPermission;
