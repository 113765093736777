import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import login from './login';
import dashboard from './Dashboard';
import Ui from './ui';
import myProfileSettings from './myProfileSettings';
import registerpage from './register';
import forgetpassword from './forgetpassword';
import resetpassword from './resetpassword';
import programs from './programs';
import addProcess from './addProcess';
import processDetails from './processDetails';
import translate from './Translate';
import saasFulfilment from './saasFulfilment';
import folder from './folder';
import workspace from './workspace';
import translatev2 from './translateV2';
import dictionary from './dictionary';
import analytics from './analytics';
import apiAccess from './apiAccess';
import stripe from './stripePayment';
import liveTranslate from './liveTranslate';

const appReducer = combineReducers({
  router: routerReducer,
  form: formReducer,
  login,
  forgetpassword,
  resetpassword,
  dashboard,
  Ui,
  programs,
  myProfileSettings,
  registerpage,
  addProcess,
  processDetails,
  translate,
  folder,
  workspace,
  saasFulfilment,
  translatev2,
  dictionary,
  analytics,
  apiAccess,
  stripe,
  liveTranslate,
});

// Setup root reducer
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
