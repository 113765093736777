/* eslint-disable */
// import superagent from 'superagent';
import { APIConfig, APP_CONFIG } from '@constants';
import IsJsonString from '@helpers/TypeCheckers';
import axios from "axios";
// import merge from 'lodash/merge';
const methods = ['get', 'post', 'put', 'patch', 'del'];
let HOSTNAME = APP_CONFIG.CURRENT_HOST_NAME;
const ENDPOINTS = APIConfig.endpoints;

// function Intercept() {
//   let callbacks = Array.prototype.slice.call(arguments);
//   return function (req) {
//     let callback = req.callback;
//     req.callback = (err, res) => {
//       callbacks.forEach(function (e) {
//         e.call(req, err, res);
//       });
//       callback.call(req, err, res);
//     };
//   };
// }

// let AuthIntercept = Intercept((err, res) => {
//   if ((res && (res.status == 401 || res.status == 10006)) || (err && err.message.includes('terminated') && !(res || {}).status)) {
  // if(window.location.pathname !== `${APP_CONFIG.BASE_URL}/`) {
  //   window.localStorage.clear();
  //   window.localStorage.setItem('showLogoutNoty', true);
  //   window.location.href = `${APP_CONFIG.BASE_URL}/`;
  // }
// });

const redirectToLogin = () => {
  if(window.location.pathname !== `${APP_CONFIG.BASE_URL}/`) {
    window.localStorage.clear();
    window.localStorage.setItem('showLogoutNoty', true);
    if (window.location.pathname.includes(`${APP_CONFIG.BASE_URL}/process`)) {
      window.location.href = window.location.pathname;
    } else {
      window.location.href = `${APP_CONFIG.BASE_URL}/`;
    }
  }
};

const responseUnauthorised = (res) => {
  if ((res && (res.status == 401 || res.status == 10006)) && !(res || {}).status) {
    // route to login
    redirectToLogin();
  }
  return res;
};

const errorUnauthorised = (err) => {
  if(err && err.response && err.response.status === 401) {
    redirectToLogin();
    return Promise.reject(err.response.message);
  }
  let decodedResponse = err.response.data;
  if(decodedResponse !== null && typeof decodedResponse === 'string') {
    decodedResponse = decodeURIComponent(err.response.data.replace(/\+/g, '%20'));
    decodedResponse = JSON.parse(decodedResponse);
    if (decodedResponse.body && decodedResponse.body.error_description.includes('Bad credentials')) {
      return Promise.reject(decodedResponse.body);
    }
    if (decodedResponse && decodedResponse.errorMessage && decodedResponse.errorMessage.includes('User has already Registered')) {
      return Promise.reject(decodedResponse);
    }
  }
  return Promise.reject(err.response.data);
};

axios.interceptors.response.use(
  (res) => responseUnauthorised(res),
  (err) => errorUnauthorised(err)
);

function formatUrl(path) {
  let apiPath = path;
  let apiPathArray = [];

  if (apiPath.indexOf('?') != -1) {
    apiPathArray = apiPath.split('?');
    apiPath = apiPathArray[0];
    apiPathArray.shift();
  }

  let mappedEndpoint = ENDPOINTS[apiPath];
  if (apiPath.indexOf('/') !== -1) {
    mappedEndpoint = '';
    let splitPathArray = apiPath.split('/');
    mappedEndpoint += ENDPOINTS[splitPathArray[0]] + '/';
    splitPathArray.shift();
    mappedEndpoint += splitPathArray.join('/');
  }
  if (
    mappedEndpoint.indexOf('/upload') !== -1 ||
    mappedEndpoint.indexOf('process') !== -1 ||
    mappedEndpoint.indexOf('dashboard') !== -1 ||
    mappedEndpoint.indexOf('/program') !== -1 ||
    mappedEndpoint.indexOf('/add/count') !== -1 ||
    mappedEndpoint.indexOf('/workspace') !== -1 ||
    mappedEndpoint.indexOf('/liveSession') !== -1 ||
    mappedEndpoint.indexOf('/analytics') !== -1 ||
    mappedEndpoint.indexOf('/file') !== -1 ||
    mappedEndpoint.indexOf('/translate') !== -1 ||
    mappedEndpoint.indexOf('/dictonary') !== -1
  ) {
    HOSTNAME = APP_CONFIG.CURRENT_HOST_NAME_PROCESS;
  }else if(mappedEndpoint.indexOf('/subscription/checksubscriptionlimit') !== -1 || mappedEndpoint.indexOf('/subscription/get/time') !== -1 || mappedEndpoint.indexOf('/stripe/') !== -1 || mappedEndpoint.indexOf('/subscription/download/report') !== -1) {
    HOSTNAME = APP_CONFIG.CURRENT_HOST_NAME_LICENSE;
  }else if(mappedEndpoint.indexOf('/subscription/getusersubscriptiondetail') !== -1) {
    HOSTNAME = APP_CONFIG.CURRENT_HOST_NAME_LICENSE;
  }else if(mappedEndpoint.indexOf('/saas/addsaasoffer') !== -1) {
    HOSTNAME = APP_CONFIG.CURRENT_HOST_NAME_LICENSE;
  } else if (mappedEndpoint.indexOf('/user/profile') !== -1 || mappedEndpoint.indexOf('getuserdetails') !== -1 || mappedEndpoint.indexOf('account/get/details') !== -1 || mappedEndpoint.indexOf('account/get/regdata') !== -1 || mappedEndpoint.indexOf('account/export/report') !== -1) {
    HOSTNAME = APP_CONFIG.CURRENT_HOST_NAME;
  }
  let adjustedPath =
    mappedEndpoint[0] !== '/'
      ? HOSTNAME + '/' + mappedEndpoint
      : HOSTNAME +
        mappedEndpoint +
        (apiPathArray.length != 0 ? `?${apiPathArray.join('')}` : '');
  if (
    adjustedPath.indexOf('accounts/login') !== -1 ||
    adjustedPath.indexOf('account/register') !== -1 ||
    adjustedPath.indexOf('account/is/email/exist') !== -1 ||
    adjustedPath.indexOf('account/is/userName/exist') !== -1 ||
    adjustedPath.indexOf('account/is/userName/Exist') !== -1 ||
    adjustedPath.indexOf('/account/verify/email') !== -1 ||
    adjustedPath.indexOf('/account/get/forgot/password/link') !== -1 ||
    adjustedPath.indexOf('/account/update/password') !== -1 ||
    adjustedPath.indexOf('/account/verify/forgot/password/link') !== -1 ||
    adjustedPath.indexOf('/account/is/password/same') !== -1 ||
    adjustedPath.indexOf('/account/get/registeration/email/link') !== -1
  ) {
    if (
      (window.localStorage.getItem('userData') === null ||
      window.localStorage.getItem('userData') === 'undefined')
      && mappedEndpoint.indexOf('getuserdetails') === -1
    ) {
      adjustedPath =
        mappedEndpoint[0] !== '/'
          ? '/kanariweb' + '/' + mappedEndpoint
          : '/kanariweb' +
            mappedEndpoint +
            (apiPathArray.length != 0 ? `?${apiPathArray.join('')}` : '');
    }
  }
  return adjustedPath;
}

export default class ApiClient {
  constructor(req) {
    methods.forEach(method => {
      this[method] = (
        path,
        { params, data, headers = {} } = {}
      ) => {

          return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json;charset=utf-8';
            headers['Content-Type'] = 'application/json';
            const accessToken = window.localStorage.getItem('access_token');
            if (accessToken && accessToken !== null) {
              headers['Authorization'] = `Bearer ${accessToken}`;
            }

            if (data) {
              if (path.indexOf('loginAuth') !== -1) {
                headers['Content-Type'] = 'application/x-www-form-urlencoded';
              } else if (path.indexOf('addProcessfile') === -1) {
                headers['Content-Type'] = 'application/json';
              }
            }

            let generatedRequestHeader = {};
            let options = { headers, params }
            if (path.indexOf('download') != -1 || path.indexOf('export') != -1) {
              options = { headers, params, responseType:'arraybuffer'}
            }
            if (method === 'post') {

              const postObj = data ? data : {};

              generatedRequestHeader = axios[method](formatUrl(path), postObj, options );
            } else{
                generatedRequestHeader = axios[method](formatUrl(path), options);
            }

            generatedRequestHeader.then(response => {
                if (path.indexOf('download') != -1 || path.indexOf('export') != -1) {
                  resolve(response);
                } else if (response && response.data != '') {
                  // resolve(response.data);
                  const decodedResponse = response.data && typeof response.data === 'string' ? decodeURIComponent(response.data.replace(/\+/g, '%20')) : '';
                  //below condition is true if the response is in url-encoded form, usually happens when it comes from node js
                  if (decodedResponse!== '' && IsJsonString(decodedResponse)) {
                    resolve(JSON.parse(decodedResponse));
                  } else {
                    resolve(response.data);
                  }
                } else {
                  resolve();
                }
              })
              .catch(error => {
                reject(error);
              });
          });
      };
    });
  }
  empty() {}
}

// export default class ApiClient {
//   constructor(req) {
//     methods.forEach(method => {
//       this[method] = (path, { params, data, headers = {}, files, fields } = {}) =>
//         new Promise((resolve, reject) => {
//           headers['Accept'] = 'application/json;charset=utf-8';
//           let request = superagent[method](formatUrl(path))
//             .withCredentials(true)
//             .use(AuthIntercept)
//             .set(headers);
//           if (params) {
//             request.query(params);
//           }

//           if (request.url && window.localStorage.getItem('access_token') && window.localStorage.getItem('access_token') !== null) {
//             headers['Authorization'] = `Bearer ${window.localStorage.getItem('access_token')}`;
//           }

//           //user login time for event
//          const userLoginTime = (path === 'logout' && window.localStorage.getItem('userLoginTime')) ? {'loginTime': window.localStorage.getItem('userLoginTime')} : '';

//           // userAdditionalInfo
//           if (request.url && window.localStorage.getItem('userData') && window.localStorage.getItem('userData') !== null && JSON.parse(window.localStorage.getItem('userData')).endUserDetails && JSON.parse(window.localStorage.getItem('userData')).endUserDetails !== null) {
//             const endUserDetails = JSON.parse(window.localStorage.getItem('userData')).endUserDetails;
//           }

//           if (headers) {
//             request.set(headers);
//           }

//           if (this.token) {
//             request.set('Authorization', `Bearer ${this.token}`);
//           }

//           if (files) {
//             request.send(files);
//             //files.forEach(file => request.attach(file.key, file.value));
//           }

//           if (fields) {
//             fields.forEach(item => request.field(item.key, item.value));
//             //  request.send(data);
//           }
//           const requrl = request.url.split('?');
//           if (requrl[0].indexOf('download') != -1) {
//             request.responseType('arraybuffer');
//           }
//           if (data) {
//             if (path.indexOf('loginAuth') !== -1) {
//               headers['Content-Type'] = 'application/x-www-form-urlencoded';
//             } else if (path.indexOf('addProcessfile') === -1) {
//               headers['Content-Type'] = 'application/json';
//             }
//             request.set(headers);
//                 request.send(data);

//           }

//           request.end((err, response = {}) => {
//             if (err) {
//               reject(response.body || err);
//             } else {
//               const requrl = request.url.split('?');
//               if (requrl[0].indexOf('download') != -1) {
//                 resolve(response);
//               } else if (response.text != '') {
//                 if (IsJsonString(decodeURIComponent(response.text.replace(/\+/g, '%20')))) {
//                   resolve(JSON.parse(decodeURIComponent(response.text.replace(/\+/g, '%20'))));
//                 }
//                 else {
//                   resolve(response.text);
//                 }
//               } else {
//                 resolve();
//               }
//             }
//           });
//         });
//     });
//   }

//   empty() { }
// }
