export default {
  Dashboard: [
    {
      id: 1,
      name: 'Dashboard',
      url: '/Dashboard',
      icon: '',
      className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItem' },
      allowedService: '',
    },
    // {
    //   id: 2,
    //   name: 'What\'s New',
    //   url: '',
    //   icon: 'fa fa-newspaper-o',
    //   className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItem', wi: 'SGBF-open-62415144ea425400121c2fc7' },
    //   allowedService: 'widgetDisplay',
    // },
    // {
    //   id: 3,
    //   name: 'Feature Request',
    //   url: '',
    //   icon: 'fa fa-bolt',
    //   className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItem', wi: 'SGBF-open-62415144ea425400121c2fc7' },
    //   allowedService: 'widgetDisplay',
    // },
    // {
    //   id: 2,
    //   name: 'Translate',
    //   url: '/translation',
    //   icon: '',
    //   className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItem' },
    // },
    // {
    //   id: 3,
    //   name: 'Transcribe',
    //   url: `/addnewprocess/uploads/${btoa(0)}/${btoa(1)}`,
    //   icon: '',
    //   className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItem' },
    // },
    // {
    //   id: 4,
    //   name: 'Workspace',
    //   url: '',
    //   icon: '',
    //   className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItem' },
    //   submenu: [
    //     {
    //       id: 5,
    //       name: 'Create Workspace',
    //       url: '/workspace/new',
    //       icon: 'fa fa-plus p-1',
    //       className: { li: 'nav-item pt-3 pl-2', a: 'sideMenuItem' },
    //     },
    //     {
    //       id: 9,
    //       name: 'Default Workspace',
    //       url: '',
    //       icon: 'p-1',
    //       className: { li: 'nav-item pt-3 pl-2', a: 'sideMenuItem' },
    //     },
    // ],
    // },
  ],
  // translate: [
  //   {
  //     id: 6,
  //     name: 'Back to Dashboard',
  //     url: '/Dashboard',
  //     icon: 'fa fa-caret-left p-2',
  //     className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItem' },
  //   },
  // ],
  // addnewprocess: [
  //   {
  //     id: 7,
  //     name: 'Back to Dashboard',
  //     url: '/Dashboard',
  //     icon: 'fa fa-caret-left p-2',
  //     className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItem' },
  //   },
  // ],
  // process: [
  //   {
  //     id: 8,
  //     name: 'Back to Dashboard',
  //     url: '/Dashboard',
  //     icon: 'fa fa-caret-left p-2',
  //     className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItem' },
  //   },
  // ],
  // workspace: [
  //   {
  //     id: 9,
  //     name: 'Back to Dashboard',
  //     url: '/Dashboard',
  //     icon: 'fa fa-caret-left p-2',
  //     className: { li: 'nav-item pt-4 pl-4', a: 'sideMenuItem' },
  //   }
  // ],
};
