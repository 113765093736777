export const GET_FILE_DETAILS_REQUESTED = 'processDetails/GET_FILE_DETAILS_REQUESTED';
export const GET_FILE_DETAILS_SUCCESS = 'processDetails/GET_FILE_DETAILS_SUCCESS';
export const GET_FILE_DETAILS_FAILURE = 'processDetails/GET_FILE_DETAILS_FAILURE';

export const DOWNLOAD_FILE_REQUESTED = 'processDetails/DOWNLOAD_FILE_REQUESTED';
export const DOWNLOAD_FILE_SUCCESS = 'processDetails/DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAILURE = 'processDetails/DOWNLOAD_FILE_FAILURE';

export const SAVE_FILE_REQUESTED = 'processDetails/SAVE_FILE_REQUESTED';
export const SAVE_FILE_SUCCESS = 'processDetails/SAVE_FILE_SUCCESS';
export const SAVE_FILE_FAILURE = 'processDetails/SAVE_FILE_FAILURE';

export const FETCH_FILE_DETAILS_REQUESTED = 'processDetails/FETCH_FILE_DETAILS_REQUESTED';
export const FETCH_FILE_DETAILS_SUCCESS = 'processDetails/FETCH_FILE_DETAILS_SUCCESS';
export const FETCH_FILE_DETAILS_FAILURE = 'processDetails/FETCH_FILE_DETAILS_FAILURE';

export const FETCH_SUBTITLE_DETAILS_REQUESTED = 'processDetails/FETCH_SUBTITLE_DETAILS_REQUESTED';
export const FETCH_SUBTITLE_DETAILS_SUCCESS = 'processDetails/FETCH_SUBTITLE_DETAILS_SUCCESS';
export const FETCH_SUBTITLE_DETAILS_FAILURE = 'processDetails/FETCH_SUBTITLE_DETAILS_FAILURE';
export const SHARE_FILE_REQUESTED = 'processDetails/SHARE_FILE_REQUESTED';
export const SHARE_FILE_SUCCESS = 'processDetails/SHARE_FILE_SUCCESS';
export const SHARE_FILE_FAILURE = 'processDetails/SHARE_FILE_FAILURE';

export const EDIT_SUBTITLE_DETAILS_REQUESTED = 'processDetails/EDIT_SUBTITLE_DETAILS_REQUESTED';
export const EDIT_SUBTITLE_DETAILS_SUCCESS = 'processDetails/EDIT_SUBTITLE_DETAILS_SUCCESS';
export const EDIT_SUBTITLE_DETAILS_FAILURE = 'processDetails/EDIT_SUBTITLE_DETAILS_FAILURE';

export const FOLDER_EXPORT_REQUESTED = 'folder/FOLDER_EXPORT_REQUESTED';
export const FOLDER_EXPORT_SUCCESS = 'folder/FOLDER_EXPORT_SUCCESS';
export const FOLDER_EXPORT_FAILURE = 'folder/FOLDER_EXPORT_FAILURE';

export const SESSION_FETCH_REQUESTED = 'folder/SESSION_FETCH_REQUESTED';
export const SESSION_FETCH_SUCCESS = 'folder/SESSION_FETCH_SUCCESS';
export const SESSION_FETCH_FAILURE = 'folder/SESSION_FETCH_FAILURE';

export const BROADCAST_SESSION_FETCH_REQUESTED = 'folder/BROADCAST_SESSION_FETCH_REQUESTED';
export const BROADCAST_SESSION_FETCH_SUCCESS = 'folder/BROADCAST_SESSION_FETCH_SUCCESS';
export const BROADCAST_SESSION_FETCH_FAILURE = 'folder/BROADCAST_SESSION_FETCH_FAILURE';

export const FETCH_AUDIO_LIST_REQUESTED = 'processDetails/FETCH_AUDIO_LIST_REQUESTED';
export const FETCH_AUDIO_LIST_SUCCESS = 'processDetails/FETCH_AUDIO_LIST_SUCCESS';
export const FETCH_AUDIO_LIST_FAILURE = 'processDetails/FETCH_AUDIO_LIST_FAILURE';

export const SAVE_MIXTURE_REQUESTED = 'processDetails/SAVE_MIXTURE_REQUESTED';
export const SAVE_MIXTURE_SUCCESS = 'processDetails/SAVE_MIXTURE_SUCCESS';
export const SAVE_MIXTURE_FAILURE = 'processDetails/SAVE_MIXTURE_FAILURE';

export const FETCH_MIXTURE_REQUESTED = 'processDetails/FETCH_MIXTURE_REQUESTED';
export const FETCH_MIXTURE_SUCCESS = 'processDetails/FETCH_MIXTURE_SUCCESS';
export const FETCH_MIXTURE_FAILURE = 'processDetails/FETCH_MIXTURE_FAILURE';

export const DOWNLOAD_MIXTURE_REQUESTED = 'processDetails/DOWNLOAD_MIXTURE_REQUESTED';
export const DOWNLOAD_MIXTURE_SUCCESS = 'processDetails/DOWNLOAD_MIXTURE_SUCCESS';
export const DOWNLOAD_MIXTURE_FAILURE = 'processDetails/DOWNLOAD_MIXTURE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  listData: [],
  file: null,
  syncData: null,
  audioFile: '',
  ctmData: null,
  audioUrl: '',
  ctmPara: [],
  fetchLoading: false,
  saveLoading: false,
  audioName: '',
  timecode: 0,
  srtData: [],
  commentArray: [],
  timeCodeType: 1000,
  newProgramId: '',
  newContentId: 0,
  isShared: false,
  isEditable: false,
  isEditablePermission: false,
  canChangePermission: false,
  shareLoading: false,
  sentimentArray: [],
  isArabic: false,
  isLeftJustified: false,
  sessionDetails: [],
  broadcastSessionDetails: [],
  index: 0,
  audioList: [],
  totalCount: 0,
  originalFile: '',
  fullPath: '',
  saveMixVideoLoading: false,
  totalDuration: 0,
  draggedObjectArray: [],
  fetchMixtureLoading: false,
  mixtureName: '',
  isGoogle: false,
  translationType: 0,
  languageName: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FILE_DETAILS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_FILE_DETAILS_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        listData: action.result.fileDetails,
        syncData: action.result.syncData,
        audioFile: action.result.audioFile.audioFile,
        ctmData: action.result.ctmData
      };
    }
    case GET_FILE_DETAILS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DOWNLOAD_FILE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DOWNLOAD_FILE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        file: action.result
      };
    }
    case DOWNLOAD_FILE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DOWNLOAD_MIXTURE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DOWNLOAD_MIXTURE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        file: action.result
      };
    }
    case DOWNLOAD_MIXTURE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_FILE_REQUESTED:
    {
      return {
        ...state,
        saveLoading: true,
        loaded: false,
      };
    }
    case SAVE_FILE_SUCCESS:
    {
      return {
        ...state,
        saveLoading: false,
        error: false,
        loaded: true,
        newProgramId: action.result.editedProgram.programId,
        newContentId: action.result.editedProgram.contentId
      };
    }
    case SAVE_FILE_FAILURE:
    {
      return {
        ...state,
        saveLoading: false,
        loaded: false,
        error: true,
      };
    }
    case FETCH_FILE_DETAILS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        fetchLoading: true,
      };
    }
    case FETCH_FILE_DETAILS_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        audioFile: action.result.audioFile && action.result.audioFile.audioFileName,
        audioUrl: action.result.audioFile && action.result.audioFile.audioFileUrl,
        ctmData: action.result.ctmdtoList,
        fetchLoading: false,
        audioName: action.result.audioFileNameToDisplay,
        isListExist: action.result.isListExist,
        editedProgramDetails: action.result.editedProgramDetails,
        timecode: action.result.timeCode,
        commentArray: action.result.comments ? action.result.comments : [],
        timeCodeType: action.result.timeCodeType,
        isEditablePermission: action.result.isEditable,
        isShared: action.result.isShareable,
        canChangePermission: action.result.canChangePermission,
        isEditable: action.result.allowEditing,
        sentimentArray: action.result.sentimentArray,
        isLeftJustified: action.result.isLeftJustified,
        isSegmented: action.result.isSegmented,
        originalFile: action.result.originalFile,
        fullPath: action.result.fullPath,
        isGoogle: action.result.isGoogle,
      };
    }
    case FETCH_FILE_DETAILS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        fetchLoading: false,
      };
    }
    case FETCH_SUBTITLE_DETAILS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
        fetchLoading: true,
      };
    }
    case FETCH_SUBTITLE_DETAILS_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        // audioFile: action.result.audioFile.audioFileName,
        audioUrl: action.result.audioOrVideoUrl,
        srtData: action.result.srtDtoList,
        fetchLoading: false,
        audioName: action.result.audioFileNameToDisplay,
        isListExist: action.result.isListExist,
        editedProgramDetails: action.result.editedProgramDetails,
        timecode: action.result.timeCode,
        isArabic: action.result.isArabic,
      };
    }
    case FETCH_SUBTITLE_DETAILS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        fetchLoading: false,
      };
    }
    case SHARE_FILE_REQUESTED:
    {
      return {
        ...state,
        loaded: false,
        shareLoading: true
      };
    }
    case SHARE_FILE_SUCCESS:
    {
      return {
        ...state,
        error: false,
        loaded: true,
        shareLoading: false
      };
    }
    case SHARE_FILE_FAILURE:
    {
      return {
        ...state,
        loaded: false,
        error: true,
        shareLoading: false
      };
    }
    case EDIT_SUBTITLE_DETAILS_REQUESTED:
    {
      return {
        ...state,
        saveLoading: true,
        loaded: false,
      };
    }
    case EDIT_SUBTITLE_DETAILS_SUCCESS:
    {
      return {
        ...state,
        saveLoading: false,
        error: false,
        loaded: true,
      };
    }
    case EDIT_SUBTITLE_DETAILS_FAILURE:
    {
      return {
        ...state,
        saveLoading: false,
        loaded: false,
        error: true,
      };
    }
    case FOLDER_EXPORT_REQUESTED: {
      return {
        ...state,
        exportLoading: true,
        loaded: false,
        error: false
      };
    }
    case FOLDER_EXPORT_SUCCESS: {
      console.log('grouped file', action, state);
      return {
        ...state,
        exportLoading: false,
        loaded: true,
        error: false,
        // folderList: action.result.programDetails,
      };
    }
    case FOLDER_EXPORT_FAILURE: {
      return {
        ...state,
        exportLoading: false,
        loaded: true,
        error: true
      };
    }
    case SESSION_FETCH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case SESSION_FETCH_SUCCESS: {
      return {
        ...state,
        loaded: true,
        error: false,
        audioUrl: action.result.audioUrl,
        sessionDetails: action.result.liveSessionDtoList,
        audioName: action.result.sessionName,
        translationType: action.result.translationType,
        isLeftJustified: action.result.isLeftJustified,
        languageName: action.result.languageName,
      };
    }
    case SESSION_FETCH_FAILURE: {
      return {
        ...state,
        loaded: true,
        error: true
      };
    }
    case BROADCAST_SESSION_FETCH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case BROADCAST_SESSION_FETCH_SUCCESS: {
      return {
        ...state,
        loaded: true,
        error: false,
        index: action.result.index,
        broadcastSessionDetails: action.result.liveSessionContinuousChunkDTOs,
      };
    }
    case BROADCAST_SESSION_FETCH_FAILURE: {
      return {
        ...state,
        loaded: true,
        error: true
      };
    }
    case FETCH_AUDIO_LIST_REQUESTED:
    {
      return {
        ...state,
        loaded: false,
      };
    }
    case FETCH_AUDIO_LIST_SUCCESS:
    {
      return {
        ...state,
        error: false,
        loaded: true,
        audioList: action.result.mixerList,
        totalCount: action.result.totalCount
      };
    }
    case FETCH_AUDIO_LIST_FAILURE:
    {
      return {
        ...state,
        loaded: false,
        error: true,
      };
    }
    case SAVE_MIXTURE_REQUESTED:
    {
      return {
        ...state,
        loaded: false,
        saveMixVideoLoading: true,
      };
    }
    case SAVE_MIXTURE_SUCCESS:
    {
      return {
        ...state,
        error: false,
        loaded: true,
        saveMixVideoLoading: false,
      };
    }
    case SAVE_MIXTURE_FAILURE:
    {
      return {
        ...state,
        loaded: false,
        error: true,
        saveMixVideoLoading: false
      };
    }
    case FETCH_MIXTURE_REQUESTED:
    {
      return {
        ...state,
        loaded: false,
        fetchMixtureLoading: true,
      };
    }
    case FETCH_MIXTURE_SUCCESS:
    {
      return {
        ...state,
        error: false,
        loaded: true,
        fetchMixtureLoading: false,
        totalDuration: action.result.mixVideos.totalDuration,
        draggedObjectArray: action.result.mixVideos.segments,
        mixtureName: action.result.editedAudioName
      };
    }
    case FETCH_MIXTURE_FAILURE:
    {
      return {
        ...state,
        loaded: false,
        error: true,
        fetchMixtureLoading: false
      };
    }
    default:
      return state;
  }
};

export const getProcessedProgram = (programID, orderBy, searchedString) => {
  return {
    types: [GET_FILE_DETAILS_REQUESTED, GET_FILE_DETAILS_SUCCESS, GET_FILE_DETAILS_FAILURE],
    promise: client => client.get(`getProcessedFiles/${programID}/${orderBy}?searchedString=${searchedString}`)
  };
};

export const downloadProcessedFile = (programID, fileName, isOriginal = false) => {
  return {
    types: [DOWNLOAD_FILE_REQUESTED, DOWNLOAD_FILE_SUCCESS, DOWNLOAD_FILE_FAILURE],
    promise: client => client.get(`downloadProcessedFile/${programID}?fileName=${fileName}&isOriginal=${isOriginal}`)
  };
};

export const saveFile = (data, programID) => {
  return {
    types: [SAVE_FILE_REQUESTED, SAVE_FILE_SUCCESS, SAVE_FILE_FAILURE],
    promise: client => client.post(`saveCTMFile/${programID}`, { data })
  };
};

export const fetchProcessedProgram = (programID) => {
  return {
    types: [FETCH_FILE_DETAILS_REQUESTED, FETCH_FILE_DETAILS_SUCCESS, FETCH_FILE_DETAILS_FAILURE],
    promise: client => client.get(`fetchProcessedFiles/${programID}`)
  };
};

export const saveCTMFile = (data, programID, timecode, timecodeType) => {
  return {
    types: [SAVE_FILE_REQUESTED, SAVE_FILE_SUCCESS, SAVE_FILE_FAILURE],
    promise: client => client.post(`saveFile/${programID}?timeCode=${timecode}&timeCodeType=${timecodeType}`, { data })
  };
};

export const shareCTMFile = (data) => {
  return {
    types: [SHARE_FILE_REQUESTED, SHARE_FILE_SUCCESS, SHARE_FILE_FAILURE],
    promise: client => client.post('share', { data })
  };
};

export const fetchSubtitleProgram = (programID) => {
  return {
    types: [FETCH_SUBTITLE_DETAILS_REQUESTED, FETCH_SUBTITLE_DETAILS_SUCCESS, FETCH_SUBTITLE_DETAILS_FAILURE],
    promise: client => client.get(`fetchSubtitle/${programID}`)
  };
};

export const editSubtitleProgram = (programID, data) => {
  return {
    types: [EDIT_SUBTITLE_DETAILS_REQUESTED, EDIT_SUBTITLE_DETAILS_SUCCESS, EDIT_SUBTITLE_DETAILS_FAILURE],
    promise: client => client.post(`editSubtitle/${programID}`, { data })
  };
};

export const exportGroupedByTime = (programId, splitTimeCount) => {
  return {
    types: [FOLDER_EXPORT_REQUESTED, FOLDER_EXPORT_SUCCESS, FOLDER_EXPORT_FAILURE],
    promise: client => client.post(`exportGroupedByTime/${programId}?splitCount=${splitTimeCount}`)
  };
};

export const sessionFetch = (programId) => {
  return {
    types: [SESSION_FETCH_REQUESTED, SESSION_FETCH_SUCCESS, SESSION_FETCH_FAILURE],
    promise: client => client.get(`sessionWatch?programId=${programId}`)
  };
};

export const broadcastSessionFetch = (dirId, index) => {
  return {
    types: [BROADCAST_SESSION_FETCH_REQUESTED, BROADCAST_SESSION_FETCH_SUCCESS, BROADCAST_SESSION_FETCH_FAILURE],
    promise: client => client.get(`broadcastSession?dirId=${dirId}&index=${index}`)
  };
};

export const fetchProcessedProgramWithoutlogin = (programID) => {
  return {
    types: [FETCH_FILE_DETAILS_REQUESTED, FETCH_FILE_DETAILS_SUCCESS, FETCH_FILE_DETAILS_FAILURE],
    promise: client => client.get(`fetchProcessedFilesWithoutLogin/${programID}`)
  };
};

export const fetchAudioList = (pageNo, searchValue) => {
  return {
    types: [FETCH_AUDIO_LIST_REQUESTED, FETCH_AUDIO_LIST_SUCCESS, FETCH_AUDIO_LIST_FAILURE],
    promise: client => client.get(`audioListFetch/${pageNo}?searchValue=${searchValue}`)
  };
};

export const saveMixture = (data) => {
  return {
    types: [SAVE_MIXTURE_REQUESTED, SAVE_MIXTURE_SUCCESS, SAVE_MIXTURE_FAILURE],
    promise: client => client.post('saveMixture', { data })
  };
};

export const fetchMixedVideo = (mixerId) => {
  return {
    types: [FETCH_MIXTURE_REQUESTED, FETCH_MIXTURE_SUCCESS, FETCH_MIXTURE_FAILURE],
    promise: client => client.get(`fetchMixture/${mixerId}`)
  };
};

export const downloadMixtureTranscript = (data) => {
  console.log('inside reducer ', data);
  return {
    types: [DOWNLOAD_MIXTURE_REQUESTED, DOWNLOAD_MIXTURE_SUCCESS, DOWNLOAD_MIXTURE_FAILURE],
    promise: client => client.post('downloadMixtureTranscript', { data })
  };
};

export const downloadProcessedFileWithoutLogin = (programID, fileName, isOriginal = false) => {
  return {
    types: [DOWNLOAD_FILE_REQUESTED, DOWNLOAD_FILE_SUCCESS, DOWNLOAD_FILE_FAILURE],
    promise: client => client.get(`downloadProcessedFileWithoutLogin/${programID}?fileName=${fileName}&isOriginal=${isOriginal}`)
  };
};
