export const LIVE_CONTINUOS_CHUNK_REQUESTED = 'liveTranslate/LIVE_CONTINUOS_CHUNK_REQUESTED';
export const LIVE_CONTINUOS_CHUNK_SUCCESS = 'liveTranslate/LIVE_CONTINUOS_CHUNK_SUCCESS';
export const LIVE_CONTINUOS_CHUNK_FAILURE = 'liveTranslate/LIVE_CONTINUOS_CHUNK_FAILURE';

export const SAVE_LIVE_DATA_REQUESTED = 'liveTranslate/SAVE_LIVE_DATA_REQUESTED';
export const SAVE_LIVE_DATA_SUCCESS = 'liveTranslate/SAVE_LIVE_DATA_SUCCESS';
export const SAVE_LIVE_DATA_FAILURE = 'liveTranslate/SAVE_LIVE_DATA_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LIVE_CONTINUOS_CHUNK_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LIVE_CONTINUOS_CHUNK_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LIVE_CONTINUOS_CHUNK_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SAVE_LIVE_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SAVE_LIVE_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SAVE_LIVE_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const saveContinousChunk = (data) => {
  return {
    types: [LIVE_CONTINUOS_CHUNK_REQUESTED, LIVE_CONTINUOS_CHUNK_SUCCESS, LIVE_CONTINUOS_CHUNK_FAILURE],
    promise: client => client.post('continuosLiveChunk', { data }),
  };
};

export const saveLiveData = (data) => {
  return {
    types: [SAVE_LIVE_DATA_REQUESTED, SAVE_LIVE_DATA_SUCCESS, SAVE_LIVE_DATA_FAILURE],
    promise: client => client.post('saveLiveData', { data }),
  };
};
